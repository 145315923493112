import React, { useContext, useEffect, useState } from 'react';

import TimelineCheckBox from './TimelineCheckBox/TimelineCheckBox'

import axios from 'axios';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import TimeLineElement from './TimeLineElement';
import Event from '../../types/Event';
import { ForecastContext } from '../Forecasts';
import { createConsumer } from "@rails/actioncable"
import { Toast } from '../Toast';
import I18n from "../../packs/translations"


const Timeline = () => {
  const today = new Date();
  today.setFullYear(today.getFullYear() - 1);
  const defaultSince = today.toISOString().substr(0, 10);
  today.setFullYear(today.getFullYear() + 1);
  const defaultUntil = today.toISOString().substr(0, 10);
  const [events, setEvents] = useState<Event[]>([]);
  const [sinceDate, setSinceDate] = useState(defaultSince);
  const [untilDate, setUntilDate] = useState(defaultUntil);
  const [eventTypes, setEventTypes] = useState<{ name: string, type: string, color: string, icon: string }[]>([]);
  const [visibleEventTypes, setVisibleEventTypes] = useState<string[]>([]);
  const [displayTimeline, setDisplayTimeline] = useState<boolean>(false);
  const [hasNewEvents, setHasNewEvents] = useState<boolean>(false);
  const { currentForecast } = useContext(ForecastContext);
  const [eventConsumer, setEventConsumer] = useState<any>(createConsumer());



  useEffect(() => {
    if (currentForecast) {
      eventConsumer.subscriptions.create({ channel: "EventChannel", forecastBulletinId: currentForecast.id }, {
        received({ event }) {
          setHasNewEvents(true)
          Toast.info({
            title: I18n.t(event.type),
            text: event.title,
            timer: 5000,
            showConfirmButton: true,
            confirmButtonText: 'Afficher',
          })
        }
      });
    }

    return () => {
      eventConsumer.disconnect();
    }
  }, [currentForecast, eventConsumer]);

  useEffect(() => {
    axios.get<{ name: string, type: string, color: string, icon: string }[]>('/events/types.json')
      .then((response) => {
        setEventTypes(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const getEvents = () => {
    if(!currentForecast || !currentForecast.id){
      console.error('No forecast selected');
      return;
    }
    axios.get<Event[]>('/events.json', {
      params: {
        since: sinceDate,
        until: untilDate,
        eventTypes: visibleEventTypes,
        forecastBulletinId: currentForecast.id,
      }
    })
      .then((response) => {
        setEvents(response.data);
        setHasNewEvents(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    setVisibleEventTypes(
      eventTypes.map(elem => elem.type)
        .filter(elem => localStorage
          .getItem(`timeline_filter_${elem}`) === 'true'))
  }, [eventTypes])

  useEffect(() => {
    if (!!sinceDate && !!untilDate && !!visibleEventTypes && currentForecast && displayTimeline) {
      getEvents();
    }
  }, [sinceDate, untilDate, visibleEventTypes, currentForecast?.id, displayTimeline]);


  function handleSinceChange(event) {
    setSinceDate(event.target.value);
    localStorage.setItem('since_date_filter', event.target.value);
  }

  function handleUntilChange(event) {
    setUntilDate(event.target.value);
    localStorage.setItem('until_date_filter', event.target.value);
  }

  function handleEventTypeChange(eventType: string, checked: boolean) {
    const cleanedEventTypes = visibleEventTypes.filter(elem => eventTypes.map(elem => elem.type).includes(elem));
    if (checked) {
      setVisibleEventTypes([...cleanedEventTypes, eventType]);
    } else {
      setVisibleEventTypes(cleanedEventTypes.filter(elem => elem !== eventType));
    }
  }


  return (
    <>

      <button
        type="button"
        className="btn timeline-button"
        onClick={() => {
          setDisplayTimeline(!displayTimeline);
        }}
      >
        Chronologie
      </button>
      {displayTimeline && <div className="timeline-component">
        <div className="configuration-panel">
          <i className="fas fa-rotate-right px-1 ml-1 text-warning clickable fa-xs"
            onClick={getEvents}
            title={hasNewEvents ? 'Nouveaux évènements\nCliquer pour rafraîchir' : ''}
            style={{ visibility: hasNewEvents ? 'visible' : 'hidden' }}></i>
          {
            eventTypes.map((event) => {
              return (
                <TimelineCheckBox
                  key={`EventType-${event.type}`}

                  onChange={handleEventTypeChange}
                  {...event}
                />
              )
            })
          }

          <input
            type="date"
            className='timeline-filter-date'
            value={sinceDate}
            onChange={handleSinceChange}
          />
          <input
            type="date"
            className='timeline-filter-date'
            value={untilDate}
            onChange={handleUntilChange}
          />
        </div>
        <VerticalTimeline layout="1-column-right">
          {events.map((ev, index) => <TimeLineElement key={`event-${index}`} event={ev} />)}
          <VerticalTimelineElement
            iconStyle={{ background: 'rgba(39, 44, 51, 1)', color: '#8b939b' }}
            icon={<i className="fas fa-hourglass-start menu-icon" />}
          />


        </VerticalTimeline>

      </div >}
    </>
  )
};

export default Timeline;