import React, { useState } from 'react';
import I18n from "../../../packs/translations"

interface Props {
  type: string,
  color: string,
  icon: string,
  onChange: (eventType: string, checked: boolean) => void,
}

const TimelineCheckBox = ({ type, color, icon, onChange }: Props) => {
  const [isChecked, setChecked] = useState(
    localStorage.getItem(`timeline_filter_${type}`) === 'true' ? true : false
  );

  function handleCheckChange(event) {
    setChecked(isChecked);
    localStorage.setItem(`timeline_filter_${type}`, !isChecked ? 'true' : 'false');
    onChange(type, event.target.checked);

  }

  return (
    <label 
      style={{ color: isChecked ? '#FFF' : '#444', marginBottom: 0 }}
      title={I18n.t(type)}
      className='clickable'
    >
      <input
        className='timeline-filter-checkbox'
        name={type}
        id={type}
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckChange}
      />
      <i className={icon} />
    </label>
  );
}

export default TimelineCheckBox;
