import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import React from "react";
import { useEffect, useMemo, useState } from "react";
import LocationRenderer from "./LocationRenderer";
import LocationEditor from "./LocationEditor";
import AssetTypeEditor from "./AssetTypeEditor";
import { Toast } from "../Toast";
import DeleteButton from "./DeleteButton";

export interface Asset {
  id: number|undefined;
  assetType: string|undefined;
  identifier: string|undefined;
  timeEfficient: number|undefined;
  hazardLimitation: object|undefined;
  location: object|undefined;
  comment: string|undefined;
}

const suppressReservedKeys = params => {
  if(params.event.keyCode == 13)
    return true;
},

const columns = [
  { field:"assetType", editable:true, headerName: "Type de matériel" , flex:4,
        suppressKeyboardEvent: suppressReservedKeys, cellEditor: AssetTypeEditor, cellEditorPopup: true},
  { field:"identifier", editable:true, headerName: "Identifiant", flex:3 },
  { field:"location", editable:true, headerName: "Localisation", flex:5,
      cellRenderer: LocationRenderer, suppressKeyboardEvent: suppressReservedKeys,
      cellEditor: LocationEditor, cellEditorPopup: true,comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        if (valueA.label == valueB.label) return 0;
        return (valueA.label > valueB.label) ? 1 : -1;
      },filterValueGetter: params => {      
        return params.data.location?.label        
    }
  },
  { field:"timeEfficient", editable:true, headerName: "Autonomie", flex:1 },
  { field:"hazardLimitation", editable:true, headerName: "Limite de hauteur d'eau (cm)", flex:2 },
  { field:"comment", editable:true, headerName: "Commentaire", flex:3 },
  { headerName:"", cellRenderer: DeleteButton, maxWidth:30, sortable:false, filter:false, lockPosition: 'right', cellClass: 'locked-col'}
];


const defaultColDef =  {
      flex: 1,
      filter: true,
      floatingFilter: false,
  };


const Inventory = () => {
  const [assets, setAssets] = useState<Asset[]>([]);
  const organisationId = location.search.substring(5);
  useEffect(() => {
      axios
        .get<Asset[]>(`/assets.json`+ (organisationId? "?oid="+organisationId:""))
        .then(response => {
          setAssets(response.data);
        });
  }, []);

  const saveAsset = (cell) =>
    {
      const body = {}
      if(cell.column.colId=="location")
      {
        body["location_id"] = cell.newValue.id;
        body["location_type"] = cell.newValue.type;
      }
      else
        body[cell.column.colId] = cell.newValue;
      axios.patch(`/assets/${cell.data.id}.json`, body)
        .then(() => Toast.success({ title: "Enregistré" }))
        .catch((res) => {
          const errors = res.response.data;
          Toast.error({
            title:
              res.response.status === 422
                ? "Données invalides"
                : res.response.statusText,
            html: Object.keys(errors).map((k)=>`<li><b>${k}</b> : ${errors[k][0]}</li>`).join("")
              
          });
        })
  }

  return (
    <div className="card">
      <div className="card-header">
        <h1>Inventaire</h1>
      </div>
      <div className="card-body">

        <div
        className="ag-theme-quartz" // applying the Data Grid theme
        style={{ height: 600 }} // the Data Grid will fill the size of the parent container
      >
        <AgGridReact
          onCellValueChanged={saveAsset}
          columnDefs={columns}
          rowData={assets}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[25, 50, 100]}
        />
        </div>
      </div>
    </div>
  );
}

export default Inventory;

function componentDidUpdate() {
  throw new Error("Function not implemented.");
}
