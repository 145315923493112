import axios from "axios";

const formats = {}
const LoaderImageHandler = (e, map) =>{
  const id = e.id;
  if(id.indexOf(".svg")>0)
  {
    const [url, style] = id.split('?')
    if(!formats[id]){
      formats[id] = "loading";
      axios.get(id).then(({data})=>{
        const regex = /viewBox="\d+\s\d+\s(\d+)\s(\d+)"/gm;
        formats[id] = [...data.matchAll(regex)][0].slice(1,3).map(m=>parseFloat(m));
        data = data.replace("<path", "<path " + (style||"").replace("&"," "))
        const blob = new Blob([data], {type: 'image/svg+xml'});
        const url = URL.createObjectURL(blob);
        let img = new Image(16,16 * formats[id][1]/formats[id][0]);
        img.onload = ()=>{
          map.addImage(id, img)
        }
        img.src = url;
      })}
    else 
      return;
  }
  else
  {
    const image = map.loadImage(id);
    map.addImage(id, image.data);
  }
}

const SwimwaterImageHandler = (e, map) => {
  const id = e.id; // id of the missing image

  // check if this missing icon is one this function can generate
  const prefix = 'square-rgb-';
  if (id.indexOf(prefix) !== 0) return;

  const days = id.replace(prefix, '').split(',').map(d=>parseInt(d));
  // const days = [1,0,1,0]
  const height = 16; // The image will be 16 pixels square
  const upperHeight = 20;
  const upperImgHeight = 24;
  
  const vMargin = (upperImgHeight - upperHeight)/2
  const imgHeight = height + upperImgHeight;
  const width = height * (days.length-2); // The image will be 16 pixels square
  const hMargin = (width - upperHeight)/2
  const bytesPerPixel = 4; // Each pixel is represented by 4 bytes: red, green, blue, and alpha.
  const data = new Uint8Array(width * imgHeight * bytesPerPixel);
  for(let i in days)
  {
    // extract the color from the id
    const d = parseInt(i)
    const rgb = days[d]===0?[255,100,100]:[0,200,0];
    if(d<2)
      for (let x = 0; x < upperImgHeight; x++) {
        for (let y = 0; y < width; y++) {
            const offset = (x * width + y) * bytesPerPixel;
            if(x < vMargin || x > upperHeight + vMargin || y < hMargin || y >= width - hMargin)
            {
              data[offset + 0] = 0; // red
              data[offset + 1] = 0; // green
              data[offset + 2] = 0; // blue
              data[offset + 3] = 0; // alpha
            }
            else if(x == vMargin || x == upperHeight + vMargin || y == hMargin ||y == width - hMargin)
            {
              data[offset + 0] = 0; // red
              data[offset + 1] = 0; // green
              data[offset + 2] = 0; // blue
              data[offset + 3] = 255; // alpha
            }
            else if(d==0 && (x-vMargin) + (y-hMargin) <= upperHeight || d==1 && (x-vMargin) + (y-hMargin) > upperHeight)
            {
              data[offset + 0] = rgb[0]; // red
              data[offset + 1] = rgb[1]; // green
              data[offset + 2] = rgb[2]; // blue
              data[offset + 3] = 255; // alpha
            }
        }
      }
    else
      for (let x = 0; x < height; x++) {
          for (let y = 0; y < height; y++) {
              const offset = (y * width + (d-2) * height + x + width * (upperImgHeight-1)) * bytesPerPixel;
              if(x==0||y==0)
              {
                data[offset + 0] = 0; // red
                data[offset + 1] = 0; // green
                data[offset + 2] = 0; // blue
                data[offset + 3] = 0; // alpha
              }
              else if(x==1||y==1)
              {
                data[offset + 0] = 0; // red
                data[offset + 1] = 0; // green
                data[offset + 2] = 0; // blue
                data[offset + 3] = 255; // alpha
              }
              else
              {
                data[offset + 0] = rgb[0]; // red
                data[offset + 1] = rgb[1]; // green
                data[offset + 2] = rgb[2]; // blue
                data[offset + 3] = 255; // alpha
              }
          }
      }
      
  }
  map.addImage(id, {width, height: imgHeight, data});
}


export const MapImageHandler = (e, map) => {
  const id = e.id;
  if(id.indexOf('square-rgb-') === 0)
    return SwimwaterImageHandler(e, map);
  else
    return LoaderImageHandler(e, map);
}
