import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const Toast = {
  toast: (props) => MySwal.fire({
    title: <p>Alerte</p>,
    timer: 1500,
    toast: true,
    showConfirmButton: false,
    position: 'top-end',
    ...props,
  }),
  success: (props) => Toast.toast({ icon: 'success', ...props }),
  error: (props) => Toast.toast({ icon: 'error', timer: 5000, ...props }),
  info: (props) => Toast.toast({ icon: 'info', timer: 5000, ...props }),
};

export {
  Toast,
};
