import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import 'tablednd'
import FeaturePublicDisplay from '../../types/FeaturePublicDisplay'
import { Toast } from '../Toast'

const FeaturePublicDisplaysTable = ({
  layerId, setSelectedFeaturePublicDisplay, selectedFeaturePublicDisplay, loadFeaturePublicDisplays, featurePublicDisplays
}: {
  layerId: number,
  selectedFeaturePublicDisplay: FeaturePublicDisplay,
  setSelectedFeaturePublicDisplay: Function,
  loadFeaturePublicDisplays: Function,
  featurePublicDisplays: FeaturePublicDisplay[]
}) => {
  const tableRef = React.createRef<HTMLTableElement>()

  useEffect(() => {
    global.jQuery(tableRef.current).tableDnD({
      onDrop: (table: any, row: any) => {
        const rows = table.tBodies[0].rows;
        const ids = [...rows].map((row: any) => row.id.split('_')[1]);
        fetch(`/layers/${layerId}/feature_public_displays/sort.json`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector<HTMLMetaElement>('[name="csrf-token"]')?.content || '',
          },
          body: JSON.stringify({ feature_public_display: ids }),
        })
          .then(() => loadFeaturePublicDisplays())
      }
    });
  }, [tableRef]);


  const deleteFeaturePublicDisplay = (id: number, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    fetch(`/layers/${layerId}/feature_public_displays/${id}.json`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector<HTMLMetaElement>('[name="csrf-token"]')?.content || '',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        Toast.success({ title: 'Suppression réussie' })
      })
      .catch((error) => {
        Toast.error({ title: 'Erreur lors de la suppression' })
      })
      .finally(() => loadFeaturePublicDisplays)
  }

  const editFeaturePublicDisplay = (id: number, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    return fetch(`/layers/${layerId}/feature_public_displays/${id}.json`)
      .then((response) => response.json())
      .then((data) => {
        setSelectedFeaturePublicDisplay(data)
      }
      )
  }


  useEffect(() => {
    loadFeaturePublicDisplays()
  }, [layerId]);

  return (
    <table className="table table-striped" ref={tableRef}>
      <thead className="thead-dark">
        <tr>
          <th>Nom</th>
          <th>Attribut</th>
          <th>Colonne</th>
          <th>Chemin</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {featurePublicDisplays.map(({ publicName, attributeName, columnName, includePath, id }) => (
          <tr id={`fpd_${id}`} className={`draggable ${id === selectedFeaturePublicDisplay.id ? 'table-info' : ''}`} key={`feature-public-value-${id}`}>
            <td>{publicName}</td>
            <td>{attributeName}</td>
            <td>{columnName}</td>
            <td>{includePath}</td>
            <td>
              <a href={`/layers/${layerId}/feature_public_displays/${id}/edit`} onClick={(e) => editFeaturePublicDisplay(id, e)} className="fas fa-edit mx-2 text-primary"></a>
              <a href={`/layers/${layerId}/feature_public_displays/${id}`} onClick={(e) => deleteFeaturePublicDisplay(id, e)} className="fas fa-trash mx-2 text-danger"></a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

FeaturePublicDisplaysTable.propTypes = {
  layerId: PropTypes.number.isRequired,
}

FeaturePublicDisplaysTable.defaultProps = {
  layerId: 0,
}


export default FeaturePublicDisplaysTable