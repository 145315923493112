import React from 'react'

interface ForecastBulletin {
  activeResultRegisterings?: any[],
  createdAt?: string,
  evaluationsCompleted?: boolean,
  evaluationsCompletedAt?: string,
  forecastAlternative?: any,
  forecastValues?: any[],
  forecastableFeatures?: any[],
  forecastableModel?: any,
  id: number,
  issuedAt?: string,
  layerFeatureEvaluators?: any[],
  missingEvaluations?: any[],
  name?: string,
  layer?: string,
  updatedAt?: string,
  url?: string,
  setCurrentForecast?: (forecast: ForecastBulletin | string) => void,
}

interface ForecastContextProps {
  currentForecast?: ForecastBulletin,
  setCurrentForecast?: (forecast: ForecastBulletin | string) => void,
  disabledIntervals: string[],
  showFunctional: boolean,
  isObsolete: boolean,
  currentInterval: number,
  loadForecast: (forecastId: number) => Promise<void>,
}

const ForecastContext = React.createContext<ForecastContextProps>({
  currentForecast: null,
  disabledIntervals: [],
  showFunctional: false,
  isObsolete: true,
  currentInterval: 0,
  loadForecast: () => Promise.resolve(),
});
ForecastContext.displayName = 'ForecastContext';

export default ForecastContext