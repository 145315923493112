import axios from "axios";
import { Form, Formik, FormikHelpers, FormikValues, useField } from "formik";
import React, { useEffect, useState } from "react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { Contact } from "../Directory";
import { FieldWithError } from "../Form/Error";
import NestedForm from "../Form/NestedForm";

const emptyContact = {firstName: undefined, lastName:undefined, id:undefined, title:undefined, phone: undefined, altPhone:undefined, email:undefined, tags:[]}

const ContactSearch = ({...props}) =>
  {
    const [field, meta, helpers] = useField(props.name);
    const {value} = meta;
    const {setValue} = helpers;
    const [creating, setCreating] = useState(false);
    const [selectedContact, setSelectedContact] = useState();
    const [newContact, setNewContact] = useState<Contact>(emptyContact);

    useEffect(()=>{
      if(value)
        axios.get(`/contacts/${value}.json`)
          .then(({ data }) => {
            return setSelectedContact(buildContactResult(data));
          })
          .catch((err) => {
            console.error(err);
          });
    },[])
      
    const buildContactResult = (contact) => 
      {
        return {
          value: contact.id,
          label:<div>
            <b>{contact.firstName} {contact.lastName}</b><br />
            <i>{contact.title}</i>
          </div>
        }
      }

    const searchContact = (inputValue) => {
      if (inputValue.length < 3) {
        return Promise.resolve([]);
      }
  
      return axios.get("/contacts/search.json?q="+inputValue)
        .then(({ data }) => {
          return data.map(buildContactResult);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    const handleCreate = (inputValue: string) => {
      setCreating(true);
      [newContact.firstName, newContact.lastName, newContact.title] = inputValue.split(" ");
      
      setNewContact({...newContact});
    };

    const createContact = async (values) => {
      console.log("Creating")
      return axios.post("/contacts.json", values)
        .then((res)=>{
          setCreating(false);
          setNewContact(emptyContact);
          setSelectedContact(buildContactResult(res.data));
          setValue(res.data.id);
        })
        .catch((err)=>console.error(err.data))
    }

  
    return <>
      {!creating ? <AsyncCreatableSelect
        name={field.name}
        onChange={(contact)=>{setValue(contact.value);setSelectedContact(contact)}}
        placeholder="Rechercher ..."
        noOptionsMessage={() => "Aucune entrée correspondante"}
        onCreateOption={handleCreate}
        loadingMessage={() => "Chargement ..."}
        // styles={customStyles}
        cacheOptions
        loadOptions={searchContact}
        // defaultOptions
        // onSubmit={()=>setValue(selectedContact? selectedContact.id : undefined)}
        value={selectedContact} 
      />:
      <fieldset>
        <NestedForm 
          initialValues={newContact} 
          onSubmit={createContact}>
          <Form>
              <div className="form-group row">
                <label className="col-form-label col-sm-4 required">
                  Prénom
                </label>
                <div className="col-8">
                  <FieldWithError
                    name="firstName"
                    className={`form-control`}
                    placeholder="Prénom"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-sm-4 required">
                  Nom
                </label>
                <div className="col-8">
                  <FieldWithError
                    name="lastName"
                    className={`form-control`}
                    placeholder="Nom de famille"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-sm-4 required">
                  Fonction
                </label>
                <div className="col-8">
                  <FieldWithError
                    name="title"
                    className={`form-control`}
                    placeholder="Fonction"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-sm-4 required">
                  Téléphone
                </label>
                <div className="col-8">
                  <FieldWithError
                    name="phone"
                    className={`form-control`}
                    placeholder="Numéro de téléphone"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-sm-4 required">
                  E-Mail
                </label>
                <div className="col-8">
                  <FieldWithError
                    name="email"
                    type="email"
                    className={`form-control`}
                    placeholder="Courrier électronique"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="offset-md-8 col-md-4">
                  <button type="submit" className="btn btn-primary btn-sm">Créer le contact</button>
                </div>
              </div>
          </Form>
        </NestedForm>
        </fieldset>
      }
      </>
  }
export default ContactSearch;