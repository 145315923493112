import React, { useEffect, useState } from 'react';
import KeyrosModal from '../Modal';
import { Button } from 'react-bootstrap';

const CheckListForm = ({onClose})=>{
  const [showCheckList, setShowCheckList] = useState(true);
  useEffect(()=>{
    if(showCheckList === false && onClose)
      onClose();
  },[showCheckList])
  return <KeyrosModal show={showCheckList} 
        title={`Check-list d'ouverture ${new Date().toLocaleDateString()}`}
        children={
          <div className="checklist">
            <div className='row'>
              <div className='col-9'>
                Pollution
              </div>
              <div className='col-3'>
                <input type="checkbox" />
              </div>
            </div>
            <div className='row'>
              <div className='col-9'>
                Météo
              </div>
              <div className='col-3'>
                <input type="checkbox" />
              </div>
            </div>
            <div className='row'>
              <div className='col-9'>
                Débit
              </div>
              <div className='col-3'>
                <input type="checkbox" />
              </div>
            </div>
            <div className='row'>
              <div className='col-9'>
                Visuel
              </div>
              <div className='col-3'>
                <input type="checkbox" />
              </div>
            </div>
          </div>
        }
        footer={(
          <>
            <Button variant='danger' onClick={() => setShowCheckList(false)}> Energistrer </Button>
            <Button variant='outline-primary' onClick={() => setShowCheckList(false)} >
              Annuler
            </Button>
          </>
        )}
    />
};

export default CheckListForm;