import React, { FC, useState, useEffect } from 'react';
import ForecastableModelSelector from './ForecastableModelSelector';
import Xarrow, { useXarrow, Xwrapper } from 'react-xarrows';
import ForecastableModelFrame from './ForecastableModelFrame';
import DynamicLayerFrame from './DynamicLayerFrame';
import RequirementFrame from './RequirementFrame';
import FeatureVariableFrame from './FeatureVariableFrame';
import LayerFeatureEvaluatorFrame from './LayerFeatureEvaluatorFrame';
import { IConfiguratorLayerFeatureEvaluator, IConfiguratorStoreFeatureDependencyConfig, IConfiguratorStoreForecastableModel, IConfiguratorStoreRequirement } from './ConfiguratorStoreType';
import { FeatureVariable } from '../../types/FeatureVariable';

const computeArrowAnchor = (index: number, nbElements: number) => {
  if (nbElements < 3) {
    return "bottom";
  }

  switch (index) {
    case 0:
      return "left"
    case nbElements - 1:
      return "right"
    default:
      return "bottom"
  }
}

const getUniqueVariables = (lfes: IConfiguratorLayerFeatureEvaluator[]) => {
  const res: { [key: number]: FeatureVariable } = {};

  lfes.map(lfe => lfe.featureVariable).filter(
    (variable) =>
      variable
  ).forEach(variable => {
    res[variable.id] = variable;
  })
  return Object.values(res);
}

const getRequirements = (
  forecastableModel: IConfiguratorStoreForecastableModel,
  featureDependencyConfig: IConfiguratorStoreFeatureDependencyConfig
): IConfiguratorStoreRequirement[] => {
  const res: IConfiguratorStoreRequirement[] = [];

  forecastableModel.layerFeatureEvaluators?.forEach(lfe => {
    lfe.featureVariable?.requirements?.forEach(requirement => {
      if (requirement.featureDependencyConfigId === featureDependencyConfig.id) {
        res.push(requirement)
      }
    })
  })

  return res;
}


const getDependencies = (
  forecastableModel:
    IConfiguratorStoreForecastableModel
): IConfiguratorStoreFeatureDependencyConfig[] => {
  const res: IConfiguratorStoreFeatureDependencyConfig[] = [];
  forecastableModel.layerFeatureEvaluators?.forEach(lfe => {
    lfe.featureVariable?.requirements?.forEach(requirement => {
      res.push(requirement.featureDependency)
    })
  })
  return res;
}



const Configurator: FC = () => {
  const updateXArrows = useXarrow();
  const [forecastableModel, setForecastableModel] = useState<IConfiguratorStoreForecastableModel | null>(null);

  useEffect(() => {
    updateXArrows();
  }, [forecastableModel]);


  if (!forecastableModel?.id) {
    return (
      <div className="card">
        <div className="card-header">
          <h2>
            Configurateur
          </h2>
        </div>
        <div className='card-body container'>
          <div className="row mb-5">
            <ForecastableModelSelector selectForecastableModel={(fm) => setForecastableModel(fm)} />
          </div>
          Veuillez choisir un modèle prévisionel.
        </div>

      </div>
    )
  }

  return (
    <div className="card">
      <div className="card-header">
        <h2>
          Configurateur
        </h2>
      </div>
      <div className='card-body container'>
        <div className="row mb-5">
          <ForecastableModelSelector
            selectedForecastableModelId={forecastableModel?.id}
            selectForecastableModel={(fm) => setForecastableModel(fm)}
          />
        </div>
        <div className="col-12 row">
          <Xwrapper>
            <div className="col-9 container">

              {/* Current forecastable model */}
              <ForecastableModelFrame forecastableModel={forecastableModel} frameId="forecastable-model" />

              {/* Layer provider and evaluators config */}
              <div className='row pt-5'>

                <div className="col row justify-content-around align-middle p-0 m-0 mb-5 mt-5 pb-5 pt-5">

                  {
                    (forecastableModel.layerFeatureEvaluators ?? []).map(evaluator =>
                      <React.Fragment key={`evaluator-${evaluator.id}`}>
                        <LayerFeatureEvaluatorFrame key={evaluator.id} evaluator={evaluator} frameId={`evaluator-${evaluator.id}`} forecastedlayerId={forecastableModel.forecastedTypeLayerId} />

                        <Xarrow
                          key={`evaluator-${evaluator.id}`}
                          start="forecastable-model"
                          end={`evaluator-${evaluator.id}`}
                          animateDrawing
                          color='#272c33'
                          startAnchor="bottom"
                          endAnchor="top"
                          zIndex={1000} />


                        {
                          evaluator.featureVariableId && (
                            <Xarrow
                              key={`variable-${evaluator.id}`}
                              end={`variable-${evaluator.featureVariableId}`}
                              start={`evaluator-${evaluator.id}`}
                              animateDrawing
                              color='#272c33'
                              startAnchor="bottom"
                              endAnchor="top"
                              zIndex={1000} />
                          )
                        }

                      </React.Fragment>
                    )
                  }

                </div>
              </div>

              {/* Feature variables */}
              <div className='row pb-5'>
                <div className="col row justify-content-around align-middle p-0 m-0 mb-5 mt-5 pb-5 pt-5">

                  {
                    getUniqueVariables(forecastableModel?.layerFeatureEvaluators || [])
                      .map((variable, index) =>
                        <FeatureVariableFrame
                          forecastableModel={forecastableModel}
                          key={`feature-variable-${variable.id}`}
                          featureVariable={variable}
                          frameId={`variable-${variable.id}`}
                        />
                      )
                  }

                </div>
              </div>


              {/* forecasted layers */}
              <div className='row pt-5 pb-5'>
                <div className="col row justify-content-around align-middle p-0 m-0 pb-5 pt-5 mb-5 mt-5">

                  {
                    getDependencies(forecastableModel)
                      .filter(dependency => dependency)
                      .map((dependency, dep_index) =>
                        <React.Fragment key={dependency.layerId}>
                          <DynamicLayerFrame layer={dependency.dependent} frameId={`dependent-layer-${dependency.layerId}`} />
                          {
                            getRequirements(forecastableModel, dependency).map((requirement, req_index) =>
                              <Xarrow
                                key={req_index}
                                start={`variable-${requirement.dependencyVariableId}`}
                                end={`dependent-layer-${dependency.layerId}`}
                                animateDrawing color='#272c33'
                                startAnchor={computeArrowAnchor(dep_index, forecastableModel.forecastedLayer.dependentLayerIds.length)}
                                endAnchor={"top"}
                                labels={
                                  {
                                    middle: <RequirementFrame requirement={requirement} forecastableModel={forecastableModel} />
                                  }
                                }
                                zIndex={1000}
                                curveness={0.4}
                              />
                            )
                          }

                        </React.Fragment>
                      )
                  }
                </div>


                {/* Arrow and dependency button */}
                {/* <Xarrow start="link-1" end="postes-hta-bt" animateDrawing color='#272c33' startAnchor="bottom" endAnchor="top" zIndex={1000} />
                      <Xarrow start="link-2" end="reseau-bt" animateDrawing color='#272c33' startAnchor="bottom" endAnchor="top" zIndex={1000} /> */}
                {/* special evaluator */}
                {/* <div className='col row bg-secondary justify-content-around align-middle pb-5 pt-5 mb-5 mt-5'>

                        <div className="col-md-auto bg-primary mt-3 mb-3 p-2 align-middle text-center" id='postes-hta-bt'>
                          Postes hta/bt
                        </div>

                        <div className="col-md-auto bg-primary mt-3 mb-3 p-2 align-middle text-center" id='reseau-bt'>
                          Reseau BT
                        </div>

                      </div> */}

              </div>
            </div>


          </Xwrapper>
        </div>
      </div>

    </div>
  );
};
export default Configurator;
