import React from "react";

import { SearchBar } from "../components/SearchBar/SearchBar";
import { register } from "ol/proj/proj4";

import proj4 from "proj4";
proj4.defs(
  "EPSG:2154",
  "+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
register(proj4);


const MapHeader = ({onSelect}) => {
  return (
    <div
      className="container"
      style={{
        margin: 0,
        minHeight: "40px",
        maxWidth: "inherit",
        position: "fixed",
        top: "10px",
        zIndex: 9,
        height: "40px",
      }}
    >
      <div className="col-6 float-right">
        <SearchBar onSelect={onSelect} />
      </div>
    </div>
  );
};

export default MapHeader;
