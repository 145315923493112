import React, { useState, useContext, useCallback } from 'react'
import { debounce } from '../Map/Utils';
import AsyncSelect from 'react-select/async';
import Axios from "axios";
import { MapContext } from "../ol/mapContext";
import GeoJSON from "ol/format/GeoJSON";
import { ForecastContext } from "../Forecasts";
import { Formik, Form } from "formik";
import { snakeCasizeKeys } from '../Map/Utils';
const SELECTED_FEATURE_ZOOM_LEVEL = 18

const customStyles = {
  control: (provided) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    width: '600px',
    height: '20px',
    border: '2px solid rgb(39, 44, 51, 1)',
    margin: '0px',
    padding: '0px'
  }),
  menu: (provided) => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    // width: '600px',
    zIndex: 60,
  }),
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    // width: 590,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}


const SearchBar = ({onSelect}) => {

  const [value, setValue] = useState('');
  const map = useContext(MapContext);
  const { currentForecast, showFunctional, disabledIntervals } = useContext(ForecastContext);


  const featureOption = (result, q) => {

    const key = result.Key || result.ogcFid || result.id;
    return {
      feature: result,
      value: key,
      label: <div className="search-result"><img src={`/layers/${result.layerId}/icon.svg`} /> {result.label}</div>,
    };
  };

  const searchFeatures = (inputValue) => {
    const re = /^-?1?[0-9]{1,2}\.\d{1,16}, +-?1?[0-9]{1,2}\.\d{1,16}/;

    if (inputValue.length < 3) {
      return Promise.resolve([]);
    }

    if (re.test(inputValue)) {
      // searchbar expects promise
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve([coordinatesOption(inputValue.split(",").map(Number))]);
        }, 1000);
      });
    }

    const visibleLayers = map
      .getLayers()
      .getArray()
      .filter((l) => l.getVisible())
      .map((l) => l.get("id"));
    const mapId = location.pathname.startsWith("/maps/") ? parseInt(location.pathname.replace("/maps/","")) : undefined
    return Axios.get("/features/search", {
      params: {
        q: inputValue,
        res_format:"simple",
        map: mapId
      },
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    })
      .then(({ data }) => {
        return data.map((feature) => featureOption(feature, inputValue));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const select = (selected)=>{

    if(onSelect)
    return Axios.get(`/layers/${selected.feature.layerId}/features/${selected.feature.id}.json`, {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    }) 
      .then(({ data }) => {
          onSelect(data);
      });
    
    
  }

  const debouncedSearch = useCallback(
    debounce((inputValue, callback) => {
      searchFeatures(inputValue).then(options => callback(options))
  }, 250)
  ,[]);

  return (
    <Formik
      enableReinitialize
      onSubmit={(e) => e.preventDefault()}
    >
      {() => (
        <Form className="" onSubmit={(e) => { e.preventDefault(); return false }}>

          <AsyncSelect
            name="q"
            onChange={select}
            placeholder="Rechercher ..."
            noOptionsMessage={() => "Aucune entrée correspondante"}
            loadingMessage={() => "Chargement ..."}
            styles={customStyles}
            cacheOptions
            loadOptions={debouncedSearch}
            defaultOptions
            autoFocus
            onSubmit={select}
            value={value}
          />
        </Form>
      )}
    </Formik>
  )
}


export { SearchBar }