import { useField } from "formik";
import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";

const year = 1970
const to_doy = (day: string, month: string)=> {
  if(!(day && month)) return;
  let start = new Date(year, 0, 0);
  let date = new Date(year, parseInt(month), parseInt(day));
  let diff = date.getTime() - start.getTime();
  let oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
}

const from_doy = (doy:number|undefined)=> {
  if(!(doy??-1>=0))return ["", ""]
  let start = moment(new Date(year, 0, 0));
  let date = start.add(doy, "d").toDate();
  return [date.getDate().toString(), date.getMonth().toString()]
}

const DOYInput = ({ ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  const {setValue} = helpers;
  const vals = from_doy(props.value);
  const [day, setDay] = useState(vals[0]);
  const [month, setMonth] = useState(vals[1]);
  const [nbDays, setNbDays] = useState(31)

  useEffect(()=>{ 
    if(!(day && month)) return;
    setValue(day && month ? to_doy(day, month) :undefined);
  }, [day,month])

  useEffect(()=>{ 
    setNbDays(month ? moment(new Date(year, parseInt(month),1)).daysInMonth():31)
  }, [month])

  return (
    <table>
      <tbody>
      <tr>

      <td>
        <select value={day} onChange={e => setDay(e.target.value)} className="form-control">
          {day==="" && <option value=""></option>}
          {
            [...Array(nbDays).keys()].map((key)=>{
              return <option key={key} value={key+1}>{key+1}</option>
            })
          }
        </select>
      </td>
      <td>
        <select value={month} onChange={e => setMonth(e.target.value)} className="form-control">
          {month==='' && <option value=""></option>}
          {
            [...Array(12).keys()].map((key)=>{
              return <option key={key} value={key}>{ moment(new Date(1900,key + 1,0)).format("MMM") }</option>
            })
          }
        </select>
      </td>
      <td>
        <button
          type="button"
          className="btn btn-outline-info btn-unset"
          onClick={() => {setDay(""); setMonth(""); setValue(null)}}
        >
          <i className="fas fa-times-circle"></i>
        </button>
      </td>
    </tr>
    </tbody>
    </table>
  );
};
export default DOYInput;