import React, { ReactNode, FC, useState, useEffect, MetaHTMLAttributes } from "react";
import { Button, Modal, OverlayTrigger, Popover, Spinner, ToggleButtonGroup } from "react-bootstrap";
import { ForecastableModel } from "../../types/ForecastableModel";
import ForecastableModelForm from "../ForecastableModel/Form";
import axios from "axios";
import fetchAndAggregate from "./fetchAndAggregate";
import { Toast } from "../Toast";
import ConfirmationModal from "../ConfirmationModal/Index";


interface Props {
  selectedForecastableModelId?: number;
  selectForecastableModel: (forecastableModel: ForecastableModel) => void;
}

const ForecastableModelSelector: FC<Props> = (props: Props) => {

  const [ShowModal, setShowModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [forecastableModels, setForecastableModels] = useState<ForecastableModel[]>([]);

  const deleteForecastableModelPopOver = (
    <Popover id="forecastableModelPopOver">
      <Popover.Title as="h3">Variable</Popover.Title>
      <Popover.Content>
        <p>
          Supprimer le modèle prévisionnel. Attention, si vous les supprimez tous les bulletins associés et les couches dynamiques qui en dépendent seront supprimés.
        </p>
      </Popover.Content>
    </Popover>
  )

  const handleSelect = (model: ForecastableModel) => {
    fetchAndAggregate(model).then((data) => {
      props.selectForecastableModel(data);
    })
  }

  const handleDeleteForecastableModel = (forecastableModel: ForecastableModel) => {
    axios.delete(`/forecastable_models/${forecastableModel.id}.json`)
      .then(() => {
        Toast.success({
          title: "Succès",
          body: "Modèle prévisionnel supprimé",
        })

        window.location.reload();
      })
      .catch((error) => {
        Toast.error({
          title: "Erreur",
          body: "Impossible de supprimer le modèle prévisionnel",
        })

      })
  }

  useEffect(() => {
    axios.get<ForecastableModel[]>(`/forecastable_models.json`)
      .then((response) => {
        setForecastableModels(response.data);
      });
  }, []);

  let selectedForecastableModelId = props.selectedForecastableModelId
  if (!selectedForecastableModelId && forecastableModels.length === 1) {
    handleSelect(forecastableModels[0]);
  }


  return (
    <div className="card w-100 h-100 p-0 m-0">
      <div className="card-header">Modèles prévisionnels</div>
      <div className="card-body">
        <div className="list-group list-group-horizontal">
          {
            props ? forecastableModels.map((forecastableModel) => {
              return (
                <a
                  key={forecastableModel.id}
                  className={`btn btn-light list-group-item list-group-item-action ${forecastableModel.id === selectedForecastableModelId ? "active" : ""} p-4`}
                  aria-current={forecastableModel.id === selectedForecastableModelId}
                  onClick={_e => handleSelect(forecastableModel)}
                >
                  {forecastableModel.name}

                  <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={deleteForecastableModelPopOver}>
                    <button
                      className="badge badge-pill badge-danger pull-right"
                      onClick={e => setShowConfirmationModal(true)}
                    >
                      ✕
                    </button>
                  </OverlayTrigger>
                  <ConfirmationModal
                    title="Suppression de modèle prévisionnel"
                    message={
                      <>
                        <p className="row">
                          Êtes vous sur de vouloir supprimer le modèle pévisionel {forecastableModel.name} ?

                        </p>
                        <p className="row">
                          Pour confirmer, entrez le nom du modèle prévisionnel : {forecastableModel.name}

                        </p>
                      </>
                    }
                    show={showConfirmationModal}
                    onCancel={() => setShowConfirmationModal(false)}
                    onConfirm={() => handleDeleteForecastableModel(forecastableModel)}
                    promptValue={forecastableModel.name}
                  />

                </a>
              )
            }) :
              <Spinner animation="border" role="status" />
          }
          <Button className="btn-success w-auto" onClick={_e => setShowModal(!ShowModal)}>+</Button>
        </div>
      </div>

      <Modal show={ShowModal} onHide={() => setShowModal(false)} size={"xl"} >
        <ForecastableModelForm forecastableModelId={undefined} />
      </Modal>
    </div>
  );
};

export default ForecastableModelSelector;
