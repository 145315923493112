import { CustomCellRendererProps } from '@ag-grid-community/react';
import React, {useState} from 'react';

const TagsRenderer = (props: CustomCellRendererProps) => {
    
    return (
        !props.value? "": props.value.map(v=><span className="react-tagsinput-tag" key={v}>{v}</span>)
    );
};

export default TagsRenderer;