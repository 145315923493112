import { CustomCellEditorProps } from '@ag-grid-community/react';
import React, {useState, useEffect, createRef} from 'react';
import TagsInput from 'react-tagsinput';

const TagsEditor = ({value, onValueChange}: CustomCellEditorProps) => {
    
    const [tags, setTags] = useState(value || [])
    const inputRef = createRef<HTMLDivElement>();

    useEffect(()=>{
        inputRef.current?.focus()
    },[])

    const handleChange = (ts) => {
        setTags(ts)
        onValueChange(ts)
    };


    return (
        <TagsInput value={tags} ref={inputRef} onChange={handleChange}/>
    );
};

export default TagsEditor;