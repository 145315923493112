export interface FeatureVariable {
    id: number,
    name: string,
    layerId: number,
    requirementIds: number[],
    createdAt: Date,
    updatedAt: Date,
    variableType: VariableType,
}

  export enum VariableType {
    Boolean = "boolean",
    Number = "number",
    String = "string",
  }