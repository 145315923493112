import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useField, useFormikContext } from 'formik';
import { FieldErrors } from './Error';

const Selector = ({ name, errorName, className, options, onChange, formik, disabled, ...props }) => {
  const { errors } = useFormikContext(); // Récupération des erreurs de Formik, via son Context 
  const [field, meta, helpers] = useField(name);

  const { value } = meta;
  const { setValue } = helpers;
  const isInvalid = !!(errors && errors[errorName || name])
  return (
    <>
      <Select
        placeholder="Sélectionner"
        noOptionsMessage={() => 'Aucune option'}
        options={options}
        isDisabled={disabled}
        {...field}
        {...props}
        onChange={option => {
          setValue(option.value)
          if (onChange) { onChange(option); }
        }}
        styles={{
          control: (provided, state) => ({
            ...provided,
            borderColor: isInvalid ? '#dc3545' : provided.borderColor,
            '&:hover': {
              borderColor: isInvalid ? '#dc3545' : provided.borderColor,
            }
          }),
        }}
        className={`${isInvalid ? ' is-invalid' : ''}`}
        value={(options ? options.find(option => option.value === value) : '')}
      />
      <FieldErrors name={name} errorName={errorName}/>
    </>
  )
}

Selector.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  disabled: PropTypes.bool,
};

Selector.defaultProps = {
  disabled: false,
}

export default Selector;
