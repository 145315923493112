import React, { useContext, useEffect, useMemo, useState } from 'react';
import LegendCtrl from 'ol-ext/control/Legend';
import Legend from 'ol-ext/legend/Legend';
import LegendItem from 'ol-ext/legend/Item';
import { MapContext } from '../ol/mapContext';
import { createColoredSquare } from '.';
import { ForecastContext } from '../Forecasts';
import { forecastTimeIntervals } from './colorScale'
import { unByKey } from 'ol/Observable';

const TimeLegend = ({ mapLegend }) => {
  const {
    currentForecast,
    disabledIntervals, setDisabledIntervals,
    showFunctional, setShowFunctional,
  } = useContext(ForecastContext)

  const map = useContext(MapContext);
  const [legend, setLegend] = useState();
  const [legendCallbackKey, setLegendCallbackKey] = useState()

  // Create Legend object on mount
  useEffect(() => {
    const timeLegend = new Legend({
      title: 'Les infrastructures \n dysfonctionnent:',
      margin: 3,
      size: [30, 30],
      collapsed: false,
      className: 'time-legend',
    });
    const legendControl = new LegendCtrl({
      legend: timeLegend,
      target: mapLegend.element
    })

    map.addControl(legendControl);

    setLegend(legendControl.getLegend());
    return () => map.removeControl(legend);
  }, [mapLegend]);

  const disableInterval = (e) => {
    if (e.index >= 0) {
      switch (e.item.get('title')) {
        case "Fonctionnent":
          const isFunctionalEnabled = showFunctional
          const newFunctionalDisabled = !isFunctionalEnabled
          setShowFunctional(newFunctionalDisabled)
          break;
        default:
          const isDisabled = disabledIntervals.indexOf(e.item.get('from')) > -1
          const newDisabled = isDisabled
            ? [...disabledIntervals.filter(i => i !== e.item.get('from'))].sort()
            : [...disabledIntervals, e.item.get('from')].sort()
          setDisabledIntervals(newDisabled)
      }


    }

  }

  useEffect(() => {
    if (legendCallbackKey) { unByKey(legendCallbackKey) }
    if (legend) {
      setLegendCallbackKey(legend.on('select', disableInterval))
    }
  }, [showFunctional, disabledIntervals, legend])

  // Update Legend as timeLegendRows changes
  useEffect(() => {
    if (legend) {
      if (currentForecast && currentForecast.forecastableModel && currentForecast.forecastableModel.timeIntervals && currentForecast.forecastableModel.timeIntervals.length) {
        legend.getItems().clear()
        const intervals = currentForecast.forecastableModel.timeIntervals

        intervals.forEach((interval, index) => {
          const intervalColor = forecastTimeIntervals({ currentForecast, index })
          if (intervalColor) {
            const disabled = disabledIntervals.includes(intervalColor.from)
            const item = new LegendItem({
              title: intervalColor.title,
              typeGeom: 'Point',
              style: createColoredSquare(disabled ? '#CCC' : intervalColor.color),
              ...intervalColor,
            })
            legend.addItem(item);
          } else {
            console.error("Interval color not found !", interval)
          }
        });

        const functionalItem = new LegendItem({
          title: "Fonctionnent",
          typeGeom: 'Point',
          style: createColoredSquare(showFunctional ? "#00FF00" : '#CCC'),
        })
        legend.addItem(functionalItem)
      }
    }
  }, [currentForecast, legend, disabledIntervals, showFunctional])

  return null;
};

TimeLegend.propTypes = {};

TimeLegend.defaultProps = {};

export default TimeLegend;
