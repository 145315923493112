import axios from "axios";
import React, { useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';
import { CustomCellEditorProps } from "ag-grid-react";


const customStyles = {
    control: (provided) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: '200px',
      height: '20px',
      // border: '2px solid rgb(39, 44, 51, 1)',
      margin: '0px',
      padding: '0px'
    }),
    menu: (provided) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      // width: '600px',
      zIndex: 60,
    })
  }

const AssetTypeEditor = ({value, onValueChange, stopEditing}: CustomCellEditorProps) =>
  {
    const [selectedAssetType, setSelectedAssetType] = useState({value:value, label:value});
    const [existingAssetTypes, setExistingAssetTypes] = useState([]);
    useEffect(()=>{
        axios.get("/assets/asset_types.json")
            .then(({ data }) => {
                setExistingAssetTypes(data.map((a)=>{return {value:a, label:a}}));
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);


    const handleChange = (selected) => {
        setSelectedAssetType(selected)
        onValueChange(selected.value)
        stopEditing();
    };


    return <CreatableSelect
        onChange={handleChange}
        isClearable
        autoFocus
        menuIsOpen={true}
        styles={customStyles}
        options={existingAssetTypes}
        value={selectedAssetType} 
      />
      
  }
export default AssetTypeEditor;