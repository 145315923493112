import { Formik, FormikProps } from 'formik';
import React, { isValidElement } from 'react';

interface Props<Values> {
  initialValues: Values;
  onSubmit: (values: Values) => void;
  children?: ((props: FormikProps<Values>) => any) | any;
}

function NestedForm<Values>({
  initialValues,
  onSubmit,
  children,
}: Props<Values>) {
  function onKeyDownCapture(form: FormikProps<Values>) {
    return (e: any) => {
      if (e.key === 'Enter') {
        if (
          e.target instanceof HTMLInputElement ||
          e.target instanceof HTMLSelectElement ||
          (e.target instanceof HTMLButtonElement && e.target.type !== 'button')
        ) {
          e.preventDefault(); // Prevents outer form from submitting
          onSubmit(form.values);
        }
      } else if (e.key === 'Space') {
        checkForSubmitButton(e);
      }
    };
  }

  function checkForSubmitButton(form: FormikProps<Values>) {
    return (e: any) => {
      if (e.target instanceof HTMLButtonElement && e.target.type !== 'button') {
        e.preventDefault();
        onSubmit(form.values);
      }
    };
  }

  return (
    <Formik initialValues={initialValues} onSubmit={() => null}>
      {form => (
        <div onKeyDownCapture={onKeyDownCapture(form)} onClick={checkForSubmitButton(form)}>
          {isValidElement(children) ? children : children(form)}
        </div>
      )}
    </Formik>
  );
}
export default NestedForm;