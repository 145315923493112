import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import { ForecastContext } from '../Forecasts';
import ForecastSourceSelector from "./ForecastSourceSelector";

const SELECTED_STATION_IDS_KEY = "informationStripSelectedStationId"
const MAX_DISPLAYED_FORECAST_VALUES = 4

/**
 * Load the displayed forecast values from the browser's local storage
 */
const loadSavedDisplayedForecastValues = () => {
  const savedDisplayedValues = localStorage.getItem(SELECTED_STATION_IDS_KEY)
  if (savedDisplayedValues) {
    let jsonSavedDisplayedValues;
    try {
      jsonSavedDisplayedValues = JSON.parse(savedDisplayedValues)
    } catch (e) {
      console.error("Error while parsing the saved displayed forecast values", e)
    }
    if (Array.isArray(jsonSavedDisplayedValues)) {
      return jsonSavedDisplayedValues.filter(v => (typeof v === 'object' && v !== null))
    } else {
      return []
    }
  }
  return [];
}


const ForecastSourceDisplay = ({ }) => {
  const { currentForecast } = useContext(ForecastContext);
  const [displayedForecastValues, setDisplayedForecastValues] = useState<ForecastValue[]>(loadSavedDisplayedForecastValues())
  const [currentForecastValues, setCurrentForecastValues] = useState<ForecastValue[]>([])
  const [hasForecastValues, setHasForecastValues] = useState(true);

  // Get the current forecast values when the current forecast changes
  useEffect(() => {
    if (currentForecast) {
      const currentValues = currentForecast.forecastValues
        .filter(val => val.timeInterval === 0)
        .sort((a, b) => a.featureName.localeCompare(b.featureName))
        setHasForecastValues(currentValues.length>0)
        setCurrentForecastValues(currentValues)
    }
  }, [currentForecast])

  // Update the displayed forecast values when the current forecast values change
  useEffect(() => {
    const updatedDisplayedForecastValues = [...(displayedForecastValues || [])]
    currentForecastValues
      .filter(v => v.timeInterval === 0)
      .forEach((v) => {
        const displayedIndex = displayedForecastValues.findIndex(d => d.featureId === v.featureId && d.featureType === v.featureType)
        if (displayedIndex !== -1) {
          updatedDisplayedForecastValues[displayedIndex] = { featureType: v.featureType, featureId: v.featureId, value: v.value }
        }
      })
    setDisplayedForecastValues(updatedDisplayedForecastValues)
  }, [currentForecastValues])

  // Set the visible forecast value for the given station, at the given position
  function setVisibleForecastValueAtIndex(featureSlug, index) {
    const updatedDisplayedForecastValues = [...(displayedForecastValues || [])]
    const [featureType, featureId] = featureSlug.split('/').map((s, i) => (i === 1 ? parseInt(s, 10) : s))
    const value = currentForecast?.forecastValues?.find(f => (
      f.featureId === featureId &&
      f.occursAt === currentForecast?.issuedAt &&
      f.featureType === featureType)
    )?.value
    updatedDisplayedForecastValues[index] = { featureType, featureId, value }
    setDisplayedForecastValues(updatedDisplayedForecastValues)
  }

  // Save the displayed forecast values when they change
  useEffect(() => {
    const forecastsFeatures = displayedForecastValues?.map(({ featureType, featureId }) => ({ featureType, featureId }))
    localStorage.setItem(SELECTED_STATION_IDS_KEY, JSON.stringify(forecastsFeatures))
  }, [displayedForecastValues])

  // Add a new forecast value to the list of displayed values
  const addDisplayableForecastValue = () => (
    setDisplayedForecastValues([...(displayedForecastValues || []), { featureId: 0, featureType: null }])
  )

  // Remove the forecast value at the given position from the displayable values
  const removeDisplayableForecastValue = (index) => {
    const updatedDisplayedForecastValues = [...(displayedForecastValues || [])]
    updatedDisplayedForecastValues.splice(index, 1)
    setDisplayedForecastValues(updatedDisplayedForecastValues)
  }

  if (!currentForecast?.id) { return null }
  return <div className="stations-data-container">
    {hasForecastValues && <>
      <span> Hauteur à </span>
      <div className="stations-container">
        {displayedForecastValues.length
          ? displayedForecastValues.map((v, index) =>
            <ForecastSourceSelector
              key={`source-selector-${index}-${v.featureType}/${v.featureId}`}
              selected={v}
              selectorIndex={index}
              currentForecastValues={currentForecastValues}
              disabledForecastValues={displayedForecastValues}
              setVisibleForecastValueAtIndex={setVisibleForecastValueAtIndex}
              addDisplayableForecastValue={addDisplayableForecastValue}
              removeDisplayableForecastValue={removeDisplayableForecastValue}
            />
          ) : 
          <ForecastSourceSelector
            selected={{
              featureId: 0,
              featureType: 'unknown',
            }}
            selectorIndex={0}
            currentForecastValues={currentForecastValues}
            disabledForecastValues={displayedForecastValues}
            setVisibleForecastValueAtIndex={setVisibleForecastValueAtIndex}
            addDisplayableForecastValue={addDisplayableForecastValue}
            removeDisplayableForecastValue={removeDisplayableForecastValue}
          />
        }
      </div>
      </>
    }
    
  </div>
};

export default ForecastSourceDisplay;