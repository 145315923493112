import React, { useContext, useEffect, useState } from 'react';
import LegendCtrl from 'ol-ext/control/Legend';
import Legend from 'ol-ext/legend/Legend';
import { MapContext } from '../ol/mapContext';
import { createColoredSquare } from '.';
import LegendItem from 'ol-ext/legend/Item';
import LayersContext from '../ol/LayersContext';

const CustomLegend = ({ mapLegend }) => {
  const map = useContext(MapContext);
  const [mapVersion, setMapVersion] = useState();
  const { layers } = useContext(LayersContext);
  const [legends, setLegends] = useState([]);

  // Invalidate map version when map changes
  // This will trigger a re-render of the legend
  // Otherwise, old layers legend may be displayed
  map.getLayers().on("add", (e) => {
    setMapVersion(new Date().getTime())
  });


  useEffect(() => {
    setLegends(
      layers.filter(layer =>
        layer.legendColors
        && layer.legendColors.length
        && layer.legendColors.some(({ display }) => display)
        && layer.olLayer
      ).map(layer => {
        const partialLegend = new Legend({
          title: layer.name,
          margin: 5,
          size: [20, 20],
        });
        layer.legendColors.forEach(({
          title,
          display,
        }) => {
          if(!display) return;
          
          const legendItem = new LegendItem({
            title,
            typeGeom: 'Point',
            style: createColoredSquare(display.fill),
          })
          partialLegend.addItem(legendItem)
        });
        const legendControl = new LegendCtrl({
          legend: partialLegend,
          target: mapLegend.element,
        })
        layer.olLayer.get('visible') ? legendControl.show() : legendControl.hide()
        layer.olLayer.on('change:visible', (e) => {
          e.target.get('visible') ? legendControl.show() : legendControl.hide()
        });
        return legendControl;
      })
    )
  }, [layers, mapVersion]);

  useEffect(() => {
    if (legends) { legends.forEach(legend => map.addControl(legend)); }
    return () => legends.forEach(legend => map.removeControl(legend));
  }, [legends]);

  return null;
};

CustomLegend.propTypes = {
};

CustomLegend.defaultProps = {
};

export default CustomLegend;
