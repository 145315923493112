import axios from "axios";
import React, { useEffect, useState } from "react"
import Plotly from 'plotly.js-dist-min';

export const WaterLevelChart = ({title, layerId, stationId}) => {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
      axios.get(`/layers/${layerId}/features/${stationId}/vigicrues`)
        .then(res => {
          if(!res.data || !document.getElementById("water-heights-plot-"+stationId))
            return;

          const layout = {
            title: title,
            plot_bgcolor: 'rgba(255, 255, 255, 0.05);', 
            paper_bgcolor: 'rgba(255, 255, 255, 0.05);',
            font: {color: '#7FDBFF'},
            showlegend: false,
            xaxis: {
            fixedrange: true,
            tickformat: "%d/%m %H:%M",
              linecolor:"#888",
              tickcolor:"#888",
              gridcolor:"#666"
            },
            yaxis: {
              title: "Hauteur d'eau (m)",
              fixedrange: true,
              linecolor:"#888",
              tickcolor:"#888",
              gridcolor:"#666"
            },
            margin:{l:50,r:10,b:25,t:30},
            height:240
            
          };
          let data = [
            {
              "x": Object.keys(res.data).map(d=>new Date(d)),
              "y": Object.values(res.data),
              line:{"width":3},
              hovertemplate: "%{y}m<extra> %{x}</extra>"
            }
          ]
          setLoading(false);
          Plotly.newPlot("water-heights-plot-"+stationId,{
            "data": data,
            "layout": layout
          })
        });
  }, [])

  return <>{loading && <div style={{textAlign:"center"}}><h6></h6><i className="fas fa-spinner fa-spin"></i> Chargement des mesures en cours ...</div>}<div id={`water-heights-plot-${stationId}`} className="no-controls"></div></>;
}