import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Map from './Map';
import { mapBoxizeKeys, camelize } from './Map/Utils';
import NavigationBar from './NavigationBar';
import Axios from 'axios';
import ForecastContext from './Forecasts/Context';
import { Toast } from './Toast';
import ForecastUpdateNotifier from './ForecastUpdateNotifier';
import I18n from "../packs/translations"


const MapContainer = ({ id, forecastableModelId }) => {
  const [organisationId, setOrganisationId] = useState(undefined)
  const [style, setStyle] = useState({})
  const [states, setStates] = useState({})
  const [layout, setLayout] = useState([])
  const [forecasts, setForecasts] = useState([])
  const [scenarios, setScenarios] = useState([])
  const [currentForecast, setCurrentForecast] = useState()
  const [currentForecastId, setCurrentForecastId] = useState('latest')
  const [currentForecastInterval, setCurrentForecastInterval] = useState()
  const [disabledIntervals, setDisabledIntervals] = useState([])
  const [isObsolete, setIsObsolete ] = useState(true)
  const [showFunctional, setShowFunctional] = useState(false)

  useEffect(() => {
    Axios.get(`/maps/${id}/organisation.json`)
      .then(({data}) => {
        setOrganisationId(data);
      })
  },[])
  

  const addSymbolStyle = style => {
    for (let l of style.layers){
      if(l["type"] == "symbol"){
        if(l.paint["icon-image"])
        {
          l.layout["icon-image"] = l.paint["icon-image"];
          delete l.paint["icon-image"];
        }
        else 
        {
          l.layout["icon-image"] = l.icon + (l.paint["icon-style"] ? "?" + l.paint["icon-style"] :"");
          delete l.paint["icon-style"];
        }
        l.layout["icon-overlap"] = "always";
        l.layout["text-allow-overlap"] = true;
        l.layout["text-field"] = '{label}';
        l.layout["text-font"] = ["Open Sans Regular"];
        l.layout["text-size"] = 14;
        l.layout["text-offset"] = [0,1.5];
        l.layout["text-anchor"] = 'top';

        l.paint["text-color"] = "white";
        l.paint["text-halo-color"] = "black";
        l.paint["text-halo-width"] = 1;
      }
    }
  }

  const addForecastStyle = style => {
    
    for (let l of style.layers){
      if(l["layer-type"] === "stake" && l["type"] !== "symbol"){
        l.paint[l.type+'-color'] = 
        [
          "let",
          "dys_in",
          ["number",["feature-state", "dys_in"],9999],
          [
            "case",
            ["<",["var","dys_in"],2], "#FF0000",
            ["<",["var","dys_in"],24], "#FF8800",
            ["<",["var","dys_in"],9998], "#FFFF00",
            l.paint[l.type+'-color']
          ]
        ];
      }
      else if (l.layerType === "static"){

      }
    }
  }


  const addSelectStyle = style => {
    let hoverLayers = []
    for (let l of style.layers){
      if(l.type==="raster") continue;
      let hLayer = {'id': l.id+'-sel','source':l.source,'source-layer':l["source-layer"]}
      hLayer["type"]="line"
      hLayer["paint"] = {};
      hLayer.paint["line-width"] = 6;
      hLayer.paint["line-blur"] = 6;
      if(l.type=="line")
      {
        hLayer["paint"] = structuredClone(l.paint);
        hLayer.paint["line-gap-width"] = l.paint["line-width"] || 1;  
        hLayer.paint["line-width"] = 2;  
        hLayer.paint["line-blur"] = 2;

      }
      hLayer.paint["line-color"] = "blue";
      hLayer.paint["line-opacity"] = [
        'case',
        ['boolean', ['feature-state', 'selected'], false],
        0.6,
        0
      ];
      
      hoverLayers.push(hLayer);
    }
    for(const hLayer of hoverLayers)
      style.layers.push(hLayer)
  }

  useEffect(() => {
    Axios.get(`/maps/${id}.json`)
      .then(res => {

        const addStyle = async (styledata) => {      
          mapBoxizeKeys(styledata.sources);
          for (let l of styledata.layers){
            mapBoxizeKeys(l);
            for (let k in l)
              mapBoxizeKeys(l[k]);
          }

          addSymbolStyle(styledata)
          addForecastStyle(styledata);
          addSelectStyle(styledata);

          if(!window.location.hash)
            window.location.hash = styledata.defaultZoom+'/'+styledata.latitude+'/'+styledata.longitude;

          setStyle({
            ...styledata
          })

          if(isObsolete) loadForecast(currentForecastId, styledata);
        }

        const deriveLayout = (layerdata) => {
          const layout = layerdata.map(l=>{
            const storedLayout = JSON.parse(localStorage.getItem("layerLayout"+l.id)??"{}")
            if(storedLayout)
            {
              if(storedLayout["visible"]!==undefined)l.layout['visibility']=storedLayout["visible"]?"visible":"none";
              if(storedLayout["opacity"]!==undefined)l.paint[camelize(l.type)+"Opacity"] = storedLayout["opacity"];
            }
            return {
              id: l.id,
              name: l.name,
              layerType: l.layerType,
              publicNames: Object.fromEntries(l.publicNames),
              selectable: ["stake","static"].indexOf(l.layerType)>=0,
              uuid: l.uuid,
              visible: l.layout['visibility'] === 'visible',
              opacity: l.paint[l["type"] == "symbol"?"icon":camelize(l.type)+"Opacity"],
              geomtype: l["type"] == "symbol"?"icon":l.type,
              spec: l.sourceLayer
            }
            
          });
          setLayout(layout);
        }

        deriveLayout(res.data.layers)
        
        addStyle(res.data).catch(console.error)
      })
      .catch(err => {
        console.error(err)
        Toast.error({ title: I18n.t('error'), text: I18n.t('Could not load example map') })
      })
  }, [id])

  useEffect(() => {
    if(!id) return;
    Axios.get(`/layers/scenarios.json?oid=${organisationId}`)
      .then(res => {
        setScenarios(res.data);
      })
    },[id, organisationId])
    

    useEffect(() => {
      if(!id || !forecastableModelId) return;
      Axios.get(`/forecastable_models/${forecastableModelId}/forecast_bulletins.json?oid=${organisationId}`)
        .then(res => {
          setForecasts(res.data);
        })
      },[id, forecastableModelId, organisationId])
      

  const loadForecast = (forecastId, styledata) => {
    if (forecastableModelId && forecastId) {
      return fetch(`/forecastable_models/${forecastableModelId}/forecast_bulletins/${forecastId}.json`)
        .then((response) => response.json())
        .then((updatedForecast) => {
          setCurrentForecast(updatedForecast)
          setCurrentForecastInterval(0)
          if(!styledata) styledata=style;
          if (Object.keys(styledata).length === 0 ) return;

          for(const k in styledata["sources"])
            if(k.endsWith("static"))
              styledata["sources"][k]["tiles"][0] = 
                styledata["sources"][k]["tiles"][0].split("?")[0] 
                + "?stations=" + updatedForecast["forecastLayers"][0]["url"];

          let url = `/forecast_bulletins/${updatedForecast.id}/${updatedForecast.forecastableModel.functionalType}/${id}.json`;
          
          Axios.get(url).then((response=>{
            const states = response.data 
            setStates({...states});
            setIsObsolete(false)
          })).catch((err)=>console.error({err}))
        })
        .catch((err) => 
        {
          console.error(err);
          setCurrentForecast(null)
        })
    }
    return Promise.resolve()
  }

  const onChangeScenario = (selected) => {
    if (selected.indexOf("bulletin: ")===0)
      setCurrentForecastId(selected.replace(("bulletin: "),""));
    else if (selected.indexOf("scenario: ")===0)
      setCurrentForecast({'layer':selected.replace(("scenario: "),"")});
  }

  const removeInvisible = (layout) =>{
    let res = [...layout]
    // res.filter((l)=>{
    //   console.log(l)
    // })
    return res;
  }

  const layoutMap = () => { 
    setLayout(removeInvisible(layout)) 
  }

  useEffect(() => {
    loadForecast(currentForecastId)
  }, [currentForecastId])
  return (
    <React.StrictMode>
      {/* <Provider store={Store}> */}
      {/* <LayersProvider layers={layout}> */}
        <ForecastContext.Provider value={
          {
            currentForecast, setCurrentForecastId, setCurrentForecast,
            setDisabledIntervals, disabledIntervals,
            setIsObsolete, isObsolete,
            showFunctional, setShowFunctional,
            loadForecast
          }
        }>
          <meta name="turbolinks-visit-control" content="reload"></meta>

          <ForecastUpdateNotifier />
          <div className="map-container">
            <NavigationBar 
              organisation={organisationId}
              layers={layout} 
              layerAggregations={style?.layerAggregations} 
              forecasts={forecasts}
              scenarios={scenarios}
              mapDisplayed={true} 
              style={style} 
              onChange={layoutMap} 
              onChangeScenario={onChangeScenario} />
            <div id="right-panel" className="right-panel">
              <Map
                name="base"
                style={style}
                states={states}
                layout={layout}
                forecastLayer={currentForecast?.layer}
                forecastInterval={currentForecastInterval} />
            </div>
          </div>
        </ForecastContext.Provider>
      {/* </LayersProvider> */}
      {/* </Provider> */}
    </React.StrictMode>

  );
};

MapContainer.propTypes = {
  id: PropTypes.number,
  forecasttableModelId: PropTypes.number,
};

MapContainer.defaultProps = {
  id: null,
};

export default MapContainer;