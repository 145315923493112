import { Layer } from '../../types/Layer';
import React from 'react';

const VISIBLE_LAYER_ICON_COLOR = "#FFFFFF";
const HIDDEN_LAYER_ICON_COLOR = "#444444";
const LAYER_ICON_SIZE = "15px"

const layerIconHTML = (layer: Layer, visible: Boolean) => {
  if (layer.iconSvgContent) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(layer.iconSvgContent, "image/svg+xml");
    doc.documentElement.setAttribute("width", LAYER_ICON_SIZE);
    doc.documentElement.setAttribute("height", LAYER_ICON_SIZE);
    if (typeof (visible) === "boolean") {
      Array.from(doc.documentElement.children).forEach((node) => {
        if (node.nodeName === "path") {
          node.setAttribute("stroke", visible ? VISIBLE_LAYER_ICON_COLOR : HIDDEN_LAYER_ICON_COLOR);
          node.setAttribute("fill", visible ? VISIBLE_LAYER_ICON_COLOR : HIDDEN_LAYER_ICON_COLOR);
        }
      });
    }

    const svgElement = layer.iconSvgContent ? (
      <span
        dangerouslySetInnerHTML={{ __html: doc.documentElement.outerHTML }}
        style={{
          float: "left",
          padding: 0,
          position: "absolute",
          left: 0
        }}
      />
    ) : (
      <svg>
        <use xlinkHref={layer.iconSvgPath} />
      </svg>
    );
    return svgElement;
  } else {
    if (typeof (visible) === "boolean") {
      return visible
        ? <i className="fas fa-eye" style={{ marginRight: "0.5em", color: VISIBLE_LAYER_ICON_COLOR }}></i>
        : <i className="fas fa-eye-slash" style={{ marginRight: "0.5em", color: HIDDEN_LAYER_ICON_COLOR }}></i>
    } else {
      return <i className="fas fa-eye" style={{ marginRight: "0.5em" }}></i>
    }
  }
}

export { layerIconHTML }