import axios from "axios";
import React, { useContext, useEffect } from "react";
import { Layer } from "../../types/Layer";

interface Props {
  layer: Layer;
  frameId: string;
}

const DynamicLayerFrame = (props: Props) => {



  return (
    <div className="col bg-info m-1 p-1 align-middle text-center text-wrap rounded-pill" id={props.frameId}>
      {props.layer.name}
    </div>
  )
}

export default DynamicLayerFrame;