import React from 'react'
import FeaturePublicDisplaysTable from './Table'
import FeaturePublicDisplaysForm from './Form'
import FeaturePublicDisplay from '../../types/FeaturePublicDisplay'

interface FeaturePublicDisplaysIndexProps {
  layerId: number
}

const FeaturePublicDisplaysIndex = ({ layerId }: FeaturePublicDisplaysIndexProps): JSX.Element => {
  const [selectedFeaturePublicDisplay, setSelectedFeaturePublicDisplay] = React.useState({} as FeaturePublicDisplay)
  const [featurePublicDisplays, setFeaturePublicDisplays] = React.useState([] as FeaturePublicDisplay[])


  const loadFeaturePublicDisplays = () => {
    fetch(`/layers/${layerId}/feature_public_displays.json`)
      .then((response) => response.json())
      .then((data) => {
        setFeaturePublicDisplays(data)
      })
  }

  return (
    <div className="feature-public-displays-form">
      <FeaturePublicDisplaysTable layerId={layerId}
        selectedFeaturePublicDisplay={selectedFeaturePublicDisplay}
        setSelectedFeaturePublicDisplay={setSelectedFeaturePublicDisplay}
        featurePublicDisplays={featurePublicDisplays}
        loadFeaturePublicDisplays={loadFeaturePublicDisplays} />
      <FeaturePublicDisplaysForm
        layerId={layerId}
        editedFeaturePublicDisplay={selectedFeaturePublicDisplay}
        setEditedFeaturePublicDisplay={setSelectedFeaturePublicDisplay}
        onChange={loadFeaturePublicDisplays} />
    </div>
  )
}

export default FeaturePublicDisplaysIndex

export { FeaturePublicDisplaysIndex, FeaturePublicDisplaysTable, FeaturePublicDisplaysForm }