import { FeatureVariable } from "./FeatureVariable";

export type Comparator = "eq" | "lt" | "lte" | "gt" | "gte";

export const ComparatorLabels: Record<Comparator, string> = {
  eq: "égal",
  lt: "inferieur",
  lte: "inferieur ou égal",
  gt: "superieur",
  gte: "superieur ou ou égal",
};

export interface Requirement {
  id: number;
  featureAttribute: string;
  featureResult: string;
  comparator: Comparator;
  dependencyType: string;
  dependencyId: number;
  dependencyVariableId: number;
  featureDependencyConfigId: number;
  createdAt: Date;
  updatedAt: Date;
}
