import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { useForm, useFieldArray } from "react-hook-form";

import { Toast } from "../Toast";
// import usePortal from "react-cool-portal";

const TIME_INTERVALS = [
  { label: "Heures", value: "hours" },
  { label: "Journées", value: "days" },
  { label: "Semaines", value: "semaines" },
];

const EMPTY_FORECASTABLE_MODEL = {
  name: ""
}

const ForecastableEntityForm = ({ forecastableModelId, organisations }) => {

  const [intervalColors, setIntervalColors] = useState([]);

  // ForecastableEntity to edit / create
  const [forecastableModel, setForecastableModel] = useState(EMPTY_FORECASTABLE_MODEL);
  // Available forecastable entities to choose from
  const [forecastableClasses, setForecastableClasses] = useState([]);
  // available layer attributes to chose from
  const [forecastedLayerAttributes, setForecastedLayerAttributes] = useState([]);

  const [errors, setErrors] = useState([]);
  const IMPORT_KEY = "import";

  const filteredOutKeys = ['createdAt', 'updatedAt', 'forecastableModelId', 'forecastAlternatives', 'forecastableModelEntries', 'color', 'url']

  const filterOutKeys = (obj) => {
    filteredOutKeys.forEach(key => delete obj[key])
    return obj
  }

  const { register, handleSubmit, control, reset, setValue, getValues, watch, formState: { errors: formErrors, ...otherFormState } } =
    useForm({
      defaultValues: forecastableModel
    });

  const watchForecastedType = watch("forecastedType", forecastableModel?.forecastedType);
  const isCustomEntries = useMemo(() => watchForecastedType === IMPORT_KEY, [watchForecastedType]);

  const {
    fields: entryFields,
    append: appendModelEntry,
    remove: removeModelEntry,
  } = useFieldArray({
    control,
    name: "forecastableModelEntries",
    keyName: "uuid",
  });

  const {
    fields: intervalFields,
    append: appendInterval,
    remove: removeInterval,
  } = useFieldArray({
    control,
    name: "timeIntervals",
  });

  const watchintervalFields = watch("timeIntervals", forecastableModel?.timeIntervals);

  useEffect(() => {
    updateIntervalFieldsColors();
  }, [watchintervalFields]);

  const {
    fields: alternativeFields,
    append: appendAlternative,
    remove: removeAlternative,
  } = useFieldArray({
    control,
    name: "forecastAlternatives",
    keyName: "uuid",
  });

  // Set default alternative value if not defined
  useEffect(() => {
    if (!alternativeFields.length) {
      appendAlternative({ name: "" });
    }
  }, [alternativeFields]);

  // Set default source entry value if not defined
  useEffect(() => {
    if (isCustomEntries && !entryFields.length) {
      appendModelEntry({ name: "" });
    }
  }, [entryFields]);

  // Form CSRF token
  const csrfToken = document.querySelector("[name=csrf-token]")?.content;
  if (axios.defaults) {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
  }

  // Get forecastable entity to edit or create
  useEffect(() => {
    const url = forecastableModelId
      ? `/forecastable_models/${forecastableModelId}.json`
      : `/forecastable_models/new.json`;
    axios.get(url).then((res) => setForecastableModel(res.data));
  }, [forecastableModelId]);

  // Get forecastable entities to choose from
  useEffect(() => {
    axios
      .get("/forecastable_models/available.json")
      .then((res) => setForecastableClasses(res.data));
  }, [forecastableModelId]);

  // Get forecasted feature attributes
  useEffect(() => {
    if (!watchForecastedType || watchForecastedType == IMPORT_KEY) return;

    axios
      .get(`/features/${watchForecastedType}.json`)
      .then((res) => setForecastedLayerAttributes(res.data));


  }, [watchForecastedType]);

  // Reset form if ForecastableEntity changes
  useEffect(() => {
    reset(forecastableModel);
  }, [forecastableModel]);

  // generate new colors depending on intervalfields
  const updateIntervalFieldsColors = () => {
    axios.get(`/layer_styles/gradient/${intervalFields.length}.json`).then((res) => setIntervalColors(res.data))
  }


  // Go back to main page
  const backToList = () => {
    window.location = `/forecastable_models`;
  };

  /**
   * Handle Rails nested attributes
   * @param {*} req
   * @param {*} attributeName
   * @returns
   */
  const formatNestedAttributes = (req, attributeName) => (

    (forecastableModel[attributeName]?.length
      ? [
        ...forecastableModel[attributeName]
          .filter(
            (alt) =>
              !req[attributeName].find(
                (reqAlt) => parseInt(reqAlt.id, 10) === alt.id
              )
          )
          .map(({ id }) => ({ id, _destroy: true })),
        ...req[attributeName],
      ]
      : req[attributeName]
    ).map(entry => filterOutKeys(entry)))

  /**
   * Save ForecastableModel to server
   * @param {*} req
   */
  const save = (req) => {
    const [url, method] = forecastableModel.id
      ? [`/forecastable_models/${forecastableModel.id}.json`, "patch"]
      : [`/forecastable_models.json`, "post"];
    const data = {
      ...req,
      // Mark removed alternatives as "to destroy"
      forecastAlternativesAttributes: formatNestedAttributes(req, 'forecastAlternatives'),
      forecastableModelEntriesAttributes: isCustomEntries ? formatNestedAttributes(req, 'forecastableModelEntries') : [],
    };


    axios[method](url, { forecastable_model: filterOutKeys(data) })
      .then(({ data }) => {
        setForecastableModel(data);
        setErrors([]);
      })
      .then(() => Toast.success({ title: "Enregistré" }))
      .then(backToList)
      .catch((res) => {
        Toast.error({
          title:
            res.response.status === 422
              ? "Données invalides"
              : res.response.statusText,
        });
        if (res.response.status === 422) {
          setErrors(res.response.data);
        }
      });
  };

  // Set default value for time interval if empty
  if (!intervalFields.length) {
    appendInterval({ from: 0, to: 24 });
    updateIntervalFieldsColors();
  }


  /**
   * Data source selector.
   */
  const SelectDataSource = React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
    <div className="form-group row">
      <label
        htmlFor={name}
        className="col-sm-2 col-form-label"
      >
        {label}
      </label>
      <div className="col-sm-10">
        <select
          name={name}
          ref={ref} onChange={onChange} onBlur={onBlur}
          id="forecastedType"
          className={`form-control ${formErrors[name] ? "is-invalid" : ""}`}
          defaultValue={forecastableModel?.forecastedType}
        >
          <option
            disabled
            aria-label="Vide"
            value=""
            key="feature-variable-empty"
          />
          <option value={IMPORT_KEY} className="text-secondary">
            Custom
          </option>
          {forecastableClasses.map((attr) => (
            <option value={attr} key={`forecastableEntitie-${attr}`}>
              {attr} {attr === watchForecastedType ? "(actuel)" : ""}
            </option>
          ))}
        </select>
        {formErrors[name]?.message && (
          <div className="invalid-feedback">
            {formErrors[name]?.message}
          </div>
        )}
      </div>
    </div>
  ));

  /**
 * Attribute selector.
 */
  const SelectFeatureAttribute = React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
    <div className="form-group row">
      <label
        htmlFor={name}
        className="col-sm-2 col-form-label"
      >
        {label}
      </label>
      <div className="col-sm-10">
        <select
          name={name}
          ref={ref} onChange={onChange} onBlur={onBlur}
          id="attributeName"
          className={`form-control ${formErrors[name] ? "is-invalid" : ""}`}
          defaultValue={forecastableModel?.attributeName}
        >
          <option
            disabled
            aria-label="Vide"
            value=""
            key="feature-attribute-empty"
          />
          {Object.keys(forecastedLayerAttributes).map((attr) => (
            <option value={attr} key={`feature-attribute-${attr}`}>
              {attr}
            </option>
          ))}
        </select>
        {formErrors[name]?.message && (
          <div className="invalid-feedback">
            {formErrors[name]?.message}
          </div>
        )}
      </div>
    </div>
  ));


  const CustomSourcesList = React.forwardRef(({ onChange, onBlur, name, label }, ref) => (
    watchForecastedType === null || watchForecastedType === IMPORT_KEY ? (
      <div className="form-group row">
        <label className="col-sm-2 col-form-label" htmlFor={name}>
          {label}
        </label>
        <div className="col-sm-10">
          {entryFields.length ? (
            entryFields.map((entry, index) => (
              <div
                className="row"
                key={`forecastableModelEntries-${entry.uuid}`}
                id={`forecastableModelEntries-${entry.uuid}`}
              >
                <div className="col-sm-10">
                  {
                    /* Hidden field for ID */
                    {
                      forecastableModelEntries: [],
                      ...forecastableModel,
                    }.forecastableModelEntries[index] && (
                      <input
                        onChange={onChange} onBlur={onBlur}
                        {...register(`forecastableModelEntries[${index}].id`)}
                        type="hidden"
                        defaultValue={entry.id}
                      />
                    )
                  }
                  <input
                    type="input"
                    onChange={onChange} onBlur={onBlur}
                    {...register(`forecastableModelEntries[${index}].name`)}
                    className={`form-control ${errors[`forecastableModelEntries[${index}].name`]
                      ? "is-invalid"
                      : ""
                      }`}
                    name={`forecastableModelEntries[${index}].name`}
                    defaultValue={entry.name}
                  />
                  {errors[
                    `forecastableModel.forecastableModelEntries[${index}]`
                  ]?.length && (
                      <div className="invalid-feedback">
                        {
                          errors[
                          `forecastableModel.forecastableModelEntries[${index}]`
                          ]
                        }
                      </div>
                    )}
                </div>
                <div className="col-sm-1">
                  <button
                    className="btn btn-link"
                    onClick={() => removeModelEntry(index)}
                    type="button"
                  >
                    <i className="fas fa-times text-danger"></i>
                  </button>
                </div>
              </div>
            ))
          ) : (
            <span className="col-sm-2 col-form-label">
              Aucune source.
            </span>
          )}
        </div>
        <div className="col-12 p-4 text-right">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => {
              appendModelEntry({ name: "", id: "" });
            }}
          >
            Ajouter une source de prévision
          </button>
        </div>
      </div>
    ) : null
  ));

  const onSubmit = save;


  return (
    <React.StrictMode>
      <div className="card">
        <div className="card-header">
          <h1>
            {forecastableModelId
              ? `Édition de ${forecastableModel.name}`
              : "Nouveau type de prévision"}
          </h1>
        </div>

        <p></p>

        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
          {organisations && (
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="name">
                Organisation
              </label>
              <div className="col-sm-10">
                <select
                    id="organisation"
                    className={`form-control ${errors.organisation ? "is-invalid" : ""
                      }`}
                    name="organisation_id"
                    defaultValue={forecastableModel.organisation}
                    {...register('organisation_id', { required: true })}
                  >
                    {organisations.map((orga) => (
                      <option
                        value={orga.id}
                        key={`orga-${orga.id}`}
                      >
                        {orga.name}
                      </option>
                    ))}
                  </select>
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </div>
            </div> 
          )}
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="name">
                Nom
              </label>
              <div className="col-sm-10">
                <input
                  {...register('name', { required: true })}
                  id="name"
                  className={`form-control ${errors.name ? "is-invalid" : ""}`}
                  defaultValue={forecastableModel.name}
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name}</div>
                )}
              </div>
            </div>
            <SelectDataSource label="Infrastructure de référence" {...register("forecastedType", { required: true })} />
            <CustomSourcesList label="Sources de prévision" {...register("forecastableModelEntries")} />

            {/*==================================
              ATTRIBUTE NAME
          ==================================*/}
            <SelectFeatureAttribute label="Attribut nommant l'infrastructure" {...register("attributeName", { required: false })} />

            {/*==================================
              SCENARIOS
          ==================================*/}
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="name">
                Scénario
              </label>
              <div className="col-sm-10">
                {alternativeFields.length ? (
                  alternativeFields.map((alternative, index) => (
                    <div
                      className="row"
                      key={`alternative-${alternative.uuid}`}
                      id={`alternative-${alternative.id}`}
                      uuid={`alternative-${alternative.uuid}`}
                    >
                      <div className="col-sm-10">
                        {
                          /* Hidden field for ID */
                          { forecastAlternatives: [], ...forecastableModel }
                            .forecastAlternatives[index] && (
                            <input
                              {...register(`forecastAlternatives[${index}][id]`)}
                              type="hidden"
                              defaultValue={alternative.id}
                            />
                          )
                        }
                        <input
                          type="input"
                          placeholder={
                            "Scenario minorant, Scenario majorant, Scenario moyen"
                          }
                          {...register(`forecastAlternatives[${index}][name]`, { required: true })}
                          className={`form-control ${formErrors.forecastAlternatives?.[index].name
                            ? "is-invalid"
                            : ""
                            }`}
                          defaultValue={alternative.name}
                        />
                        {errors[`forecastAlternatives[${index}].name`]?.length && (
                          <div className="invalid-feedback">
                            {errors[`forecastAlternatives[${index}].name`]}
                          </div>
                        )}
                      </div>
                      <div className="col-sm-1">
                        <button
                          className="btn btn-link"
                          onClick={() => removeAlternative(index)}
                          type="button"
                        >
                          <i className="fas fa-times text-danger"></i>
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <span className="col-sm-2 col-form-label">
                    Aucune prevision alternative.
                  </span>
                )}
              </div>
              <div className="col-12 p-4 text-right">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => {
                    appendAlternative({ name: "" });
                  }}
                >
                  Ajouter une prévision alternative
                </button>
              </div>
            </div>

            {/*==================================
                    TIME INTERVALS
          ==================================*/}
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="intervalType">
                Type de période
              </label>
              <div className="col-sm-10">
                <select
                  id="interval-type"
                  className={`form-control ${errors.intervalType ? "is-invalid" : ""
                    }`}
                  name="intervalType"
                  defaultValue={forecastableModel.intervalType}
                  {...register('intervalType', { required: true })}
                >
                  {TIME_INTERVALS.map((interv) => (
                    <option
                      value={interv.value}
                      key={`time-interval-${interv.value}`}
                    >
                      {interv.label}
                    </option>
                  ))}
                </select>
                {errors.intervalType && (
                  <div className="invalid-feedback">{errors.intervalType}</div>
                )}
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="name">
                Intervales de temps
              </label>
              <div className="col-sm-10">
                {intervalFields.map((item, index) => {
                  return (
                    <div className="row" key={`interval-${item.id}`}>
                      <div className="col-sm-3 interval-form">
                        <input
                          type="number"
                          step="1"
                          {...register(`timeIntervals[${index}].from`, { required: true })}
                          className={`form-control ${errors.timeIntervals &&
                            errors.timeIntervals[index] &&
                            errors.timeIntervals[index].from
                            ? "is-invalid"
                            : "nope"
                            }`}
                          defaultValue={
                            index == 0
                              ? intervalFields[index] &&
                              (getValues(`timeIntervals[${index}].from`) ||
                                intervalFields[index].from)
                              : undefined
                          }
                          value={
                            index > 0
                              ? intervalFields[index] &&
                              (getValues(`timeIntervals[${index}].from`) ||
                                intervalFields[index].from)
                              : undefined
                          }
                          readOnly={index > 0}
                        />
                        {errors.timeIntervals &&
                          errors.timeIntervals[index] &&
                          errors.timeIntervals[index].from && (
                            <div className="invalid-feedback">
                              {errors.timeIntervals[index].from}
                            </div>
                          )}
                      </div>
                      <div className="col-sm-1"> à </div>
                      <div className="col-sm-3">
                        <input
                          type="number"
                          step="1"
                          {...register(`timeIntervals[${index}].to`, { required: true })}
                          className={`form-control ${errors.timeIntervals &&
                            errors.timeIntervals[index] &&
                            errors.timeIntervals[index].to
                            ? "is-invalid"
                            : ""
                            }`}
                          name={`timeIntervals[${index}].to`}
                          defaultValue={
                            intervalFields[index] && intervalFields[index].to
                          }
                          onChange={(e) => {
                            if (intervalFields[index + 1]) {
                              setValue(
                                `timeIntervals[${index + 1}].from`,
                                e.currentTarget.value,
                                { shouldDirty: true }
                              );
                              setValue(
                                `timeIntervals[${index}].to`,
                                e.currentTarget.value,
                                { shouldDirty: true }
                              );
                            }
                          }}
                        />
                        {errors.timeIntervals &&
                          errors.timeIntervals[index] &&
                          errors.timeIntervals[index].to && (
                            <div className="invalid-feedback">
                              {errors.timeIntervals[index].to}
                            </div>
                          )}
                      </div>
                      <div
                        className="col-sm-1"
                        id={`color-cell-${index}`}
                        key={`interval-${item.id}`}
                        style={{ backgroundColor: intervalColors[index] }}
                      ></div>
                      <div className="col-sm-1">
                        <button
                          className="btn btn-link"
                          onClick={() => removeInterval(index)}
                          type="button"
                        >
                          <i className="fas fa-times text-danger"></i>
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="col-8 p-4 text-right">
                <button
                  className="btn btn-primary"
                  id="add-interval-button"
                  type="button"
                  onClick={() => {
                    appendInterval({
                      from: intervalFields[intervalFields.length - 1]
                        ? getValues(
                          `timeIntervals[${intervalFields.length - 1}].to`
                        )
                        : 0,
                      to: intervalFields[intervalFields.length - 1]
                        ? parseInt(
                          getValues(
                            `timeIntervals[${intervalFields.length - 1}].to`
                          ),
                          10
                        ) + 1
                        : 1,
                    });
                    updateIntervalFieldsColors();
                  }}
                >
                  Ajouter
                </button>
              </div>
            </div>
            <button type="submit" className="btn btn-success">
              {" "}
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    </React.StrictMode>
  );
};

export default ForecastableEntityForm;
