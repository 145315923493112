import axios from "axios";
import { Field, FieldArray, Form, Formik } from "formik";
import React, {useEffect, useState} from "react";
import { FieldWithError } from "../Form/Error";
import { Toast } from "../Toast";
import DOYInput from "./DOYInput";
import MediaTypeInput from "./MediaTypeInput";
import ContactSearch from "./ContactSearch";
import AssetSearch from "./AssetSearch";

export interface ActionPlanParams{
  id: number;
}
export interface Action {
  id: number | undefined;
  title: string;
  type: string | undefined;
  specifications: object;
}

export interface ActionPlan {
  id: number | undefined;
  name: string;
  comment: string;
  cost: number | undefined;
  hazardLimitation: number | undefined;
  reputationImpact: number | undefined;
  actions: Action[];
}

export interface ActionTemplate {
  templates: {[propName: string]: {label:string; fields:[string];}};
  fields: { [propName: string]: {label:string; type:string;};};
  sorted: [string];
}


const ActionPlan = ({id}: ActionPlanParams) => {
  const csrfToken = document.querySelector<any>("[name=csrf-token]").content;
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
  axios.defaults.headers.common["Key-Inflection"] = 'camel';

  const emptyActionPlan = ():ActionPlan => ({
    id: undefined,
    name: '',
    comment: '',
    cost: undefined,
    hazardLimitation: undefined,
    reputationImpact: undefined,
    actions: []
  });
  const [actionPlan, setActionPlan] = useState<ActionPlan>(emptyActionPlan);
  const [actionTemplates, setActionTemplates] = useState<ActionTemplate|undefined>();

  useEffect(() => {
    if(!id) return;
    axios
      .get<ActionPlan>(`/action_plans/${id}.json`)
      .then(response => {
        setActionPlan(response.data);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get<ActionTemplate>(`/action_plans/action_templates.json`)
      .then(response => {
        setActionTemplates(response.data);
      });
  }, []);

  
  const renderAction = (type, index, key, value) => {
    const label = <label className="form-label mb-0">{actionTemplates?.fields[key] ? actionTemplates.fields[key].label : key}</label>;
    const rowKey = `action-${index}-${key}`;
    const name = `actions.${index}.specifications.${key}`
    switch(type){
      case "doy":
        return <div className="form-group col-sm-6" key={rowKey}>{ label }
        <DOYInput name={name} value={value || undefined} /></div>;
      case "media":
        return <div className="form-group col-sm-12" key={rowKey}>{ label }
        <MediaTypeInput name={name} value={value || undefined} /></div>;
      case "contact":
        return <div className="form-group col-sm-12" key={rowKey}>{ label }
        <ContactSearch name={name} value={value || undefined} /></div>
      case "asset":
        return <div className="form-group col-sm-12" key={rowKey}>{ label }
        <AssetSearch name={name} value={value || undefined} /></div>
      case "textarea":
        return <div className="form-group col-sm-12" key={rowKey}>{ label } <Field
            name={name}
            as="textarea"
            className={`form-control`}
            value={value || undefined}
          /></div>;
      default: 
        return <div className="form-group col-sm-12" key={rowKey}>{ label } <Field
          name={name}
          as="input"
          type={type}
          className={`form-control`}
          value={value || undefined}
        /></div>;
    }
  }

  const sortAction = (a,b) => {
    if (!actionTemplates) return 0;
    return actionTemplates.sorted.indexOf(a) - actionTemplates.sorted.indexOf(b)
  }

  const save = async (values) => {
    const params = {...values}
    params.actions_attributes = params.actions.map((a,idx)=>{return {"id": a.id, "title": a.title, "position": idx, "specifications": {...a.specifications}}})
    delete params["actions"]
    let url = "/action_plans.json";
    let method = axios.post
    if(id){
      for(let action of actionPlan.actions)
        if(!params.actions_attributes.some(a => a.id === action.id))
          params.actions_attributes.push({id:action.id, _destroy:true})
      url = "/action_plans/"+id+".json";  
      method = axios.put
    }
    for(let action of params.actions_attributes)
      for(let key in action.specifications)
        if(action.specifications[key]===undefined)
          action.specifications[key] = null;

    return method(url, params)
      .then((res)=>Toast.success({ title: <p>Plan d'action enregistré</p> }) )
      .catch((err)=> Toast.error({ title: <p>Erreur</p>, text: err.data }))
  };
  
  return ( (!id || actionPlan.id) &&
    <div className="configuration action-plan">
      <Formik
        initialValues={actionPlan}
        onSubmit={save}
      >
      {({ values }) => (
        <Form>
          <div className="card">
            <div className="card-header">
              <h1>Plan d'actions</h1>
            </div>
            <div className="card-body">
              <h4 className="mb-2">Caractéristiques générales du plan</h4>
              <div className="form-group row">
                <label className="col-form-label col-sm-4 required">
                  Titre du plan
                </label>
                <div className="col-8">
                  <FieldWithError
                    name="name"
                    className={`form-control`}
                    placeholder="Un titre pour désigner ce plan"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-sm-4">
                  Commentaire
                </label>
                <div className="col-8">
                  <FieldWithError
                    name="comment"
                    as="textarea"
                    className={`form-control`}
                    placeholder="Plus de détails sur ce plan"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-sm-4">
                  Coût de mise en œuvre
                </label>
                <div className="col-8">
                  <FieldWithError
                    as="input"
                    type="number"
                    name="cost"
                    className={`form-control`}
                  />
                </div>
              </div>

              <h4 className="mb-2">Actions</h4>

              <FieldArray name="actions">
                {({ insert, remove, push, move }) => (
                  <div className="row actions" key="actions">
                    <div className="col-12">
                  {actionTemplates && values.actions.length > 0 &&
                  values.actions.map((action:Action, index)=>(
                    <div className="row action" key={`action-${index}`}>
                      <div className="col-sm-4">
                        <div className="action-index"><h3>{index+1}</h3></div>
                        <div className="action-reorder">
                          {index>0 ? <i className="fas fa-chevron-up" style={{cursor:"pointer"}} onClick={()=>move(index,index-1)}></i>:<i className="fas fa-chevron-up" style={{opacity:0}}></i>}
                          {index<values.actions.length-1 ? <i className="fas fa-chevron-down" style={{cursor:"pointer"}} onClick={()=>move(index,index+1)}></i>:<i className="fas fa-chevron-down" style={{opacity:0}}></i>}
                        </div>
                        <div className="action-title"><h5>{action.title}</h5></div>
                      </div>
                      <div className="col-sm-8">
                        <div className="clearfix" style={{height:10}}>
                        <button
                          type="button"
                          className="btn btn-sm btn-outline-danger btn-trash"
                          onClick={() => remove(index)}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                        </div>
                        <div className="action-details">
                          <div className="form-group col-sm-12">
                            <label className="form-label required mb-0">
                              Désignation
                            </label>
                            <div>
                              <Field
                                name={`actions.${index}.title`}
                                className={`form-control`}
                                placeholder="Un titre pour désigner cette action"
                              />
                            </div>
                          </div>
                          {
                            Object.keys(action.specifications).sort(sortAction).map((key)=>
                              {
                                const type = actionTemplates.fields[key] ? actionTemplates.fields[key].type : "text";
                                return renderAction(type, index, key, action.specifications[key]);
                              }
                            )
                          }
                          
                        </div>
                      </div>
                    </div>
                    ))}
                      <div className="row">
                        <div className="col-sm-8 offset-sm-4"><div className="col-sm-12">
                          <div className="btn-group dropup">
                            <button className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                            + Ajouter une action
                            </button>
                            <div className="dropdown-menu" >
                              { actionTemplates &&
                                Object.keys(actionTemplates.templates).map((key) => 
                                {
                                  const template = actionTemplates.templates[key];
                                  return <a className="dropdown-item" 
                                    onClick={() => push({ 
                                      title: key === "custom"?"":template.label, 
                                      specifications: Object.fromEntries(template.fields.map(field=>[field, undefined]))
                                    })}
                                    key = {`action-template-${key}`}
                                    >
                                      {template.label}
                                  </a>
                                })
                              }
                            </div>
                          </div>
                        </div></div>
                      </div>
                    </div>
                </div>
                )}
              </FieldArray>
              <div className="row justify-content-center align-items-center mt-3">
                <button type="submit" className="btn btn-info">Enregistrer le plan d'actions</button>
              </div>
            </div>
          </div>
        </Form>
      )}
      </Formik>
    </div>
  )
};
export default ActionPlan;