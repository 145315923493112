import React from 'react';

const selectField = (fieldName, field, options, data, handleform) => {
  let { title, required, validation } = field;
  let { register, errors } = handleform;
  const renderOptions = (options) => {
    return options.map((child) => {
      return (
        <option key={child} value={child.value}>{child}</option>
      );
    });
  };
  return (
    <div className="form-group row" key={fieldName}>
      <label htmlFor={fieldName} className="col-sm-2 col-form-label">{title}</label>
      <select
        required
        className="form-control"
        defaultValue={data}
        {...register(fieldName, { required: required, validate: validation })}
      >
        {renderOptions(options)}
      </select>
    </div>
  );
}

const textField = (fieldName, field, data, handleform) => {
  let { title, required, validation } = field;
  let { register, errors } = handleform;
  return (
    <div className="form-group row" key={fieldName}>
      <label htmlFor={fieldName} className="col-sm-2 col-form-label">
        {title}
        <span style={required ? { color: 'red' } : {}}>*</span>
      </label>
      <input
        className="form-control"
        type='text'
        defaultValue={data}
        {...register(fieldName, { required: required, validate: validation })}
      />
      {errors[fieldName] &&
        <p>
          {errors[fieldName].message}
        </p>}
    </div>
  );
}


const JsonSchemaField = ({ fieldName, jsonSchema, data, handleform }) => {
  const renderFields = (field) => {
    if (!field) {
      return <p className="text-danger">No field : {JSON.stringify({ fields })}</p>;
    }

    let { type, enum: enumOptions } = field;

    switch (type) {
      case 'select':
        let { options } = field;
        return selectField(fieldName, field, options, data, handleform);
      case 'string':
      case 'text':
        return enumOptions
          ? selectField(fieldName, field, enumOptions, data, handleform)
          : textField(fieldName, field, data, handleform);
      default:
        return (
          <div>
            <span>Invalid Field: {type}</span>
          </div>
        );
    }

  };
  return renderFields(jsonSchema);
}

const JSONForm = ({ baseFieldName, jsonSchema, data, handleform }) => {
  const { properties } = jsonSchema;
  if (!properties) {
    return JsonSchemaField({ fieldName: baseFieldName, jsonSchema, data, handleform })
  }
  else {
    return Object.keys(properties).map(name => {
      const fieldName = baseFieldName ? `${baseFieldName}.${name}` : name;
      const fieldData = data ? data[name] : null
      return JSONForm({ baseFieldName: fieldName, jsonSchema: properties[name], data: fieldData, handleform })
    });
  }
}
export default JSONForm;