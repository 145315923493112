import { CustomCellRendererProps } from '@ag-grid-community/react';
import React, {useState} from 'react';
import KeyrosModal from '../Modal';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { Toast } from '../Toast';

const DeleteButton = (props: CustomCellRendererProps) => {
    const [deleting, setDeleting] = useState(false);
  const removeRow = (e) => {
      let deletedRow = props.node.data;
      axios.delete(`assets/${deletedRow["id"]}.json`)
        .then(()=> props.api.applyTransaction({remove:[deletedRow]}) )
        .catch((res) => {
          const errors = res.response.data;
          Toast.error({
            title: res.response.statusText,
            html: Object.keys(errors).map((k)=>`<li><b>${k}</b> : ${errors[k][0]}</li>`).join("")
              
          });
      })
    };

    return (<>
      <span><button  className="btn btn-danger-outline icon-btn" onClick={() => setDeleting(true)}><i className="fas fa-trash"></i></button></span>
      <KeyrosModal
        title={`Supprimer ${props.node.data["assetType"]} ${props.node.data["identifier"]}`}
        show={deleting}
        children={
          <p>Êtes vous sûr de vouloir supprimer ce matériel ?</p>
        }
        footer={(
          <>
            <Button onClick={removeRow}> Oui </Button>
            <Button onClick={() => setDeleting(false)}>
              Annuler
            </Button>
          </>
        )}
      />
    </>);
};

export default DeleteButton;