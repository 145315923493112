import Color from 'color';
import React from 'react';

interface forecastTimeIntervalsProps {
  currentForecast: any,
  index?: number | null,
  delay?: number | string | null,
}

interface ForecastInterval {
  color: string,
  textColor: string,
  title: string,
  from: number,
  to: number,
}

const forecastTimeIntervals = ({ currentForecast, index = null, delay = null }: forecastTimeIntervalsProps): ForecastInterval => {
  let colors = [];
  if (currentForecast?.forecastableModel?.timeIntervals?.length) {
    const { timeIntervals } = currentForecast.forecastableModel
    timeIntervals.forEach((interval, index) => {
      const color = timeIntervals[index].color
      const textColor = Color(color).isLight() ? '#000' : '#FFF';
      colors.push({
        color,
        textColor,
        title: parseInt(interval.from, 10) === 0
          ? 'maintenant'
          : `d'ici ${interval.from}h`,
        ...interval,
        from: parseInt(interval.from, 10),
        to: parseInt(interval.to, 10),
      })
    })
  }

  if (index !== null) {
    return colors[index]
  }
  if (delay !== null) {
    return colors.find(c => parseInt(c.from, 10) === parseInt(delay as string, 10))
  }

  // If no index and delay is null,then feature is functionnal.
  return {
    color: "#00FA00",
    textColor: "#000",
    title: "/",
    from: 0,
    to: 0,
  }
}

const forecastTimePill = (interval: ForecastInterval): JSX.Element => (
  <span className="badge badge-pill" style={{ color: interval.textColor, backgroundColor: interval.color }}>{interval.title === '/' ? 'Fonctionnel' : interval.title}</span>
)


export { forecastTimeIntervals, forecastTimePill }
