import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios';
import { FeatureDependencyConfig } from '../../types/FeatureDependencyConfig';
import { ComparatorLabels, Requirement } from '../../types/Requirement';
import { useForm } from 'react-hook-form';
import { FeatureVariable } from '../../types/FeatureVariable';
import { Layer } from '../../types/Layer';
import { IConfiguratorStoreForecastableModel } from '../Configurator/ConfiguratorStoreType';
import { Toast } from '../Toast';

interface DependencyFormProps {
  featureDependencyId?: number;
  forecastableModel: IConfiguratorStoreForecastableModel;
  featureVariable: FeatureVariable;
  closeModal: () => void;
}

interface nestedFeatureDependencyConfig extends FeatureDependencyConfig {
  requirements: Requirement[];
}

interface TypedAttribute {
  [key: string]: string
}

const FieldError = ({ errors, name }) => {
  if (!errors[name]) return null;
  return (
    <div className="invalid-feedback">
      <ul>
        {errors[name].map((error, i) => <li key={i}>{error}</li>)}
      </ul>
    </div>
  )
}

const DependencyForm = ({ featureDependencyId, forecastableModel, featureVariable, closeModal }: DependencyFormProps) => {
  const [dependencyConfig, setDependencyConfig] = useState({} as nestedFeatureDependencyConfig)
  const [errors, setErrors] = useState<any>({})
  const [dependentLayerAttributes, setDependentLayerAttributes] = useState({});
  const [dependencyLayerAttributes, setDependencyLayerAttributes] = useState([]);
  const [importedLayers, setImportedLayers] = useState<Layer[]>([]);

  const { register, handleSubmit, control, reset, watch, } = useForm({
    defaultValues: dependencyConfig
  });

  const watchDynamicLayerId = watch('layerId', dependencyConfig.layerId);
  const watchedDependencyAttribute = watch('dependencyAttribute')

  useEffect(() => {
    if (featureDependencyId) {
      axios.get(`/feature_dependency_configs/${featureDependencyId}.json`)
        .then(({ data }) => setDependencyConfig(data))
        .catch(res => console.error(res));
    }
  }, [])

  //Get layers
  useEffect(() => {
    axios.get<Layer[]>(`/layers/imported.json`)
      .then(({ data }) => setImportedLayers(data))
      .catch(res => console.error(res));
  }, [])

  // Get dependency layer attributes
  useEffect(() => {
    axios
      .get(`/features/${forecastableModel.forecastedLayer.featureClassName}.json`)
      .then((res) => setDependencyLayerAttributes(res.data));

  }, []);

  // Get dependent
  useEffect(() => {
    if (watchDynamicLayerId){
      const featureClassName = layerfromLayerId(watchDynamicLayerId)?.featureClassName
      if(featureClassName){
        axios
          .get(`/features/${featureClassName}.json`)
          .then((res) => {
            setDependentLayerAttributes(res.data)
          });
        }
      }
  }, [watchDynamicLayerId, importedLayers]);

  const isAttributeComparable = useCallback((firstAttribute: TypedAttribute, secondAttribute: TypedAttribute) => {
    if (!Object.values(firstAttribute)?.some(v => v) || !Object.values(secondAttribute)?.some(v => v)) {
      console.warn('isAttributeComparable: missing attribute type', { firstAttribute, secondAttribute })
      return false;
    }
    return [
      ["string"],
      ["integer", "decimal", "float", "number"],
      ["boolean"]
    ].some(function (types) {
      if (types.indexOf(Object.values(firstAttribute)[0]) !== -1 &&
        types.indexOf(Object.values(secondAttribute)[0]) !== -1) {

        return true;
      }
    });
  }, [])


  const layerfromLayerId = (layerId: number) => {
    const layer = importedLayers.find(layer => layer.id == layerId);
    return layer;
  }

  // code pas beau mais j'ai pas eu le temps de faire mieux
  const save = (data) => {
    const [url, method] = featureDependencyId
      ? [`/feature_dependency_configs/${featureDependencyId}.json`, 'patch']
      : [`/feature_dependency_configs.json`, 'post'];

    axios[method](url, {
      feature_dependency_config: {

        requirements_attributes: data.requirements.map(r => (
          {
            id: r.id,
            comparator: r.comparator,
            feature_attribute: r.featureAttribute,
            dependency_variable_id: r.dependencyVariableId,
            depedency_variable_id: r.dependencyVariableId,
            dependency_type: 'ForecastableModel',
            dependency_id: forecastableModel.id,
          }))

        ,
        layer_id: data.layerId,
        dependency_id: data.dependencyId,
        feature_attribute: data.featureAttribute,
        dependency_attribute: data.dependencyAttribute,
      }
    })
      .then(({ data }) => { data; setErrors([]) })
      .then(() => Toast.success({ title: 'Enregistré' }))
      .then(closeModal)
      .then(() => window.location.reload())
      .catch((res) => {
        Toast.error({
          title: res.response.status === 422
            ? 'Données invalides'
            : res.response.statusText,
        });
        if (res.response.status === 422) {
          setErrors(res.response.data);
        }
      });
  }

  useEffect(() => {
    reset({...dependencyConfig, dependencyAttribute: watchedDependencyAttribute })
  }, [dependencyConfig, dependencyLayerAttributes, dependentLayerAttributes, dependencyLayerAttributes])

  const destroyFeatureDependencyConfig = () => {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette dépendance ?')) {
      axios.delete(`/feature_dependency_configs/${featureDependencyId}.json`)
        .then(() => Toast.success({ title: 'Supprimé' }))
        .then(closeModal)
        .catch((res) => {
          Toast.error({
            title: res.response.statusText,
          });
        });
    }
  }

  return (
    <div className="card">
      <div className="card-header">
        <h1>{featureDependencyId
          ? `Édition d'une dépendance`
          : 'Nouvelle dépendance'}
        </h1>
      </div>


      <div className="card-body">
        <form onSubmit={handleSubmit(save)}>

          {dependencyConfig.requirements && <input
            {...register('requirements.0.id')}
            type="hidden"
            defaultValue={dependencyConfig.requirements[0].id}
          />}

          <div className="row">
            <div className="col-sm-12">L'infrastructure:</div>
          </div>

          <div className="row">
            <div className="col-sm-5">
              <select
                {...register('layerId')}
                id="layerId"
                className={`form-control ${(errors.layerId) ? 'is-invalid' : ''}`}
                defaultValue=""
              >
                <option disabled aria-label="Vide" value="" key="feature-variable-empty" />
                {
                  importedLayers.filter(l => l.id != forecastableModel.forecastedTypeLayerId).map(layer => {
                    return <option value={layer.id} key={layer.id}>
                      {layer.name}
                    </option>
                  })
                }
              </select>
              <FieldError errors={errors} name="layerId" />
            </div>
            <div className="col-sm-2 text-center p-2">Dépend de</div>
            <div className="col-sm-5">

              <select
                id="forecastedLayer"
                className={`form-control ${(errors.layerId) ? 'is-invalid' : ''}`}
                defaultValue={forecastableModel.forecastedLayer.id}
                disabled={true}
              >
                <option disabled aria-label="Vide" value={forecastableModel.forecastedLayer.id}>
                  {forecastableModel.forecastedLayer.name}
                </option>
              </select>
            </div>
          </div>



          <div className="form-group row mt-3">
            <div className="col-sm-12">
              Si l'attribut
            </div>
            <div className="col-sm-5">
              <select
                {...register('featureAttribute')}
                id="featureAttribute"
                className={`form-control ${(errors["featureAttribute"]) ? 'is-invalid' : ''}`}
              >
                <option disabled aria-label="Vide" value="" key="featureAttribute-empty" />
                {

                  Object.keys(dependentLayerAttributes).map(attr => {
                    const attrType = dependentLayerAttributes[attr]
                    const validType = dependencyLayerAttributes[watchedDependencyAttribute]
                    const isValid = isAttributeComparable(
                      { [attr]: attrType },
                      { [watchedDependencyAttribute]: validType }
                    )
                    return <option value={attr} key={`feature-attribute-${attr}`}
                      disabled={!isValid}
                      title={!isValid ? `Comparaison impossible entre ${attrType} et ${validType}` : ''}
                    >
                      {attr}
                    </option>
                  })
                }
              </select>
              <FieldError errors={errors} name="featureAttribute" />
            </div>
            <div className="col-sm-2 text-center p-2">correspond à</div>
            <div className="col-sm-5">
              <select
                {...register('dependencyAttribute')}
                id="dependencyAttribute"
                className={`form-control ${(errors["dependencyAttribute"]) ? 'is-invalid' : ''}`}
              >
                <option disabled aria-label="Vide" value="" key="dependencyAttribute-empty" />
                {
                  Object.keys(dependencyLayerAttributes).map(attr => (
                    <option value={attr} key={`dependency-attribute-${attr}`}>
                      {attr}
                    </option>
                  ))
                }
              </select>
              <FieldError errors={errors} name="dependencyAttribute" />
            </div>
          </div>




          <div className="form-group row mt-3">
            <div className="col-sm-12">
              Et fonctionne si:
            </div>
            <div className="col-sm-5">
              <select
                {...register('requirements.0.featureAttribute')}
                id="featureAttribute"
                className={`form-control ${(errors["requirements[0].featureAttribute"]) ? 'is-invalid' : ''}`}
              >
                <option disabled aria-label="Vide" value="" key="featureAttribute-empty" />
                {
                  Object.keys(dependentLayerAttributes).map(attr => {
                    const attrType = dependentLayerAttributes[attr]
                    const validType = featureVariable.variableType
                    const isValid = isAttributeComparable(
                      { [attr]: attrType },
                      { [featureVariable.name]: validType }
                    )
                    return <option value={attr} key={`feature-attribute-${attr}`}
                      disabled={!isValid}
                      title={!isValid ? `Comparaison impossible entre ${attrType} et ${validType}` : ''}
                    >
                      {attr}
                    </option>
                  })
                }
              </select>
              <FieldError errors={errors} name="requirements[0].featureAttribute" />
            </div>
            <div className="col-sm-2">
              <select
                {...register('requirements.0.comparator')}
                id="requirement.comparator"
                className={`form-control ${(errors['requirements[0].comparator']) ? 'is-invalid' : ''}`}
                defaultValue=""
              >
                <option disabled aria-label="Vide" value="" key="comparatore-empty" />
                {
                  Object.keys(ComparatorLabels).map(key => {
                    return <option value={key} key={key}>
                      {ComparatorLabels[key]}
                    </option>
                  })
                }
              </select>
              <FieldError errors={errors} name="requirements[0].comparator" />
            </div>
            <div className="col-sm-5">
              <select
                id="dependencyAttribute"
                defaultValue={featureVariable.name}
                className={`form-control ${(errors["dependencyAttribute"]) ? 'is-invalid' : ''}`}
                disabled={true}
              >
                <option disabled value={featureVariable.name}>
                  {featureVariable.name}
                </option>
              </select>
            </div>
          </div>


          <input
            {...register('dependencyId')}
            type="hidden"
            defaultValue={forecastableModel.forecastedLayer.id}
          />

          <input
            {...register('requirements.0.dependencyVariableId')}
            type="hidden"
            defaultValue={featureVariable.id}
          />

          <div className="form-group row mt-5">
            <div className="col-sm-12">
              <button type="submit" className="btn btn-success pull-right">
                {" "}
                Enregistrer
              </button>

              {featureDependencyId
              ? <a href="#" className="btn btn-danger pull-right mr-3" onClick={destroyFeatureDependencyConfig}>
                {" "}
                Supprimer
              </a>
              : <a href="#" className="btn btn-danger pull-right mr-3" onClick={closeModal}>
                {" "}
                Annuler
              </a>
              }
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DependencyForm;