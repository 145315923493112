const pathTo = (path: string) => {
  const baseURI = window.location.origin
  if (path === '/') {
    return baseURI
  } else {
    if(path.startsWith('/')) {
      return `${baseURI}${path}`
    } else {
      return `${baseURI}/${path}`
    }
  }
}

export {
  pathTo,
}