import React, { useCallback, useMemo, useState } from "react";
import Event from "../../types/Event";
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import FeatureResultOverrideEventContent from "./Event/FeatureResultOverride";
import I18n from "../../packs/translations"
import FeatureResultOverrideEvent from "../../types/FeatureResultOverrideEvent";
import ForecastBulletinEventContent from "./Event/ForecastBulletin";
import ForecastBulletinEvent from "../../types/ForecastBulletinEvent";

const TimelineElementColor = (event: Event): string => {
  const alpha = new Date(event.occuredAt) > new Date() ? 0.4 : 1;
  return `rgb(39, 44, 51, ${alpha})`;
}

interface Props {
  event: Event
}

const TimeLineElement = ({ event }: Props) => {
  const [detailsVisible, setDetailsVisible] = useState<boolean>(false)

  const toggleVisibility = () => setDetailsVisible(!detailsVisible)

  const formattedValue = (value: any) => {
    switch (typeof (value)) {
      case 'string':
        const dateValue = Date.parse(value)
        if (dateValue) {
          return I18n.l("time.formats.long", dateValue)
        }
        return I18n.t(value, { defaultValue: value });
      default:
        return value ? value.toString() : 'N/A';
    }
  }

  switch (event.eventableType) {
    case 'FeatureResult':
      return <FeatureResultOverrideEventContent
        event={event as unknown as FeatureResultOverrideEvent}
        formattedValue={formattedValue} />
    case 'ForecastBulletin':
      return <ForecastBulletinEventContent
        event={event as unknown as ForecastBulletinEvent}
        formattedValue={formattedValue} />
    default:
      const bgcolor = TimelineElementColor(event)
      return (
        <VerticalTimelineElement
          className="timeline-element-component"
          contentStyle={{ background: bgcolor, backgroundOpacity: 0.5, boxShadow: `0 3px ${event.color}` }}
          contentArrowStyle={{ borderLeft: '7px solid', borderLeftColor: event.color }}
          date={<span className="pull-right">{formattedValue(event.occuredAt)}</span>}
          iconStyle={{ background: bgcolor, color: event.iconColor, padding: '12px', margin: '12px' }}
          icon={<i className={event.icon} />}
          key={event.title}
        >
          <h5 className="vertical-timeline-element-title" >
            {I18n.t(event.eventableType)}
            <small className="text-muted pull-right clickable" onClick={toggleVisibility}>
              <i className={`fas fa-${detailsVisible ? 'minus' : 'plus'}`} />
            </small>
          </h5>
          <h6 style={{ color: '#8b939b' }} className="vertical-timeline-element-subtitle">{I18n.t(event.action)}</h6>
          <p style={{ display: detailsVisible ? '' : 'none' }}>
            {Object.keys(event.details).map((attr, index) =>
              <span key={index}>
                {`${I18n.t(attr, { defaultValue: attr })} : ${formattedValue(event.details[attr])}`}
                <br />
              </span>
            )
            }
          </p>
        </VerticalTimelineElement>)
  }
}

export default TimeLineElement
export { TimelineElementColor }