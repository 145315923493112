import Axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';

const useCurrentUser = () => {
  function hasAccessTo(feature){
    return this.authorizedFeatures.includes(feature)
  }
  function hasRole(roleName){
    return !!this.roles.find(role => role.name === roleName)
  }
  const [ currentUser, setCurrentUser ] = useState({ authorizedFeatures: [], roles: [], maps: [], hasAccessTo, hasRole, isAdmin: false })
  useEffect(() => {
    Axios.get('/users/me.json')
    .then(({ data }) => {
      setCurrentUser({
        ...data,
        hasAccessTo,
        hasRole,
        isAdmin: !!data.roles.find(role => role.name === 'admin')
      })
    })
  }, [])

  return currentUser
}

export default useCurrentUser;