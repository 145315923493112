import React, {useState, useEffect, useRef} from 'react';
import $ from "jquery";
import "bootstrap-slider";
import axios from "axios";
export interface Stake {
  id: number;
  name: string;
  icon: string;
  geometryType: string;
  threats: object[];
}

export interface Scenario {
  id: number;
  name: string;
  height: number;
  risk: string;
}

const Threats = () => {
  const slider = useRef();
  const [stakes, setStakes] = useState<Stake[]>([]);
  const [threats, setThreats] = useState<object>({});
  const [scenarios, setScenarios] = useState<Scenario[]>([]);
  const [scenario, setScenario] = useState<Scenario>(0);
  const [tooltip, setTooltip] = useState<string>("");
  
  const organisationId = location.search.substring(5);
  // Initialisation du slider
  let timeOutSlide;
  useEffect(() => {
    if (slider.current) {
      $(slider.current)
        .slider()
        .on('change', (e) => {
          e.preventDefault();
          const target = $(e.currentTarget);
          setThreats({});
          clearTimeout(timeOutSlide);
          timeOutSlide = setTimeout(()=>{
            const sc = scenarios[target.slider('getValue')];
            threats[sc.id] = threats[sc.id] || {}
            setThreats({...threats})
            setScenario(sc)
          }, 0) 
        });
  }
  }, [slider, scenarios]);

  useEffect(() => {
    axios
      .get<Stake[]>(`/layers/stakes.json`+ (organisationId? "?oid="+organisationId:""))
      .then(response => {
        setStakes(response.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get<Stake[]>(`/layers/scenarios.json`+ (organisationId? "?oid="+organisationId:""))
      .then(response => {
        setScenarios(response.data);
        const sc = response.data[0];
        threats[sc.id] = threats[sc.id] || {}
        setThreats({...threats})
        setScenario(sc)
      });
  }, []);

  const calcImpact = (acc,cur)=> acc+(cur.h>50?cur.h:25)*parseFloat(cur.area)

  const loadDetails = (rows) => {
    rows.map(s => {
      if(!threats[scenario.id][s.id])
        axios.get(`layers/`+s.id+`/feature_threats.json?scenario_id=`+scenario.id)
          .then(response => {
            const results = response.data;
            threats[scenario.id][s.id] = results.sort((a,b)=>{
              const ai = a.impact.reduce(calcImpact,0)
              const bi = b.impact.reduce(calcImpact,0)
              return bi-ai;
            })
            setThreats({...threats});
          });
    })
  }

  useEffect(()=>{
    loadDetails(stakes); 
  }, [stakes, scenario])

  return (
    <div className="card">
      <div className="card-header">
        <h1>PICS <select><option>Crues</option></select></h1>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-1"></div>
          <div className="col-10">{scenarios.length &&
          <><center><h4>Scenario: {scenario.height?scenario.height+"m":scenario.risk}</h4></center><input
              id="scenario-slider"
              ref={slider}
              style={{ width: "100%", marginLeft: "50px" }}
              className="slider"
              data-layer-id="scenario"
              data-slider-min={0}
              data-slider-max={scenarios.length - 1}
              data-slider-step="1"
              data-slider-value={0} /></>
        }</div>
          <div className="col-1"></div>
        </div>

        <div className="row slider-legend">
          <div className="col-2">Risque fort</div>
          <div className="col-2"></div>
          <div className="col-1">modéré</div>
          <div className="col-2"></div>
          <div className="col-1">faible</div>
          <div className="col-2"></div>
          <div className="col-2">très faible</div>
        </div>
      
        <div className="accordion accordion-threats" id="accordion">
          {stakes.map(stake =>
            <div key={stake.id} className={"card" + (threats[scenario.id] && threats[scenario.id][stake.id] && threats[scenario.id][stake.id].length===0?" d-none":"")}>
              <div className="card-header" id={`header`+stake.id} >
                <h2 className="mb-0">
                  <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target={`#collapse`+stake.id} aria-expanded="true" aria-controls={`collapse`+stake.id} >
                    <span className="inline-icon" dangerouslySetInnerHTML={{ __html: stake.icon }} />
                    {stake.name} 
                    {threats[scenario.id]!==undefined && threats[scenario.id][stake.id] !== undefined ? 
                      ("- "+threats[scenario.id][stake.id].length +" impacts") : 
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>}
                  </button>
                </h2>
              </div>
              <div id={`collapse`+stake.id} className="collapse multi-collapse" aria-labelledby={`heading`+stake.id} data-parent="#accordion">
                <div className="card-body">
                {threats[scenario.id] && threats[scenario.id][stake.id] &&
                  threats[scenario.id][stake.id].map(threat=>
                    <div className="card threat">
                      {stake.geometryType == "fill-" &&
                      <><div className="card-header"><h6>{threat.toponyme || threat.nature}</h6></div><div className='card-body'>
                          {threat.impact &&
                            <div className="impacts">
                              <i className="fas fa-exclamation-circle"></i>
                              <span>Hauteur d'eau</span>
                              <ul>
                                {threat.impact.map(imp => <li>{imp.h}cm: {Math.round(imp.area * 100)}%</li>
                                )}
                              </ul></div>}
                        </div></>
                      }
                      {stake.geometryType == "fill" &&
                      <><div className="card-header"><h6>{threat.label} </h6></div><div className='card-body'>
                          {threat.impact &&
                            <div className="impacts">
                              <div className="impacts-gauge" onMouseEnter={()=>setTooltip(stake.id+'-'+threat.id)} onMouseLeave={()=>setTooltip("")}>
                                {threat.impact.map(imp => 
                                  <div className={`impact-gauge impact-gauge-`+imp.h} style={{width: Math.round(imp.area * 100)}}></div>
                                )} 
                                <div className="impacts-tooltip" style={{display: tooltip==stake.id+'-'+threat.id?'inline-block':'none'}}>
                                  <i className="fas fa-exclamation-circle"></i>
                                  <span>Hauteur d'eau</span>
                                  <ul>
                                    {threat.impact.map(imp => <li>{imp.h}cm: {Math.round(imp.area * 100)}%</li>
                                    )}
                                  </ul></div>
                              </div>
                             </div>}
                        </div></>
                      }
                      {stake.geometryType == "line" &&
                      <><div className="card-header"><h6>{threat.label}</h6></div><div className='card-body'>
                          {threat.impact &&
                            <div className="impacts">
                              <i className="fas fa-exclamation-circle"></i>
                              <span>Hauteur d'eau</span>
                              <ul>
                                {threat.impact.sort((a,b)=>(a.h-b.h)).map(imp => <li>{imp.h}cm: {imp.length>1000?(Math.round(imp.length/100)/10+' km'):(Math.round(imp.length)+' m')}</li>
                                )}
                              </ul></div>}
                        </div></>
                      }
                    </div>
                  )
                }
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Threats