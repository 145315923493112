import { CustomCellRendererProps } from '@ag-grid-community/react';
import React, {useEffect} from 'react';
import $ from 'jquery';

const LocationRenderer = (props: CustomCellRendererProps) => {
    useEffect(()=>{
        $('[data-toggle="tooltip"]').tooltip();
      },[props.value])
    return (
        !props.value || !props.value?.label? "":<div className="search-result sm" title={props.value.label}  data-toggle="tooltip" data-placement="right" data-delay="250">
        <img src={`/layers/${props.value.layerId || props.value.layer_id}/icon.svg`} /> 
        {props.value.label}
      </div>
    );
};

export default LocationRenderer;