import $ from 'jquery';
import GeoJSON from 'ol/format/GeoJSON.js';
import VectorSource from 'ol/source/Vector';
import {Vector as VectorLayer} from 'ol/layer.js';
import {Icon as IconStyle, Style as LayerStyle, Stroke as LayerStrokeStyle, Fill as LayerFillStyle} from 'ol/style'
const stylesCache = {}
let EXTRA_SOURCES = [];

var formatFeature = function(feature){
  return {}
}

var generateFeaturesTooltip = function(features){
  var html = []

  for(let i=0; i<features.length; i++){
    const attributes = formatFeature(features[i]);
    if(!attributes){ continue; }
    html.push('<div class="card bg-dark text-white">')
    html.push('<div class="card-header">')
    html.push(attributes.layerName)
    html.push('</div>')
    html.push('<div class="card-body table-responsive" data-simplebar>')
    html.push('<table class="table table-sm table-striped table-dark">')
    html.push('<tbody>')
    for(var k in attributes){
      if(['geometry', 'layerName'].indexOf(k) >= 0){ continue; }
      html.push("<tr><th>" + k + "</th><td>" + attributes[k] + "</td></tr>")
    }
    html.push('</tbody>')
    html.push('</table>')
    html.push('</div>')
    html.push('</div>')
  }
  return html.join("\n");
}

var tooltipKeepedOpen = false;

function getFeaturesAtPixel(pixel,coordinate){
  let features = [];
  map.forEachFeatureAtPixel(pixel, function(feature, layer) {
    features.push(feature)
  });

  return features;
}

function showTooltype(evt, isClick){
  // Toltip déjà ouverte ?
  if(!isClick && tooltipKeepedOpen){ return; }
  tooltipKeepedOpen = false;
  growGeometry(evt);

  const pixel = evt.pixel;
  const coordinate = evt.coordinate
  queryFeatureAtPixel(pixel, coordinate, function(WMSFeatures){
    let features = getFeaturesAtPixel(pixel, coordinate)
    for(let i=0; i<WMSFeatures.length; i++){
      features.push(WMSFeatures[i])
    }
    const tooltip = $('#tooltip');
    unHighlightFeatures();
    if (features.length > 0) {
      const htmlData = generateFeaturesTooltip(features);
      if(!htmlData){
        tooltip.hide();
        tooltipKeepedOpen = false;
        return false;
      }

      highlightFeatures(features)
  //    tooltip.style.display = '';
      if(tooltip.hasClass('active')){
        tooltip.hide();
        tooltip.fadeIn();
      }
      else {
        tooltip.addClass('active').show().css({
                    right: -(tooltip.width())
                }).animate({
                    right: 0
                }, 500);
      }
      tooltip.find('.simplebar-content').html(htmlData);
      tooltipKeepedOpen = isClick
    } else {
      tooltip.hide();
      tooltipKeepedOpen = false;
    }
  });
}

function unHighlightFeatures(){
  map.getLayers().forEach(layer => {
    if(layer.get('name') === 'HighlightedFeatures'){
      map.removeLayer(layer);
    }
  })
}

function highlightFeatures(features){


  var styleFunction = function(feature) {
        var styles = {};
        let width = (feature.get('largeur') || 4) * 2
        styles['Polygon'] = new LayerStyle({
          stroke: new LayerStrokeStyle({
            color: '#b3ffb3',
            width: 3
          }),
          fill: new LayerFillStyle ({
            color: 'rgba(0, 0, 255, 0.1)'
          })
        });
        styles['MultiLineString'] = new LayerStyle ({
          stroke: new LayerStrokeStyle ({
            color: '#b3ffb3',
            width: width / map.getView().getResolution()
          })
        });
        styles['MultiPolygon'] = new LayerStyle ({
          stroke: new LayerStrokeStyle ({
            color: '#b3ffb3',
            width: width / map.getView().getResolution()
          }),
          fill: new LayerFillStyle ({
            color: 'rgba(255, 255, 0, 0.1)'
          })
        });
        styles['default'] = new LayerStyle ({
          stroke: new LayerStrokeStyle ({
            color: '#b3ffb3',
            width: 3
          }),
          fill: new LayerFillStyle ({
            color: 'rgba(255, 0, 0, 0.1)'
          }),
        });

        if(feature.get('layerName') === 'Metros'){
          return new LayerStyle({
            image: new IconStyle(/** @type {olx.style.IconOptions} */ ({
              scale: map.getView().getZoom() > 18
                      ? map.getView().getZoom() / 10
                      : map.getView().getZoom() < 15
                        ? map.getView().getZoom() / 50
                        : map.getView().getZoom() / 30,
              src: '/images/metro_highlighted.png',
            })),
          })
        }

        return styles[feature.getGeometry().getType()] || styles['default'];
      };


  unHighlightFeatures();
  const geojsonObject = {
    'type': 'FeatureCollection',
    'crs': {
      'type': 'name',
      'properties': {
        'name': 'EPSG:3857'
      }
    },
    'features': []
  }
  for(let i=0; i<features.length; i++){
    geojsonObject.features.push(features[i])
  }

  var source = new VectorSource({
    features: (new GeoJSON()).readFeatures(geojsonObject)
  });

  var layer = new VectorLayer({
    source: source,
    style: styleFunction,
    zIndex: 999,
    name: 'HighlightedFeatures'
  });

  map.addLayer(layer);
}



function displayTooltip(evt) {
  showTooltype(evt, true);
};

function toggleTooltip(evt) {
  showTooltype(evt, false);
};


var grownGeometries = {};

var growGeometry = function(evt){

  if (evt.dragging) {
    return;
  }
  var pixel = map.getEventPixel(evt.originalEvent);
  var hit = map.forEachLayerAtPixel(pixel, function(layer) {
    return true;
  }, {layerFilter: function layerFilter(layerCandidate){
    if(layerCandidate.layerType != 'Background' && layerCandidate.get('name') === 'Metros'){
      return true;
    }
  }}
);
  map.getTargetElement().style.cursor = hit ? 'pointer' : '';

  if(tooltipKeepedOpen){ return; }

  // Find feature
  var pixel = evt.pixel;
  var feature = map.forEachFeatureAtPixel(pixel, function(feature) {
    return feature;
  });

  // Suppression du style des autres features
  for(var id in grownGeometries){
    if(feature && id === feature.get("id")){ continue; }
    grownGeometries[id].setStyle(stylesCache[grownGeometries[id].get('cacheKey')])
    delete grownGeometries[id];
  }



  if (feature && !grownGeometries[feature.get("id")]) {
    const hoverColor = "green";
    let s;
    switch(feature.getGeometry().getType()){
      case 'MultiPolygon':
        s = new LayerStyle({
              stroke: new LayerStrokeStyle({
                color: '#000',
                lineDash: [5],
                width: 2
              }),
              fill: new LayerFillStyle({
                color: hoverColor,
              })
            })
        break;
      case 'MultiLineString':
        s = new LayerStyle({
              stroke: new LayerStrokeStyle({
              color: hoverColor,
              width: map.getView().getZoom() - 10
            })
          })
        break;
      case 'Point':
        s = new LayerStyle({
          image: new IconStyle(/** @type {olx.style.IconOptions} */ ({
            anchor: [0.5, 46],
            anchorXUnits: 'fraction',
            anchorYUnits: 'pixels',
            opacity: 1,
            scale: map.getView().getZoom() * 0.005,
            src: '/metro.png',
            color: hoverColor
          }))
        })
        break;
    }
    feature.setStyle(s);
    grownGeometries[feature.get("id")] = feature;
  }
}



const getLayerByName = function(layerName){
  let layer;
  const layers = map.getLayers().getArray();
  for(let i=0; i<layers.length; i++){
    layer = layers[i];
    if(layer.get('name') === layerName){
      return layer
    }
  }
}

function hasLayerExtraSource(layer){
  let hasExtraSource = false;
  EXTRA_SOURCES.forEach(source => {
    const dependentLayer = source.get('dependentLayer')
    if(dependentLayer === layer.get('name')){
      hasExtraSource = true
    }
  })
  return hasExtraSource
}

function queryFeatureAtPixel(pixel, coordinate, callback) {
    var view = map.getView();
    var viewResolution = view.getResolution();
    const featureInfoRequests = []
    const featuresInfo = []


    var hit = map.forEachLayerAtPixel(pixel, function(layer,rgba) {
      if(!hasLayerExtraSource(layer)){
        var source = layer.getSource();
        if(!source.getGetFeatureInfoUrl){ return false; }
        var url = source.getGetFeatureInfoUrl(
          coordinate, viewResolution, view.getProjection(),
          {'INFO_FORMAT': 'application/json'}
        );
        if (url) {
          featureInfoRequests.push(
            $.get(url, function(data){
              data.features.forEach(entry => { entry.properties.layerName = layer.get('name')})
              data.features.forEach(function(feature){
                featuresInfo.push(feature);
              })
            })
          )
        }
      }
    }, {layerFilter: function layerFilter(layerCandidate){
      return layerCandidate.layerType != 'Background'
    }});
    /* EXTRA DATA */
    EXTRA_SOURCES.forEach(source => {
      const dependentLayer = source.get('dependentLayer')
      if(dependentLayer){
        const layer = getLayerByName(dependentLayer)
        if(layer.get('visible')){
          var url = source.getGetFeatureInfoUrl(
            coordinate, viewResolution, view.getProjection(),
            {'INFO_FORMAT': 'application/json'}
          );
          if (url) {
            featureInfoRequests.push(
              $.get(url, function(data){
                data.features.forEach(function(feature){
                  featuresInfo.push(feature);
                })
              })
            )
          }
        }
      }
    })


    $.when.apply(undefined, featureInfoRequests)
    .then(function(){
      return callback(featuresInfo)
    })
}




var TooltipOverlay = function(map){
  //map.addOverlay(overlay);
  //map.on('pointermove', growGeometry);
  map.on('click', displayTooltip);
}

export {TooltipOverlay}
