/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
//= require bootstrap.bundle.min
import ReactOnRails from 'react-on-rails';
import '@hotwired/turbo-rails';
import Swal from 'sweetalert2';

import $ from 'jquery';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import axios from 'axios';
import { createConsumer } from '@rails/actioncable';

import NavigationBar from '../components/NavigationBar';
import Directory from '../components/Directory';
import Inventory from '../components/Inventory';
import LayersForm from '../components/Layers/Form';
import MapContainer from '../components/MapContainer';
import LayersAdvancedEdit from '../components/Layers/AdvancedEdit';
import FeaturePublicDisplays from '../components/FeaturePublicDisplays/Index';
import ForecastableModelConfigurator from '../components/Configurator/Index';
import ForecastableEntityForm from '../components/ForecastableModel/Form';
import Threats from '../components/Threats'
import MapForm from '../components/Maps/Form';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import I18n from './translations';
import ActionPlan from '../components/ActionPlan';
// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  FeaturePublicDisplays,
  ForecastableModelConfigurator,
  ForecastableEntityForm,
  LayersAdvancedEdit,
  LayersForm, 
  MapContainer,
  MapForm,
  NavigationBar,
  Directory,
  Inventory,
  Threats,
  ActionPlan
});

window.jQuery = $;
window.$ = $;
window.I18n = I18n;
const eventConsumer = createConsumer();
const notifyInfo = (message) => {
  Swal.fire({
    title: 'Info',
    text: message,
    icon: 'info',
    timer: 5000,
    toast: true,
    showConfirmButton: false,
    position: 'top-end',
  });
};
window.Keyros = {
  eventConsumer,
};

// Format nested params correctly
axios.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => jQuery.param(params);

  return config;
});

$(() => {
  const autoHiddenAlerts = $('.alert:not(.alert-keep)');
  autoHiddenAlerts.hide();
  autoHiddenAlerts.fadeTo(3000, 500).slideUp(500, () => {
    autoHiddenAlerts.slideUp(500);
  });
});

// See https://www.shakacode.com/react-on-rails/docs/rails/Turbo/
ReactOnRails.setOptions({
  turbo: true,
});

window.Keyros.observeLayerChannel = () => {
  console.log('Listening to LayerChannel');
  const channel = 'LayerChannel';
  window.Keyros.eventConsumer.subscriptions.create(channel, {
    received(data) {
      console.log({
        data,
      });
      switch (data.status) {
        case 'prepare':
          notifyInfo('Preparing layer');
          break;
        case 'importing':
          notifyInfo('Importing layer');
          break;
        case 'publishing':
          notifyInfo('Publishing layer');
          break;
        case 'complete':
          notifyInfo('Layer complete');
          window.location.reload();
          break;
        default:
          alert('default');
          break;
      }
    },
  });
};

Keyros.observeResultProcessingChannel = () => {
  console.log('Listening to ResultProcessingChannel');

  window.Keyros.eventConsumer.subscriptions.create(
    { channel: 'ResultProcessingChannel' },
    {
      received(data) {
        const percent_done = `${((data.current_line / data.total_lines) * 100).toFixed(2)}%`;

        switch (data.status) {
          case 'pending':
            $(`#result_registering_status_${data.result_registering_id}`).html(
              '<span class="text-warning"><i title="Registering queued" class="fas fa-clock-o"></i> En attente</span>',
            );
            break;
          case 'completed':
            $(`#result_registering_status_${data.result_registering_id}`).html(
              '<span class="text-success"><i title="Registering completed" class="fas fa-check"></i> Terminé</span>',
            );
            Swal.fire({
              title: '<p>Terminé</p>',
              text: 'Le chargement des résultats est terminé',
              icon: 'success',
              timer: 1500,
              toast: true,
              showConfirmButton: false,
              position: 'top-end',
            }).then(() => {
              Turbo.visit(location.toString());
            });

            break;
          case 'completed_with_errors':
            $(`#result_registering_status_${data.result_registering_id}`).html(
              '<span class="text-warning"><i title="Registering completed with errors" class="fas fa-exclamation-triangle"></i> Terminé</span>',
            );
            Swal.fire({
              title: '<p>Terminé</p>',
              text: 'Le chargement des résultats est terminé avec des erreurs',
              icon: 'warning',
              timer: 1500,
              toast: true,
              showConfirmButton: false,
              position: 'top-end',
            }).then(() => {
              Turbo.visit(location.toString());
            });
            break;
          case 'failed':
            $(`#result_registering_status_${data.result_registering_id}`).html(
              '<span class="text-danger"><i title="Registering failed" class="fas fa-times"></i> Echoué</span>',
            );
            Swal.fire({
              title: '<p>Échoué</p>',
              text: 'Le chargement des résultats a échoué',
              icon: 'error',
              timer: 1500,
              toast: true,
              showConfirmButton: false,
              position: 'top-end',
            }).then(() => {
              Turbo.visit(location.toString());
            });
            break;

          case 'processing':
            $(`#result_registering_status_${data.result_registering_id}`).html(
              `<span class="text-info"><i title="Registering processing" class="fas fa-spinner fa-pulse fa-cog"></i> En cours </span> <span class="text-muted">(${
                percent_done
              })</span>`,
            );
            break;
          default:
            Swal.fire({
              title: '<p>Erreur</p>',
              text: "Le statut de l'enregistrement des résultats n'est pas reconnu",
              icon: 'error',
              timer: 1500,
              toast: true,
              showConfirmButton: false,
              position: 'top-end',
            });
        }
      },
    },
  );
};
