import React, { FC } from 'react';
import axios from "axios";
import Configurator from './Configurator';


const ConfiguratorWrapper: FC = () => {
  const csrfToken = document.querySelector<any>("[name=csrf-token]").content;
  axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
  axios.defaults.headers.common["Key-Inflection"] = 'camel';
  axios.defaults.headers.common["X-Key-Inflection"] = 'camel';

  return (
        <Configurator />
  );
}

export default ConfiguratorWrapper;
