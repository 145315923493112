import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Toast } from "../Toast";

interface AdvancedEditProps {
  layerId: number;
}

const AdvancedEdit = ({ layerId }: AdvancedEditProps) => {
  const [layer, setLayer] = useState(null);
  const [errors, setErrors] = useState<any>({})
  const [distinctGroups, setDistinctGroups] = useState<any>([])


  const { register, handleSubmit, control, reset, setValue, getValues, watch, formState: { errors: formErrors, ...otherFormState } } =
    useForm({
      defaultValues: {
        groupOptions: layer?.groupOptions || [],
      }
    });

  React.useEffect(() => {
    fetch(`/layers/${layerId}.json`)
      .then((response) => response.json())
      .then((data) => {
        setLayer(data)
        reset({
          groupOptions: data.groupOptions || [],
        })
      });
  }, []);

  const save = ({ groupOptions }) => {
    fetch(`/layers/${layerId}.json`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        // "X-CSRF-TOKEN": global.Rails.csrfToken(),
      },
      body: JSON.stringify({
        layer: {
          group_options: groupOptions,
        }
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
            .then((data) => {
              setLayer(data)
              setErrors({})
              Toast.success({ title: 'Mise à jour réussie', text: 'Les modifications ont été enregistrées' })
            })
        }
        else {
          switch (response.status) {
            case 422:
              return response.json()
                .then((data) => {
                  setErrors(data)
                  Toast.error({ title: 'Erreur', text: 'Veuillez corriger les erreurs' })
                })
            default:
              return response.json()
                .then((data) => {
                  Toast.error({ title: 'Erreur', text: 'Une erreur est survenue' })
                }
                )

          }
        }
      })
  };

  const getGroupedFeatures = () => {
    const groupOptions = getValues('groupOptions')
    if (!groupOptions.length) {
      setDistinctGroups([])
    }
    else {
      const groupOptionsParams = new URLSearchParams()
      groupOptions.forEach((groupOption) => {
        groupOptionsParams.append(`groupOptions[]`, groupOption)
      })
      fetch(`/layers/${layerId}/feature_groups.json?` + groupOptionsParams)
        .then((response) => response.json())
        .then((data) => {
          setDistinctGroups(data)
        }
        )
    }
  }


  const distinctGroupsTable = useMemo(() => {
    if (distinctGroups.length === 0) { return null }

    const groupOptions = getValues('groupOptions')
    const groupOptionsLength = groupOptions.filter(s => s).length;
    return <div className="col-sm-12">
      <table className="table table-sm table-bordered">
        <thead>
          <tr>
            <th>{groupOptions[0]}</th>
            {groupOptionsLength === 2 && <th>{groupOptions[1]}</th>}
            <th>Nombre d'éléments (total: {distinctGroups.reduce((acc, group) => acc + group[groupOptionsLength], 0)})</th>
          </tr>
        </thead>
        <tbody>
          {distinctGroups.map((distinctGroup, index) => (
            <tr key={`distinct-group-${index}`}>
              <td>{distinctGroup[0]}</td>
              {groupOptionsLength === 2 && <td>{distinctGroup[1]}</td>}
              <td>{groupOptionsLength === 2 ? distinctGroup[2] : distinctGroup[1]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  }, [distinctGroups])

  if (!layer) {
    return <div className="text-center">
      <h1><i className="fas fa-spinner fa-spin" /></h1>
    </div>;
  }
  const featureAttributeNames = layer.featureAttributeNames || [];

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            Général
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(save)}>



              <div className="form-group row">
                <div className="col-sm-3">
                  <label className="form-label" htmlFor="group_options">
                    Regroupement de données
                  </label>
                  <p className="form-text text-muted">
                    <i className="fas fa-info-circle mr-2" />
                    Un regroupement de données permet de filtrer des objets sur la carte selon des attributs.
                  </p>
                </div>
                <div className="col-sm-9">
                  {[0, 1].map(groupOptionIndex => (
                    <div className="row" key={`group-option-${groupOptionIndex}`}>
                      <div className="col-sm-12">
                        <select
                          className={`form-control ${errors['groupOptions'] && errors['groupOptions'][0] ? 'is-invalid' : ''}`}
                          id={"groupOptions"}
                          {...register(`groupOptions.${groupOptionIndex}`)}
                        >
                          <option value="">Choisir un type de couche</option>
                          {featureAttributeNames.map((attribute: string) => (
                            <option key={attribute} value={attribute}>
                              {attribute}
                            </option>
                          ))}
                        </select>
                        <div className="invalid-feedback">
                          <ul>
                            {errors['groupOptions'] && errors['groupOptions'][groupOptionIndex] && <li key='groupOptions.0-error'>{errors['groupOptions'][groupOptionIndex]}</li>}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}



                  <div className="row">
                    <div className="col-sm-12">
                      <button type="button" className="btn btn-primary pull-right" onClick={getGroupedFeatures}>
                        Visualiser
                      </button>
                    </div>
                    {distinctGroupsTable}
                  </div>

                </div>
              </div>

              <div className="row mt-3">
                <div className="col-sm-12 text-right">
                  <button type="submit" className="btn btn-success pull-right">
                    Enregistrer
                  </button>

                </div>
              </div>

            </form>

          </div>
        </div>
      </div>

    </div>

  );
};

export default AdvancedEdit;