export const toMapBoxCase = camelCase => {
  if (camelCase) {
      const result = camelCase.replace(/([A-Z])/g, '-$1');
      return result.toLowerCase();
  }
  return '';
};

export const toSnakeCase = camelCase => {
  if (camelCase) {
      const result = camelCase.replace(/([A-Z])/g, '_$1');
      return result.toLowerCase();
  }
  return '';
};


export const camelize= str => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  }).replaceAll('-','');
}

export const mapBoxizeKeys = l => {
  if(!l || typeof l !== "object")
    return
  for (let k of Object.keys(l)) {
    let _k = toMapBoxCase(k);
    if (k !== _k) {
      l[toMapBoxCase(k)] = l[k];
      delete l[k];
    }
  }
}


export const snakeCasizeKeys = l => {
  if(!l || typeof l !== "object")
    return
  for (let k of Object.keys(l)) {
    let _k = toSnakeCase(k);
    if (k !== _k) {
      l[toSnakeCase(k)] = l[k];
      delete l[k];
    }
  }
}

export const flatten = l => {
  if(!l || typeof l !== "object")
    return
  for (let k of Object.keys(l)) {
    if(typeof l[k] == "object")
    { 
      for(let ck of Object.keys(l[k]))
        l[k+"_"+ck] = l[k][ck];
      delete l[k];
    }
  }
}

export function debounce(fn, delay = 250) {
  let timeout;

  return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
          fn(...args);
      }, delay);
  };
}