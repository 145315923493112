import React, { useContext } from "react";
import { ForecastContext } from "../Forecasts";

interface ForecastSourceSelectorProps {
  selected: ForecastValue
  currentForecastValues: ForecastValue[],
  disabledForecastValues: ForecastValue[],
  selectorIndex: number,
  setVisibleForecastValueAtIndex: (value: string, index: number) => void,
  removeDisplayableForecastValue: (index: number) => void,
  addDisplayableForecastValue: () => void
}

const MAX_DISPLAYED_FORECAST_VALUES = 4

const ForecastSourceSelector = ({
  selected,
  selectorIndex,
  currentForecastValues,
  disabledForecastValues,
  setVisibleForecastValueAtIndex,
  removeDisplayableForecastValue,
  addDisplayableForecastValue,
}: ForecastSourceSelectorProps) => {
  const { currentForecast } = useContext(ForecastContext);
  return <span key={`displayableForecastValues-forecast-${currentForecast.id}-feature-${selected.featureId}`}>
    <select value={`${selected.featureType}/${selected.featureId}`} className="select-component"
      onChange={e => {
        setVisibleForecastValueAtIndex(e.target.value, selectorIndex)
      }}>
      <option key="0" value="">Selectionner une station</option>
      {
        currentForecastValues?.map(forecastValue => (
          <option
            key={`forecast-${currentForecast.id}-feature-${forecastValue.featureType}/${forecastValue.featureId}`}
            value={`${forecastValue.featureType}/${forecastValue.featureId}`}
            disabled={selected.featureId !== forecastValue.featureId &&
              disabledForecastValues.some(v => v.featureId === forecastValue.featureId && v.featureType === forecastValue.featureType)}
          >{
              forecastValue.featureName
            }</option>
        ))
      }
    </select>
    :
    <span className="key-info" style={{ width: '5em', display: 'inline-block' }}>
      {selected.value ? ` ${selected.value} m ` : ' Inconnue'}
    </span>
    {
            disabledForecastValues.length > 1 &&
            <a href="#" onClick={e => {
              e.preventDefault()
              removeDisplayableForecastValue(selectorIndex)
            }}>
              <i className="fas fa-minus mx-1 text-white" title="Supprimer une ligne"></i>
            </a>
          }

          {
            selectorIndex === (disabledForecastValues.length - 1) && selectorIndex < (MAX_DISPLAYED_FORECAST_VALUES - 1) &&
            <a href="#" onClick={e => {
              e.preventDefault()
              addDisplayableForecastValue()
            }}>
              <i className="fas fa-plus mx-1 text-white" title="Ajouter une ligne"></i>
            </a>
          }
  </span>
}

export default ForecastSourceSelector