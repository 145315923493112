import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import axios from "axios";
import { Comparator, ComparatorLabels, Requirement } from "../../types/Requirement";
import { IConfiguratorStoreForecastableModel, IConfiguratorStoreRequirement } from "./ConfiguratorStoreType";
import { FeatureVariable } from "../../types/FeatureVariable";
import DependencyForm from "../FeatureDependencyConfig/Form";



interface Props {
  requirement: IConfiguratorStoreRequirement;
  forecastableModel: IConfiguratorStoreForecastableModel
}

const RequirementFrame = (props: Props) => {
  const [showAddDependencyMenu, setShowAddDependencyMenu] = useState<boolean>(false);

  if (!props.forecastableModel.layerFeatureEvaluators) {
    return <Spinner animation="border" />
  }

  const getVariable = () => {

    const variable = props.forecastableModel.layerFeatureEvaluators?.find(lfe => lfe.featureVariable?.id === props.requirement.dependencyVariableId)?.featureVariable
    if (variable) {
      return variable;
    } else {
      throw new Error("Variable not found")
    }
  }

  const requirementPopOver = (
    <Popover id="forecastableModelPopOver">
      <Popover.Title as="h3">Condition</Popover.Title>
      <Popover.Content>
        <p>
          Pour qu'un(e) <b>{props.requirement.featureDependency.dependent.name}</b> fonctionne, <br />
          son attribut&nbsp; <b>{props.requirement.featureAttribute}</b> <br />
          doit être <b>{ComparatorLabels[props.requirement.comparator]}</b> <br />
          à <b>{getVariable().name}</b> en station.
        </p>
        <p>
          Cliquer pour consulter ou modifier la condition.
        </p>
      </Popover.Content>
    </Popover>
  )


  return (
    <>
      <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={requirementPopOver}>
        <Button className="rounded-circle btn-danger" onClick={_ => setShowAddDependencyMenu(true)}>
          {props.requirement.comparator}
        </Button>
      </OverlayTrigger>
      <Modal show={showAddDependencyMenu} onHide={() => setShowAddDependencyMenu(false)} size={"xl"} >
        <DependencyForm closeModal={() => setShowAddDependencyMenu(false) } featureVariable={getVariable()} forecastableModel={props.forecastableModel} featureDependencyId={props.requirement.featureDependencyConfigId} />
      </Modal>
    </>

  )
}

export default RequirementFrame;