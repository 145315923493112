import React from 'react';
import { useFormikContext, Field, FieldArray } from 'formik';

const FieldErrors = ({ name, errorName }) => {
  // On récupère à nouveau les erreurs Formik dans son context
  const { errors } = useFormikContext();
  return (
    <div className="invalid-feedback">
      {/* Puis on gère l'affichage */}
      <ul>
        {errors[errorName || name] && errors[errorName || name].map(err => <li key={`${name}-${err}`}>{err}</li>)}
      </ul>
    </div>
  )
}

const FieldWithError = ({name, errorName=undefined, className, children=undefined, ...fieldProps}) => {
  const { errors } = useFormikContext(); // Récupération des erreurs de Formik, via son Context 
  // Les contexts permettent de partager des states entre composants 
  // Sans les faire passer par 10000 props

  // Est-ce qu'on trouve l'erreur Formik avec la props errorName ou, si elle n'est pas définie,
  // avec la props 'name' ? ->name non? Je ne comprends pas
  // nan mais laisse je reflechissais ok 
  const isInvalid = !!(errors && errors[errorName || name])
  return (
    <>
      {/* On créé un Field Formik standard en lui passant les props:
          - name
          - className
              -> on ajoute 'is-invalid' aux classes CSS quand le champ est invalide
              -> Ça a pour effet de 
                1. l'entourer de rouge grace à bootstrap
                2. Afficher le composant FieldErrors
          - Les éventuelles autres props (...fieldProps)
       */ }
      <Field name={name} {...fieldProps} className={`${className}${isInvalid ? ' is-invalid' : ''}`}>
        {children}
      </Field>
      {/* Dans tous les cas on peut inclure le Composant FieldErrors qui affiche
      le texte d'Erreur, car Bootstrap ne l'Affiche que si le Field au dessus a la class 'is-invalid' */}
      <FieldErrors name={name} errorName={errorName}/>
    </>
  )
}

export {
  FieldWithError,
  FieldErrors,
}