import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';
import { debounce } from "../Map/Utils";
import { CustomCellEditorProps } from "ag-grid-react";


export interface Location {
  id:number;
  label:string;
  type:string;
  center:string;
  bounding_box:string;
}


const customStyles = {
    control: (provided) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      width: '600px',
      height: '20px',
      border: '2px solid rgb(39, 44, 51, 1)',
      margin: '0px',
      padding: '0px'
    }),
    menu: (provided) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      // width: '600px',
      zIndex: 60,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: 10,
      // width: 590,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    }
  }

const LocationEditor = ({value, onValueChange, stopEditing}: CustomCellEditorProps) =>
  {
    const [selectedLocation, setSelectedLocation] = useState(value);
    const [existingLocations, setExistingLocations] = useState([]);
    useEffect(()=>{
        axios.get("/assets/locations.json")
            .then(({ data }) => {
                setExistingLocations(data.map(buildLocationResult));
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

      
    const buildLocationResult = (location) => 
      {
        const labels = location.label.split(' - ');
        return {
          value: location,
          label:<div className="search-result sm">
            <img src={`/layers/${location.layerId || location.layer_id}/icon.svg`} /> 
            {location.label}
          </div>
        }
      }

    const searchLocation = (inputValue) => {
      if (inputValue.length < 3) {
        Promise.resolve(existingLocations)
      }
      
      return axios.get("/features/search.json?q="+inputValue+'&res_format=simple')
        .then(({ data }) => {
          return data.map(buildLocationResult);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    const debouncedSearch = useCallback(
      debounce((inputValue: string, callback: (options: any) => void) => {
        searchLocation(inputValue).then(options => callback(options))
    }, 250)
    ,[]);
  

    const handleChange = ({value}) => {
        setSelectedLocation(value)
        onValueChange(value)
        stopEditing();
    };


    return <AsyncSelect
        onChange={handleChange}
        placeholder="Rechercher ..."
        noOptionsMessage={() => "Aucune entrée correspondante"}
        loadingMessage={() => "Chargement ..."}
        cacheOptions
        autoFocus
        menuIsOpen={true}
        styles={customStyles}
        defaultOptions={existingLocations}
        loadOptions={debouncedSearch}
        value={selectedLocation} 
      />
      
  }
export default LocationEditor;