import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { createConsumer } from "@rails/actioncable"
import ForecastContext from './Forecasts/Context';
import { Toast } from './Toast';
import Axios from 'axios';
import moment from 'moment';

const ForecastUpdateNotifier = () => {
  const { currentForecast, loadForecast, setIsObsolete } = useContext(ForecastContext);
  const [consumer, setConsumer] = useState(null)

  useEffect(() => {
    const consumer = createConsumer()
    setConsumer(consumer)

    return () => {
      consumer.disconnect()
    }
  }, [])

  const toastTitle = (forecastBulletin) => (
    forecastBulletin ? `Bulletin mis à jour !` : 'Bulletin introuvable'
  )

  const toastText = (forecastBulletin) => (
    forecastBulletin ? `${moment(forecastBulletin.issuedAt).utc().format('L HH:mm')} (${forecastBulletin?.forecastAlternative?.name})` : 'Bulletin introuvable'
  )

  useEffect(() => {
    if (consumer && currentForecast?.id) {
      consumer.subscriptions.create({ channel: "ForecastBulletinChannel" }, {
        received(data) {
          setIsObsolete(true)
          Toast.info({
            title: toastTitle(data),
            text: toastText(data),
            timer: 5000,
            showConfirmButton: true,
            confirmButtonText: 'Afficher',
          })
            .then(({ isConfirmed }) => {
              if (isConfirmed) {
                loadForecast(data.id)
              }
            });
        }
      });
    }
  }, [consumer, currentForecast])


  return (
    <>
    </>
  );

}

export default ForecastUpdateNotifier