import { useField } from "formik";
import React, { useEffect } from "react";
import { useState } from "react";

const MediaTypeInput = ({ ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  const {value} = meta;
  const {setValue} = helpers;
  const [selected, setSelected] = useState(value);

  useEffect(()=>{
    if(["sms", "email"].indexOf(selected)<0)
      {
        setSelected("other");
      }
  },[selected])

  const mediaChanged = (e)=>{
    setSelected(e.currentTarget.value);
    setValue(e.currentTarget.value);
  }

  const mediaTextChanged = (e)=>{
    setValue(e.currentTarget.value);
  }

  return (
    <div style={{paddingLeft:"2rem"}}>
      <div className="radio"><label className="form-check-label"><input className="form-check-input" type="radio" onChange={mediaChanged} name={field.name} checked={selected === "sms"} value="sms" />SMS</label></div>
      <div className="radio"><label className="form-check-label"><input className="form-check-input" type="radio" onChange={mediaChanged} name={field.name} checked={selected === "email"} value="email" />EMail</label></div>
      <div className="radio"><label className="form-check-label"><input className="form-check-input" type="radio" onChange={mediaChanged} name={field.name} checked={selected === "other"} value="other" />Autre:</label></div>
      { selected === "other" && <input type="text" name={field.name} value={value === "other"?"":value} onChange={mediaTextChanged} className="form-control"></input>}
    </div>
  );
};
export default MediaTypeInput;