import React, { useCallback, useContext, useMemo, useState } from "react"
import I18n from "../../../packs/translations"
import FeatureResultOverrideEvent from "../../../types/FeatureResultOverrideEvent"
import { ForecastContext } from "../../Forecasts";
import { forecastTimeIntervals, forecastTimePill } from "../../legends/colorScale";
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import { TimelineElementColor } from "../TimeLineElement";
import { Toast } from "../../Toast";

type Props = {
  event: FeatureResultOverrideEvent;
  formattedValue: (value: any) => string;
}

const FeatureResultOverrideEventContent = ({ event, formattedValue }: Props) => {
  const [detailsVisible, setDetailsVisible] = useState<boolean>(false)

  const toggleVisibility = () => setDetailsVisible(!detailsVisible)
  const { currentForecast } = useContext(ForecastContext)
  const oldInterval = forecastTimeIntervals({ currentForecast, delay: event.details.oldStatusTimespan })
  const newInterval = forecastTimeIntervals({ currentForecast, delay: event.details.newStatusTimespan })
  const bgcolor = TimelineElementColor(event)
  const templateTextColor = '#c8c9ce'

  const centerOnOverriddenFeature = useCallback((e) => {
    e.preventDefault()
    // if (event.feature) {
    //   centerOnFeature({ feature: event.feature })
    // }
    // else {
    //   Toast.error({ text: "Impossible de centrer sur l'entité", title: "Erreur" })
    // }
  }, [event.feature])

  return (

    <VerticalTimelineElement
      className="timeline-element-component"
      contentStyle={{ background: bgcolor, backgroundOpacity: 0.5, color: templateTextColor, boxShadow: `0 3px ${event.iconColor}` }}
      contentArrowStyle={{ borderLeft: '7px solid', borderLeftColor: event.iconColor }}
      date={<span className="pull-right">{formattedValue(event.occuredAt)} ({event.createdBy.name})</span>}
      iconStyle={{ background: bgcolor, color: event.iconColor, padding: '12px', margin: '12px' }}
      icon={<i className={event.icon} />}
      key={event.title}
    >
      <h5 className="vertical-timeline-element-title" >
        <a className="timeline-element-link lookup" href="#" onClick={centerOnOverriddenFeature}>
          {event.details.featureTypeName} - {event.details.featureName}
        </a>
        <small className="text-muted pull-right clickable" onClick={toggleVisibility}>
          <i className={`fas fa-${detailsVisible ? 'minus' : 'plus'}`} />
        </small>
      </h5>
      <h6 style={{ color: '#8b939b' }} className="vertical-timeline-element-subtitle">
        {I18n.t(event.action)}
      </h6>
      <div>
        {forecastTimePill(oldInterval)}<span className="p-1">→</span>{forecastTimePill(newInterval)}
      </div>
      <div style={{ display: detailsVisible ? '' : 'none' }} className="mt-2">

        <div className="row">
          <div className="col-md-5">
            <strong>Type</strong>
          </div>
          <div className="col-md-7">
            {event.details.featureTypeName}
          </div>
          <div className="col-md-5">
            <strong>Identifiant</strong>
          </div>
          <div className="col-md-7">
            {event.details.featureName}
          </div>

          <div className="col-md-5">
            <strong>Nouvel état</strong>
          </div>
          <div className="col-md-7">
            {forecastTimePill(newInterval)}
          </div>

          <div className="col-md-5">
            <strong>État préc.</strong>
          </div>
          <div className="col-md-7">
            {forecastTimePill(oldInterval)}
          </div>
        </div>
      </div>
    </VerticalTimelineElement>
  )
}

export default FeatureResultOverrideEventContent