import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import JSONForm from '../JSONForm';
import I18n from "../../packs/translations"

import { Toast } from '../Toast';
import { layerFeatureEvaluator } from '../../types/LayerFeatureEvaluator';
import { FeatureVariable, VariableType } from '../../types/FeatureVariable';

interface EvaluatorFormProps {
  layerFeatureEvaluatorId?: number;
  forecastableModelId: number;
  forecastedLayerId: number;
}

interface SnakeCaseLayerFeatureEvaluator {
  type: string;
  feature_variable_id: string;
  config?: string;
  hasFeatureVariable?: boolean;
}

const EvaluatorForm = ({ layerFeatureEvaluatorId, forecastableModelId, forecastedLayerId }: EvaluatorFormProps) => {

  const { register, unregister, handleSubmit, control, reset, watch } = useForm();
  const [layerFeatureEvaluator, setLayerFeatureEvaluator] = useState({} as SnakeCaseLayerFeatureEvaluator)

  const watchEvaluatorType = watch('type', layerFeatureEvaluator.type)
  const watchVariable = watch('feature_variable_id', layerFeatureEvaluator.feature_variable_id)

  const [evaluators, setEvaluators] = useState([])
  const [errors, setErrors] = useState<any>({})
  const [featureVariables, setFeatureVariables] = useState<FeatureVariable[]>([])


  /**
   * Get the layer feature evaluator
   */
  useEffect(() => {
    if (layerFeatureEvaluatorId) {
      axios.get<layerFeatureEvaluator>(`/forecastable_models/${forecastableModelId}/layer_feature_evaluators/${layerFeatureEvaluatorId}.json`)
        .then(({ data }) => setLayerFeatureEvaluator({
          type: data.type,
          feature_variable_id: data.featureVariableId?.toString(),
          config: data.config,
          hasFeatureVariable: data.hasFeatureVariable,
        }))
        .catch(e => console.error(e))
    } else {
      setLayerFeatureEvaluator({ feature_variable_id: null, type: null });
    }
  }, [layerFeatureEvaluatorId, forecastableModelId])

  // Get the possible evaluators
  useEffect(() => {
    axios.get(`/forecastable_models/${forecastableModelId}/evaluators.json`)
      .then(({ data }) => setEvaluators(data))
      .catch(res => Toast.error({
        title: res.response.statusText,
      }));
  }, [forecastableModelId])

  // Get the possible feature variables
  useEffect(() => {
    if (forecastedLayerId && layerFeatureEvaluator.hasFeatureVariable) {
      axios.get(`/layers/${forecastedLayerId}/feature_variables.json`)
        .then(({ data }) => setFeatureVariables(data))
        .catch(res => Toast.error({
          title: res.response.statusText,
        }));
    }
  }, [forecastedLayerId, layerFeatureEvaluator.hasFeatureVariable])

  // Reset form if ForecastableEntity changes
  useEffect(() => {
    reset(layerFeatureEvaluator);
  }, [layerFeatureEvaluator])

  // Go back to main page
  const backToList = () => {
    window.location.href = `/forecastable_models/${forecastableModelId}/layer_feature_evaluators`;
  };

  // Save the form
  const save = (data) => {
    const [url, method] = layerFeatureEvaluatorId
      ? [`/forecastable_models/${forecastableModelId}/layer_feature_evaluators/${layerFeatureEvaluatorId}.json`, 'patch']
      : [`/forecastable_models/${forecastableModelId}/layer_feature_evaluators.json`, 'post'];

    const cleanedData = {
      ...data,
      feature_variable_id: data.feature_variable_id || undefined,
      feature_variable_attributes: data.feature_variable_id ? data.feature_variable_attributes : undefined,
    }

    axios[method](url, { layer_feature_evaluator: cleanedData })
      .then(({ data }) => { setLayerFeatureEvaluator(data); setErrors([]) })
      .then(() => Toast.success({ title: 'Enregistré' }))
      .then(backToList)
      .catch((res) => {
        console.error(res);
        Toast.error({
          title: res.response.status === 422
            ? 'Données invalides'
            : res.response.statusText,
        });
        if (res.response.status === 422) {
          setErrors(res.response.data);
        }
      });
  };

  const evaluatorEditor = useMemo(() => {
    unregister('config')
    if (!watchEvaluatorType) { return null; }
    const evaluatorConfig = evaluators.find(e => e.name === watchEvaluatorType)

    if (!evaluatorConfig || !evaluatorConfig.configSchema) {
      return null;
    }

    return <div className="form-group row">
      <label htmlFor="config" className="col-sm-2 col-form-label">
        Propriétés de l'évaluateur
      </label>
      <div className="col-sm-10">

        <input {...register('config')}
          type="hidden"
          defaultValue={JSON.stringify(layerFeatureEvaluator?.config)}
        />
        <JSONForm
          baseFieldName="config"
          jsonSchema={evaluatorConfig.configSchema}
          data={layerFeatureEvaluator?.config || {}}
          handleform={{ register, errors }}
        />
      </div>
    </div>
  }, [watchEvaluatorType, evaluators, layerFeatureEvaluator])

  const variableEditor = useMemo(() => {
    if (watchVariable) {
      unregister('feature_variable_attributes')
      return null;
    }

    unregister('feature_variable_id')
    return <>
      <div className="form-group row">
        <label htmlFor="name" className="col-sm-2 col-form-label">
          Nom de la variable
        </label>
        <div className="col-sm-10">
          <input
            {...register('feature_variable_attributes.name')}
            type="text"
            className={`form-control ${(errors.name) ? 'is-invalid' : ''}`}
            id="name"
            placeholder="Nom de la variable"
          />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="feature_variable_attributes.variable_type" className="col-sm-2 col-form-label">
          Type de variable
        </label>
        <div className="col-sm-10">
          <select
            {...register('feature_variable_attributes.variable_type')}
            id="feature_variable_attributes.variable_type"
            className={`form-control ${(errors.feature_variable_attributes || errors.feature_variable_id) ? 'is-invalid' : ''}`}
            defaultValue=""
          >

            {Object.values(VariableType).map(type => {
              return <option value={type} key={type}>
                {I18n.t(type).toString()}
              </option>
            })}
          </select>

        </div>

        <input
          {...register(`feature_variable_attributes.layer_id`)}
          type="hidden"
          defaultValue={forecastedLayerId}
        />


      </div>
    </>
  }, [watchVariable, layerFeatureEvaluator])

  return (
    <React.StrictMode>
      <div className="card">
        <div className="card-header">
          <h1>{layerFeatureEvaluatorId
            ? `Édition de ${I18n.t(layerFeatureEvaluator.type)}`
            : 'Nouvel évaluateur'}
          </h1>
        </div>

        <div className="card-body">
          <form onSubmit={handleSubmit(save)}>
            {/*==================================
              FEATURE EVALUATOR
          ==================================*/}
            <div className="form-group row">
              <label htmlFor="type" className="col-sm-2 col-form-label">
                Évaluateur
              </label>
              <div className="col-sm-10">
                <select
                  {...register('type')}
                  disabled={!!layerFeatureEvaluatorId}
                  id="type"
                  className={`form-control ${(errors.feature_variable_attributes || errors.feature_variable_id) ? 'is-invalid' : ''}`}
                  defaultValue=""
                >
                  <option disabled aria-label="Vide" value="" key="feature-variable-empty" />
                  {
                    !layerFeatureEvaluatorId ?
                      evaluators.map(evaluator => {
                        return <option value={evaluator.name} key={evaluator.name}>
                          {I18n.t(evaluator.name)}
                        </option>
                      }) :
                      <option value={layerFeatureEvaluator.type} key="current_evaluator">
                        {I18n.t(layerFeatureEvaluator.type)}
                      </option>
                  }
                </select>
                {(errors.type) && (
                  <div className="invalid-feedback d-block">
                    {(errors.type)}
                  </div>
                )}
              </div>
            </div>
            {evaluatorEditor}

            {layerFeatureEvaluator.hasFeatureVariable ? <>
              <div className="form-group row">
                <label htmlFor="variable" className="col-sm-2 col-form-label">
                  Variable
                </label>
                <div className="col-sm-10">
                  <select
                    id="feature_variable_id"
                    className={`form-control ${(errors.feature_variable_attributes || errors.feature_variable_id) ? 'is-invalid' : ''}`}
                    {...register('feature_variable_id')}
                    defaultValue={layerFeatureEvaluator.feature_variable_id}
                  >
                    <option aria-label="Nouvelle variable" value="" key="feature-variable-new" >
                      Nouvelle variable
                    </option>
                    {featureVariables.map(variable => {
                      return <option value={variable.id} key={variable.name}>
                        {variable.name}
                      </option>
                    })}
                  </select>
                  {(errors['layer_feature_evaluator.variable']) && (
                    <div className="invalid-feedback">
                      {(errors['layer_feature_evaluator.variable'])}
                    </div>
                  )}
                </div>
              </div>
              {variableEditor}
            </> :
              null
            }

            < button type="submit" className="btn btn-success" > Enregistrer</button >
          </form >
        </div>
      </div>
    </React.StrictMode>
  )
}

export default EvaluatorForm