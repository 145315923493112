import React, { useState } from "react"
import moment from "moment";

const ScenarioControlList = ({ name, label, icon, forecasts, scenarios, onChange, expanded }) => {
  const [show, setShow] = useState(expanded);
  const fakeNow = moment().format("ddd  D MMM HH:00")
  const toggleShow = e => {
    e.preventDefault();
    const newValue = !show;
    setShow(newValue);
  }

  return (
    <li key={`layer-group-${name}`} className={`menu-item-has-children ${show && "show"}`}>
      <a href="#" onClick={e => toggleShow(e)}  >
        {icon}
        {' '}
        {label}
      </a>
      <ul className="sub-menu children">
        <select onChange={(e)=>onChange(e.target.value)} >
        { !forecasts && <option key={`layer-forecast`}>Prévisions {fakeNow}</option> }
        {forecasts.map((forecast) => {
              return <option key={`layer-forecast-${forecast.id}`} value={`bulletin: ${forecast.id}`}>Prévisions {moment(forecast.issuedAt).utc().locale("fr-FR").format("ddd  D MMM HH:00")}</option>
            })
          }
       { !scenarios &&  <option key={`layer-scenario`} disabled={true}>-- Scénario</option> }
          {scenarios.map((scenario) => {
              return <option key={`layer-scenario-${scenario.id}`} value={`scenario: ${scenario.layer}`} >{scenario.height? scenario.height+ 'm' : scenario.risk}</option>
            })
          }
        </select>
      </ul>
    </li>
  )
}

export default ScenarioControlList;