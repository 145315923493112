import axios from "axios";
import { Form, Formik, FormikHelpers, FormikValues, useField } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';
import { Asset } from "../Inventory";
import { FieldWithError } from "../Form/Error";
import NestedForm from "../Form/NestedForm";
import LocationSearch from "./LocationSearch";
import { flatten } from "../Map/Utils";

const emptyAsset = {assetType: undefined, identifier:undefined, id:undefined, timeEfficient:undefined, hazardLimitation: undefined, comment:undefined}

const AssetSearch = ({...props}) =>
  {
    const [field, meta, helpers] = useField(props.name);
    const {value} = meta;
    const {setValue} = helpers;
    const [creating, setCreating] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState();
    const [newAsset, setNewAsset] = useState<Asset>(emptyAsset);
    const [assetTypes, setAssetTypes] = useState<string[]>([]);
    


    useEffect(()=>{
      axios.get<string[]>(`/assets/asset_types.json`)
      .then(({ data }) => {
        setAssetTypes(data);
      })
      .catch((err) => {
        console.error(err);
      });
    },[])

    useEffect(()=>{
      if(value)
        axios.get(`/assets/${value}.json`)
          .then(({ data }) => {
            return setSelectedAsset(buildAssetResult(data));
          })
          .catch((err) => {
            console.error(err);
          });
    },[])
      
    const buildAssetResult = (asset) => 
      {
        return {
          value: asset.id,
          label:<div>
            <b>{asset.assetType}</b><br />
            <i>{asset.identifier}</i>
          </div>
        }
      }

    const searchAsset = (inputValue) => {
      if (inputValue.length < 3) {
        return Promise.resolve([]);
      }
  
      return axios.get("/assets/search.json?q="+inputValue)
        .then(({ data }) => {
          return data.map(buildAssetResult);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    const handleCreate = (inputValue: string) => {
      setCreating(true);
      newAsset.assetType = inputValue;
      
      setNewAsset({...newAsset});
    };

    const createAsset = async (values) => {
      const params = {...values};
      flatten(params);
      return axios.post("/assets.json", params)
        .then((res)=>{
          setCreating(false);
          setNewAsset(emptyAsset);
          setSelectedAsset(buildAssetResult(res.data));
          setValue(res.data.id);
        })
        .catch((err)=>console.error(err.data))
    }

  
    return <>
      {!creating ? <AsyncCreatableSelect
        name={field.name}
        onChange={(asset)=>{setValue(asset.value);setSelectedAsset(asset)}}
        placeholder="Rechercher ..."
        noOptionsMessage={() => "Aucune entrée correspondante"}
        onCreateOption={handleCreate}
        loadingMessage={() => "Chargement ..."}
        // styles={customStyles}
        cacheOptions
        loadOptions={searchAsset}
        // defaultOptions
        // onSubmit={()=>setValue(selectedAsset? selectedAsset.id : undefined)}
        value={selectedAsset} 
      />:
      <fieldset>
        <NestedForm 
          initialValues={newAsset} 
          onSubmit={createAsset}>
          <Form>
              <div className="form-group row">
                <label className="col-form-label col-sm-4 required">
                  Type de matériel
                </label>
                <div className="col-8">
                  <CreatableSelect
                     name="assetType"
                     isClearable 
                     value={newAsset && { label:newAsset.assetType, value:newAsset.assetType }}
                     onChange={(selected)=>{
                        newAsset.assetType=selected?.value; 
                        setNewAsset({...newAsset});}
                      }
                     options={assetTypes.map((a)=>{return {label:a, value:a}})}
                     styles={{placeholder:base=>({...base, maxHeight: 26, textWrap: "nowrap"})}}
                     placeholder="Générateur électrique, camionette, pompe hydraulique, ..."
                  />
                  
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-sm-4 required">
                  Identification
                </label>
                <div className="col-8">
                  <FieldWithError
                    name="identifier"
                    className={`form-control`}
                    placeholder="Numéro de série, immatriculation, tout autre attribut permettant d'identifier le matériel de manière unique"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-sm-4 required">
                  Lieu de stockage
                </label>
                <div className="col-8">
                  <LocationSearch name="location"/>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-sm-4 required">
                  Durée d'efficacité/autonomie
                </label>
                <div className="col-8">
                  <FieldWithError
                    name="timeEfficient"
                    className={`form-control`}
                    placeholder="Durée utile avant qu'une action ne soit nécéssaire"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-sm-4 required">
                  Limite de hauteur d'eau (cm)
                </label>
                <div className="col-8">
                  <FieldWithError
                    name="hazardLimitation"
                    className={`form-control`}
                    placeholder="Limite de hauteur d'eau a son point de déploiement pour que le matériel reste fonctionnel"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-form-label col-sm-4 required">
                  Commentaire
                </label>
                <div className="col-8">
                  <FieldWithError
                    name="comment"
                    as="textarea"
                    className={`form-control`}
                    placeholder="Tout autre specification utile sur ce matériel (puissance, nombre de place, débit de pompage,...)"
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="offset-md-8 col-md-4">
                  <button type="submit" className="btn btn-primary btn-sm">Créer le matériel</button>
                </div>
              </div>
          </Form>
        </NestedForm>
        </fieldset>
      }
      </>
  }
export default AssetSearch;