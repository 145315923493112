import axios from "axios";
import { useField } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import AsyncSelect from 'react-select/async';
import { debounce } from "../Map/Utils";


export interface Location {
  id:number;
  label:string;
  type:string;
  center:string;
  bounding_box:string;
}

const LocationSearch = ({...props}) =>
  {
    const [field, meta, helpers] = useField(props.name);
    const {value} = meta;
    const {setValue} = helpers;
    const [selectedLocation, setSelectedLocation] = useState();

    const buildLocationResult = (location) => 
      {
        const labels = location.label.split(' - ');
        return {
          value: {id:location.id, type:location.type},
          label:<div className="search-result sm">
            <img src={`/layers/${location.layer_id}/icon.svg`} /> 
            {location.label}
          </div>
        }
      }

    const searchLocation = (inputValue) => {
      if (inputValue.length < 3) {
        return Promise.resolve([]);
      }
      
      return axios.get("/features/search.json?q="+inputValue+'&res_format=simple')
        .then(({ data }) => {
          return data.map(buildLocationResult);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    const debouncedSearch = useCallback(
      debounce((inputValue: string, callback: (options: any) => void) => {
        searchLocation(inputValue).then(options => callback(options))
    }, 250)
    ,[]);
  
    return <AsyncSelect
        name={field.name}
        onChange={(location)=>{setValue(location.value);setSelectedLocation(location)}}
        placeholder="Rechercher ..."
        noOptionsMessage={() => "Aucune entrée correspondante"}
        loadingMessage={() => "Chargement ..."}
        cacheOptions
        loadOptions={debouncedSearch}
        value={selectedLocation} 
      />
      
  }
export default LocationSearch;