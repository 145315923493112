import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import MaplibreGl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'

import utilUrl from '../../utility/utilUrl'
import { MapImageHandler } from './MapImageHandler'

class CustomControls {
	handleLocationToggle = null;
	onAdd(map) {
		this._container = document.createElement('div')
		return this._container;
	}
	onRemove() {
		this._container.parentNode.removeChild(this._container)
		this._map = undefined
	}
}

const MapMapbox = ({ style, rebuildMap, states, layout, onSelect, selected, forecastLayer, forecastInterval }) => {
	const [locationShow, setLocationShow] = useState(false);
	const [mapLoaded, setMapLoaded] = useState(false);
	const [map, setMap] = useState();
	const [prevErr, setPrevErr] = useState();
	const [prevStates, setPrevStates] = useState({});

	let container = document.createElement('div')
	const loadFeatureStates = () => {
		
		for(const layer_id in states)
		{
			const layer = style.layers.filter(layer => layer.id === layer_id)[0];
			const layerStates = states[layer_id];
			for(const [k, v] of Object.entries(layerStates)){
				let k2 = k;
				if (k.indexOf(',')>=0)
					k2 = k.replace('[','').replace(']','').split(',')
				let state = {}
				let selector = { source: layer["source"], sourceLayer: layer["source-layer"], id: k}
				if(Array.isArray(k2))
				{
					selector = { source: layer["source"], sourceLayer: layer["source-layer"], id: k2[0]}
					let key = "D+" + k2[1].trim()
					if (Array.isArray(v)) {
						state[key + " featureResultOverrideId"] = v[0];
						state[key + " base_opened"] = v[1];
						state[key + " is_opened"] = !v[1];
						if(k2[1].trim()=='0') state["dys_in"] = v?undefined:0;
					} else
					{
						state[key + " is_opened"]=!v[1];
						if(k2[1].trim()=='0') state["dys_in"] = v?undefined:0;
					}
				}
				else{
					if (Array.isArray(v)) {
						state["featureResultOverrideId"] = v[0];
						state["base_dys_in"] = v[1];
						if (v[0]===false){
							state["dys_in"]=0;
						}
						else{
							state["dys_in"]=undefined;
						}
					}
					else
						state["dys_in"]=v;
				}
				map.setFeatureState(selector, state);
		}}

		setPrevStates(states)

	}


	useEffect( ()=>{
		if(!mapLoaded || prevStates == states) return;
		
		loadFeatureStates();

	}, [states, mapLoaded])

	useEffect(()=>{
		if(!selected)
			return;
		handleSelection([selected], map);
	},[selected])

	const applyLayout = () => {
		for (const l of layout)
		{
			map.setLayoutProperty(l.id, "visibility", l.visible?'visible':'none');
			map.setPaintProperty(l.id, l.geomtype+'-opacity', l.opacity)
		}
	}

	useEffect( ()=>{
		if(!mapLoaded || !forecastLayer) return;

		if(map.getLayer('forecast'))
			map.removeLayer('forecast')
		if(map.getSource('forecast'))
			map.removeSource('forecast')

		let position = (style.layers.filter(l => l['layer-type']!='background')[0]||{id:undefined}).id;
		const tiles = location.protocol + location.host + '/tegola/maps/' + forecastLayer + '/{z}/{x}/{y}.pbf';
		if(forecastLayer.indexOf("zich")>=0)
		{
			map.addLayer({
				id: 'forecast',
				source: { type:'vector', tiles: [tiles] },
				'source-layer': forecastLayer.split('/').slice(-1)[0],
				type: 'fill',
				paint: {
					// "fill-color":"darkblue"
					"fill-color": 
					[
						"match",
						[
							"get",
							"h_min"
						],
						0, "#94ecff",
						10, "#8ce1f9",
						20, "#85d6f3",
						30, "#7dcbed",
						40, "#76c0e7",
						50, "#6fb5e1",
						100, "#4a7fc3",
						150, "#2548a5",
						200, "#001287",
						"transparent"
					],
					"fill-opacity": 0.2
				}}, position);
		}
		else if(forecastLayer.indexOf("e_coli")>=0)
		{
				map.addLayer({
				id: 'forecast',
				source: { type:'vector', tiles: [tiles] },
				'source-layer': forecastLayer.split('/').slice(-1)[0],
				type: 'fill',
				paint: {
					"fill-color": [
						"match",
						[
							"get",
							"level"
						],
						0, "#30123b",
						1000, "#4455c4",
						2000, "#4390fe",
						3000, "#1fc9dd",
						4000, "#2aefa1",
						5000, "#7eff55",
						6000, "#c2f234",
						7000, "#f2c93a",
						8000, "#fe8f29",
						9000, "#e94d0d",
						10000, "#bd2002",
						"transparent"
					],
					"fill-opacity": 0.2
				}
			}, position)

			filterForecastInterval(0);
		}

	}, [forecastLayer, mapLoaded])

	const filterForecastInterval = (interval) => {
		map.setFilter('forecast', ['==', ['get', 'timestep'], interval]);
	}

	useEffect( ()=>{
		if(!mapLoaded || !forecastLayer) return;
		if(forecastLayer.indexOf('zich')>0)
		{
			map.setFilter('forecast', null);
			return;
		}
		filterForecastInterval(forecastInterval); 
	}, [forecastInterval, forecastLayer, mapLoaded])


	useEffect( ()=>{
		if(!mapLoaded) return;
		
		applyLayout();

	}, [layout, mapLoaded])


	const handleLocationToggle = (show)=>{
		if (locationShow){
			setLocationShow(false)
		} else {
			setLocationShow(true)
		}
	};

	let hash = window.location.hash;

	const buildMap = ()=>{
		if (!style) return <div/>

		if (map){
			// tear down map
			return;
			// map.remove()
		}

		let prevErr = false;
		window.onerror = (message, source, lineno, colno, error)=>{
			const errString = JSON.stringify(error)
			if (errString === prevErr) return
			
			setPrevErr(errString)
		}

		let newMap = new MaplibreGl.Map({
			attributionControl:false,
			logoPosition:'bottom-right',
			container: container,
			style: style,
			transformRequest: transformRequest,
			hash:true
		});
		newMap.setMinZoom(6);
		// newMap.setMaxZoom(10);

		const Controls = new CustomControls({})
		Controls.handleLocationToggle = handleLocationToggle

		newMap.addControl(Controls)

		newMap.addControl(new MaplibreGl.AttributionControl({
			compact: true
		}))

		const nav = new MaplibreGl.NavigationControl()
		newMap.addControl(nav, 'bottom-right')

		newMap.on('error',(e)=>{
			handleMapError(e.error)
		})

		newMap.on('moveend', ()=>{
			if (window.location.hash) hash = window.location.hash
		})

		newMap.on('load', (e)=>{
			setMapLoaded(true)
		})

		newMap.on('click', (e)=>{
			handleClick(e, newMap)
		})

		newMap.on('styleimagemissing', (e)=>{
			handleMissingImage(e, newMap)
		})

		setMap(newMap)
	}

	let builtMap = null;
	let clickMarker = null;

	useEffect(()=>{

		if (!style || !style["version"]) return
		if (!builtMap && !mapLoaded){
			buildMap()
			builtMap = rebuildMap
			return
		}
		
		if (!window.location.hash){
			if (window.history.replaceState) {
	    	window.history.replaceState(null, null, hash)
			}
			else {
			  window.location.hash = hash
			}
		}
		reStyleMap()
	}, [style, rebuildMap])
	
	const queryMapFeatures = (map, point)=>{

		const pointProj = map.project(point)
	
		const margin = 5
		const bbox = [
			{x:pointProj.x-margin,y:pointProj.y+margin},
			{x:pointProj.x+margin,y:pointProj.y-margin}
		]
		const layers = layout.filter(l=>l.selectable).map(l=>l.id)
		const features = map.queryRenderedFeatures(bbox, {"layers": layers})
		return features || []
	}

	let prevSelected = [];

	const selectFeatures = (features, map) => {
		for (const f of prevSelected){
			map.setFeatureState({ source: f["source"], sourceLayer: f["sourceLayer"], id: f.id}, {"selected": false})
		}
		for (const f of features){
			map.setFeatureState({ source: f["source"], sourceLayer: f["sourceLayer"], id: f.id}, {"selected": true})
		}
		prevSelected = features;
	};

	const dedupFeatures = (featuresDuped) => {
		let found = [], features = []
		featuresDuped.forEach(feature => {
			if(feature.layer.id.endsWith('-sel')) return;
			const key = `${feature.source}~${feature.sourceLayer}~${feature.layer.id}~${feature.id}`
			if (!found.includes(key)){
				found.push(key)
				features.push(feature)
			}
		});
		return features;
	};

	const flyMapTo = (features, map) => {
		if(features.length){
			let coordinates = features.map(f=>{
				const bounds = f.properties["bounding_box"];
				if (bounds)
					return bounds.split(',').map(c=>c.split(" ").map(a=>parseFloat(a)));
				
				const center = f.properties["center"];
					if (center)
					{
						let c = center.split(" ").map(a=>parseFloat(a))
						return [c, c];
					}
				return [];
			}).flat();
			if(coordinates.length<2)
				return;
			const bounds = coordinates.reduce((bounds, coord) => {
				if (coord[0] && coord[1])
					return bounds.extend(coord);
				else
					return bounds;
			}, new MaplibreGl.LngLatBounds(coordinates[0], coordinates[1]));
		
			let pTop = window.outerHeight / 6;
			let pLeft = window.outerWidth > 700 ? (window.outerHeight - 300) : window.outerWidth/6

			map.fitBounds(bounds, {
					padding: {top:pTop, bottom:pTop, left:pLeft, right:pLeft},
				  maxZoom: 17,
				  duration: 850
			});
		}
	};

	const annotateSelectedFeatures = (features) => {
		let selected = {};
		for (const f of features)
			{
				selected[f["sourceLayer"]] = selected[f["sourceLayer"]] || []
				
				let props = f.properties
				props['id'] = f.id;
				props['layerId'] = (f.layerId || f.layer?.id).toString();
				if(f.state){
					props["dys_in"] = f.state["dys_in"];
					if (f.state["featureResultOverrideId"]!==undefined) {
						props["base_dys_in"] = f.state["base_dys_in"]
						props["featureResultOverrideId"] = f.state["featureResultOverrideId"]
				}}
				else props["dys_in"] = undefined;
				if(selected[f["sourceLayer"]].indexOf(f2 => f2.id === f.id)<=0)
					selected[f["sourceLayer"]].push(props)	
			}
		for (const l in selected)
			selected[l].sort((a,b)=> a.dys_in - b.dys_in)

		return selected;
	}

	const handleClick = (e, map)=>{
		const point = map.unproject(e.point)
		const features = dedupFeatures(queryMapFeatures(map, point));
		handleSelection(features, map)
	}

	const handleSelection = (features, map) => {
		selectFeatures(features, map);
		flyMapTo(features, map);
		
		const selected = annotateSelectedFeatures(features);
		
		if(onSelect)
			onSelect(selected);
	}

	const handleMapError = (error)=>{
		const errString = JSON.stringify(error)
		if (errString === prevErr) return
		console.error(error)
		console.log(style)
		setPrevErr(errString)
	}

	const handleMissingImage = MapImageHandler;

	const handleMarkerClose = (e)=>{
		e.stopPropagation()
		if (clickMarker) clickMarker.remove()
		modelMap.actions.clearFocus()
	}


	const reStyleMap = ()=>{
		try {
			map && map.setStyle(style,{diff: true})
		} catch(e){
			handleMapError(e)
		}
	}

	return (
		<React.Fragment>
			<div id="map" ref={el => container = el}></div>
		</React.Fragment>
	)

	const transformRequest = (url, resourceType)=>{
		const {domainHeaders} = props

		const domain = utilUrl.getDomain(url)

		if (domainHeaders && domainHeaders.has(domain)){
			const headers = domainHeaders.getIn([domain]).toJS()

			let sendHeaders = {}
			Object.keys(headers).forEach(key => {
				if (key && key.length > 0){
					sendHeaders[key] = headers[key]
				}
			})

			return {
				url: url,
				headers: sendHeaders,
			}
		}
	}
}

MapMapbox.propTypes = {
	focus:  PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
	handle: PropTypes.object,
	match: PropTypes.object,
	rebuildMap: PropTypes.number,
	style: PropTypes.object.isRequired,
	states: PropTypes.object,
}

export default MapMapbox