import { ForecastableModel } from "../../types/ForecastableModel";
import { IConfiguratorStoreForecastableModel } from "./ConfiguratorStoreType";
import axios from "axios";
import { Layer } from "../../types/Layer";
import { layerFeatureEvaluator } from "../../types/LayerFeatureEvaluator";
import { FeatureVariable } from "../../types/FeatureVariable";
import { FeatureDependencyConfig } from "../../types/FeatureDependencyConfig";
import { Requirement } from "../../types/Requirement";
import { pathTo } from "../Navigation";

const fetchAndAggregate = async (forecastableModel: ForecastableModel) => {
  const res = forecastableModel as IConfiguratorStoreForecastableModel;
  // Get forecasted layer
  const { data: forecastedLayer } = await axios.get<Layer>(
    `/layers/${forecastableModel.forecastedTypeLayerId}.json`
  );
  res.forecastedLayer = forecastedLayer;

  // Get layer feature evaluators
  const { data: layerFeatureEvaluators } = await axios.get<
    layerFeatureEvaluator[]
  >(
    pathTo(`forecastable_models/${forecastableModel.id}/layer_feature_evaluators.json`)
  );

  // if no evaluator then there's nothing to do
  if (layerFeatureEvaluators.length === 0) {
    return res;
  }
  res.layerFeatureEvaluators = layerFeatureEvaluators;

  // Get feature variables
  await Promise.all(
    res.layerFeatureEvaluators.map(async (layerFeatureEvaluator) => {
      if (!layerFeatureEvaluator.featureVariableId) return;
      const { data: feature_variable } = await axios.get<FeatureVariable>(
        pathTo(`layers/${res.forecastedLayer.id}/variables/${layerFeatureEvaluator.featureVariableId}.json`)
      );
      layerFeatureEvaluator.featureVariable = feature_variable;
    })
  );

  // Get requirements and dependencies
  await Promise.all(
    res.layerFeatureEvaluators.map(async (layerFeatureEvaluator) => {
      if (!layerFeatureEvaluator.featureVariableId) return;
      const { data: requirements } = await axios.get<Requirement[]>(
        pathTo(`layers/${res.forecastedLayer.id}/variables/${layerFeatureEvaluator.featureVariableId}/requirements.json`)
      );
      layerFeatureEvaluator.featureVariable.requirements = requirements;

      await Promise.all(
        layerFeatureEvaluator.featureVariable.requirements.map(
          async (requirement) => {
            const { data: featureDependencyConfig } =
              await axios.get<FeatureDependencyConfig>(
                pathTo(`feature_dependency_configs/${requirement.featureDependencyConfigId}.json`)
              );
            requirement.featureDependency = featureDependencyConfig;

            const { data: dependent_layer } = await axios.get<Layer>(
              pathTo(`layers/${featureDependencyConfig.layerId}.json`)
            );
            requirement.featureDependency.dependent = dependent_layer;
          }
        )
      );
    })
  );

  return res;
};

export default fetchAndAggregate;
