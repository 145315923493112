import React, { useState } from "react";
// import { useAppSelector } from "../../redux/hooks";
import { ForecastableModel } from "../../types/ForecastableModel";
import { IConfiguratorStoreForecastableModel } from "./ConfiguratorStoreType";
import { Button, Modal, ModalFooter, Popover, Spinner, ToggleButtonGroup, OverlayTrigger, Table, ToggleButton } from "react-bootstrap";
import ForecastableModelForm from "../ForecastableModel/Form";
import EvaluatorForm from "../LayerFeatureEvaluator/Form";

interface Props {
  frameId: string;
  forecastableModel: IConfiguratorStoreForecastableModel;
}

const ForecastableModelFrame = (props: Props) => {
  const [showAddEvaluatorModal, setShowAddEvaluatorModal] = useState<boolean>(false);
  const [ShowModal, setShowModal] = useState<boolean>(false);
  // const selectedForecastableModel = useAppSelector<ForecastableModel | undefined>(state => state.configurator.forecastableModel);

  const forecastableModelPopOver = (
    <Popover id="forecastableModelPopOver">
      <Popover.Title as="h3">Modèle prévisionnel</Popover.Title>
      <Popover.Content>
        <p>Consulter ou modifier le modèle prévisionel.</p>
      </Popover.Content>
    </Popover>
  )

  const addEvaluatorPopOver = (
    <Popover id="addEvaluatorPopOver">
      <Popover.Title as="h3">Ajouter un évaluateur</Popover.Title>
      <Popover.Content>
        <p>Ajouter un évaluateur.</p>
      </Popover.Content>
    </Popover>
  )


  return (
    <div className="row justify-content-center">
      <div className="col-md-auto bg-primary justify-content-center rounded" id={props.frameId}>
        <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={forecastableModelPopOver}>
          <Button className="bg-primary pt-0 pb-0 mt-3 mb-0 text-center rounded" onClick={_ => setShowModal(true)}>
            Modèle : {props.forecastableModel.name} <br />
            Couche carto : <br />
            {props.forecastableModel.forecastedLayer.name} <br />
          </Button>
        </OverlayTrigger>
        <div className='row pt-0 pb-0 justify-content-end'>
          <div className="col col-md-auto justify-content-end align-middle">
            <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={addEvaluatorPopOver}>
              <button
                className="badge badge-pill badge-success zindex-popover"
                onClick={_e => setShowAddEvaluatorModal(true)}
              >
                +
              </button>
            </OverlayTrigger>
          </div>
        </div>
      </div>


      <Modal show={ShowModal} onHide={() => setShowModal(false)} size={"xl"} >
        <ForecastableModelForm forecastableModelId={props.forecastableModel.id} />


        <div className="container row">
          <div className="col-12">
            <h3>Évaluateurs</h3>
            {
              props.forecastableModel?.layerFeatureEvaluators?.length > 0 ?

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Configuration</th>
                      <th>Type</th>
                      <th>UUID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      (props.forecastableModel?.layerFeatureEvaluators ?? []).map((evaluator, index) =>
                        <tr key={index}>
                          <td>{evaluator.config ?? "/"}</td>
                          <td>{evaluator.type}</td>
                          <td>{evaluator.uuid}</td>
                        </tr>
                      )
                    }
                  </tbody>

                </Table>
                :
                <span>Aucun évaluateur sur ce modèle. </span>

            }
          </div>
        </div>
      </Modal>

      <Modal show={showAddEvaluatorModal} onHide={() => setShowAddEvaluatorModal(false)} size={"xl"} >
        <EvaluatorForm forecastableModelId={props.forecastableModel.id} forecastedLayerId={props.forecastableModel.forecastedTypeLayerId} />
      </Modal>
    </div >

  );
};

export default ForecastableModelFrame;