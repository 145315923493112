import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import MapView from 'ol/View';
import { fromLonLat } from 'ol/proj';
import { TooltipOverlay } from '../../packs/utils/tooltip';
import { useLayers } from './LayersContext';
import { MapContext } from './mapContext';

/**
 * 
 * @param {string} name The map name
 * @param {number} zoom The zoom level
 * @param {number} longitude The default map center longitude
 * @param {number} latitude The default map center latitude 
 * @param {React.ReactNode} children The children
 * @returns {React.ReactElement}
 */
const OpenLayersMap = ({
  name, zoom, longitude, latitude, onMoveEnd, children
}) => {
  const map = useContext(MapContext);
  const { layers, updateLayerByOlLayer } = useLayers();

  // For debugging purpose
  useEffect(() => {
    window.map = map;
    map.on('moveend', onMoveEnd)
  }, [map]);

  // Update layers when layers properties change
  useEffect(() => {
    map.getLayers().on("propertychange", (e) => {
      if (layers.length === 0) return;
      map.getLayers().getArray().forEach((layer) => {
        updateLayerByOlLayer(layer, { olLayer: layer });
      });
    })
  }, [layers, updateLayerByOlLayer]);

  // Set the map target
  useEffect(() => {
    map.setTarget(`map-${name}`);
  }, [map, name]);

  // Set the map view, add the tooltip overlay and update the map size on window resize
  useEffect(() => {
    map.setView(new MapView({
      center: fromLonLat([longitude, latitude]),
      zoom,
    }));
    new TooltipOverlay(map);

    window.onresize = (() => {
      setTimeout(() => {
        // Update container size
        map.updateSize();
      }, 500 /* Delay to get the real size */);
    });

    return () => {
      window.onresize = undefined;
    }
  }, [map, zoom, longitude, latitude]);

  return (
    <MapContext.Provider value={map}>
      {children}
    </MapContext.Provider>
  );
};

OpenLayersMap.propTypes = {
  zoom: PropTypes.number,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  name: PropTypes.string,
  onMoveEnd: PropTypes.func,
  children: PropTypes.node,
};

OpenLayersMap.defaultProps = {
  zoom: 15,
  longitude: 0,
  latitude: 0,
  name: new Date().getTime().toString(),
  children: undefined,
  onMoveEnd: undefined,
};

export default OpenLayersMap;
export { MapContext, OpenLayersMap };
