import React, { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";

interface ConfirmationModalProps {
  title: string;
  message: string | ReactNode;
  promptValue?: string;
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const [isPromptValid, setIsPromptValid] = React.useState<boolean>(false);
  const [promptValue, setPromptValue] = React.useState<string>("");

  return (
    <Modal show={props.show} onHide={props.onCancel} size={"xl"} >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col">
          {props.message}
          {
            props.promptValue &&
            <div className="form-group row">
              <div className="col-sm-3 interval-form">

                <input
                  id="prompt"
                  className={`form-control ${!isPromptValid ? "is-invalid" : ""}`}
                  type="text"
                  value={promptValue}
                  onChange={e => {
                    setPromptValue(e.target.value);
                    setIsPromptValid(e.target.value === props.promptValue);
                  }}
                />
              </div>
            </div>
          }

          {
            (props.promptValue && !isPromptValid) &&
            <div className="invalid-feedback">Valeur incorrecte</div>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onCancel}>
          Annuler
        </Button>
        <Button
          variant="primary"
          onClick={props.onConfirm}
          disabled={!isPromptValid || !props.promptValue}
        >
          Confirmer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
