import React, {useState, useEffect, useMemo} from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import axios from "axios";
import TagsRenderer from './TagsRenderer';
import TagsEditor from './TagsEditor';
import { Toast } from '../Toast';
import DeleteButton from './DeleteButton';

const highlightClassname = "highlight"

export interface Contact {
  id: number|undefined;
  title: string|undefined;
  firstName: string|undefined;
  lastName: string|undefined;
  phone: string|undefined;
  altPhone: string|undefined;
  email: string|undefined;
  tags: string[];
}

const columns = [
    { field:"title", editable:true, headerName: "Fonction" },
    { field:"firstName", editable:true, headerName: "Prénom" },
    { field:"lastName", editable:true, headerName: "Nom" },
    { field:"phone", editable:true, headerName: "Téléphone" },
    { field:"altPhone", editable:true, headerName: "Alt. Téléphone" },
    { field:"email", editable:true, headerName: "Email" },
    { field:"tags", editable:true, headerName: "Tags", flex:2,
        suppressKeyboardEvent: params => {
          if(params.event.keyCode == 13)
            return true;
        },
        cellRenderer: TagsRenderer,
        cellEditor: TagsEditor, cellEditorPopup: true },
    { headerName:"", cellRenderer: DeleteButton, maxWidth:30, sortable:false, filter:false, lockPosition: 'right', cellClass: 'locked-col'}
  ];

const Directory = () => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [showFilter, setShowFilter] = useState(false);
  // const [selectedContacts, setSelectedContacts] = useState((): ReadonlySet<string> => new Set());
  const organisationId = location.search.substring(5);
  useEffect(() => {
      axios
        .get<Contact[]>(`/contacts.json`+ (organisationId? "?oid="+organisationId:""))
        .then(response => {
          setContacts(response.data);
        });
  }, []);

  const defaultColDef = useMemo(() => {
    return {
        flex: 1,
        filter: true,
        floatingFilter: showFilter,
    };
  }, [showFilter]);


const saveContact = (cell) =>
  {
    const body = {}
    body[cell.column.colId] = cell.newValue;
    axios.patch(`/contacts/${cell.data.id}.json`, body)
      .then(() => Toast.success({ title: "Enregistré" }))
      .catch((res) => {
        const errors = res.response.data;
        Toast.error({
          title:
            res.response.status === 422
              ? "Données invalides"
              : res.response.statusText,
          html: Object.keys(errors).map((k)=>`<li><b>${k}</b> : ${errors[k][0]}</li>`).join("")
        });
      })
}

  return (
    <div className="card">
      <div className="card-header">
        <h1>Annuaire</h1>
      </div>
      <div className="card-body">

        <div
        className="ag-theme-quartz" // applying the Data Grid theme
        style={{ height: 600 }} // the Data Grid will fill the size of the parent container
      >
        <AgGridReact
          onCellValueChanged={saveContact}
          columnDefs={columns}
          rowData={contacts}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={50}
          paginationPageSizeSelector={[25, 50, 100]}
        />
        </div>
      </div>
    </div>
  );
}

export default Directory;